import {Box, Button, Grid, Skeleton, Typography, Paper, TextField,CircularProgress} from "@mui/material";
import "./CreatorSummary.scss";
import {downloadCSV, getPaymentSummary, getProgress, submitTMPayments} from "../services/CreatorSummaryService";
import React, {useContext, useEffect, useRef, useState,ReactNode} from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {CurrentReportDate} from "../hooks/useReportDate";
import {PaymentSummary} from "../model/PaymentSummary";
import {AxiosResponse} from "axios";
import AdditionalPaymentDialog from "./AdditionalPaymentDialog/AdditionalPaymentDialog";
import { AdditionalPaymentTM} from "../model/AdditionalPayment";
import CreatorSummaryCard from "./CreatorSummaryCard";
import CreatorSummaryItemWrapper from "./CreatorSummaryItemWrapper";
import { getLockedYTPayment, lockYTPayment} from "../services/LockService";
import {LockedPayment} from "../model/LockedPayment";
import { PaymentsReq } from "../model/PaymentsReq";
import {CurrentMainSnackbar} from "../hooks/useMainSnackbar";
import { SyncAltOutlined } from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { YTSummary } from "../model/YTSummary";

const CreatorSummary = () => {
    const {year, month} = useContext(CurrentReportDate);
    const [paymentSummaries, setPaymentSummaries] = useState<PaymentSummary[]>([]);
    const [open, setOpen] = useState(false);
    const [kpeId, setKPEId] = useState('');
    const [creatorId, setCreatorId] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(100);
    const [loading, setLoading] = useState(true);
    const [YTProgressLoading, setYTProgressLoading] = useState(false);
    const [loadingPayments, setLoadingPayments] = useState(false);
    const [loadingCreator, setLoadingCreator] = useState(false);
    const [paymentsLocked, setPaymentsLocked] = useState(false);
    const [idsToExport, setIdsToExport] = useState<string[]>([]);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [checklist, setChecklist] = useState<YTSummary | null>(null);

    const refreshData = () => {
        setLoading(true);
        getPaymentSummary(year, month, 'tm')
            .then((res: AxiosResponse<PaymentSummary[]>) => {
                // for(let item in res.data) {
                //     res.data[item].id = res.data[item].crmCreator.creatorId;
                // }
                setPaymentSummaries(res.data)
                setCurrentPage(1);
                getLockedYTPayment(year, month)
                    .then((res: AxiosResponse<LockedPayment>) => {
                        if (res.status === 200) {
                            setPaymentsLocked(res.data.locked);
                            setLoading(false);
                        }
                    });
            })
            .catch((err) => console.error(err));
    }
    // const initialRender = useRef(true);

    const getStatusIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        return status ? <CheckCircleOutlineIcon sx={{color: 'green'}}/> : <ErrorOutlineIcon sx={{color: 'red'}}/>;
    }
    const ProcessIcon = (isProcessed:boolean)=>{
        return [getStatusIcon(isProcessed),isProcessed?"Processed":"Failed"]
     }

    const getProessedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 2 && 
               flags.affiliateTaxSummaryState === 2 &&
               flags.ecommerceState === 2 &&
               flags.assetSummaryState === 2 &&
               flags.redRawMusicState === 2 &&
               flags.redRawVideoState === 2 &&
               flags.videoSummaryState === 2 &&
               flags.shortsSubsState === 2 &&
               flags.claimsState === 2 &&
               flags.shortsAdsState === 2 &&
               flags.crmContractsStatus === 2 && 
               flags.calculationsStatus === 2;
    }

    const getProcessingStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 1 || 
               flags.affiliateTaxSummaryState === 1 ||
               flags.ecommerceState === 1 ||
               flags.assetSummaryState === 1 ||
               flags.redRawMusicState === 1 ||
               flags.redRawVideoState === 1 ||
               flags.videoSummaryState === 1 ||
               flags.shortsSubsState === 1 ||
               flags.claimsState === 1 ||
               flags.shortsAdsState === 1 || 
               flags.crmContractsStatus === 1 || 
               flags.calculationsStatus === 1;
    }

    const getNotStartedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return true;

        return flags.channelState === 0 && 
               flags.affiliateTaxSummaryState === 0 &&
               flags.ecommerceState === 0 &&
               flags.assetSummaryState === 0 &&
               flags.redRawMusicState === 0 &&
               flags.redRawVideoState === 0 &&
               flags.videoSummaryState === 0 &&
               flags.shortsSubsState === 0 &&
               flags.claimsState === 0 &&
               flags.shortsAdsState === 0 && 
               flags.crmContractsStatus === 0 && 
               flags.calculationsStatus === 0;
    }

    const getFailedStatus = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;

        return flags.channelState === 3 || 
               flags.affiliateTaxSummaryState === 3 ||
               flags.ecommerceState === 3 ||
               flags.assetSummaryState === 3 ||
               flags.redRawMusicState === 3 ||
               flags.redRawVideoState === 3 ||
               flags.videoSummaryState === 3 ||
               flags.shortsSubsState === 3 ||
               flags.claimsState === 3 ||
               flags.shortsAdsState === 3 ||
               flags.crmContractsStatus === 3 ||
               flags.calculationsStatus === 3;
    }

    const getPartialStatus = (flags: YTSummary | null): boolean => {
        const states = [
            flags?.affiliateTaxSummaryState,
            flags?.assetSummaryState,
            flags?.ecommerceState,
            flags?.redRawMusicState,
            flags?.redRawVideoState,
            flags?.videoSummaryState,
            flags?.claimsState,
            flags?.shortsAdsState,
            flags?.shortsSubsState,
            flags?.channelState,
            flags?.crmContractsStatus,
            flags?.calculationsStatus
                      ];

            const allStates = (value: number | undefined) => states.every((state) => state === value);
            const anyState = (value: number | undefined) => states.some((state) => state === value);

            switch (true) {
                    case flags === null || allStates(0):
                        return false;
                    case anyState(1):
                        return false;
                    case allStates(2):
                        return false;
                    case anyState(3):
                        return false;
                    case flags?.calculationsStatus === 1:
                        return false;
                    default:
                       return true;
                    }
    }

    const isExportable = (flags: YTSummary | null): boolean => {
        if (flags === null) return false;
        
        if(getPartialStatus(flags) || getProessedStatus(flags)) return false

        return true;
    }

    useEffect(() => {
        // if (initialRender.current) {
        //     initialRender.current = false;
        //     return;
        // }
        setChecklist(null);
        setPaymentSummaries([])
        setYTProgressLoading(true);
        setLoading(true)
        const intervalId = setInterval(() => {
            getProgress(year, month).then((res: AxiosResponse<YTSummary>) => {
                
                setYTProgressLoading(false);
                if(res.data) setChecklist(res.data);
                else setChecklist(null);
                
                if(res.data && getNotStartedStatus(res.data)){
                    clearInterval(intervalId);
                    setLoading(false);
                }
    
                else if(res.data && getProessedStatus(res.data)){
                    clearInterval(intervalId);
                    refreshData();
                }
                else if (res.data && getFailedStatus(res.data)) {
                  clearInterval(intervalId);
                    setLoading(false);
                }
                else if (res.data && getPartialStatus(res.data)) {
                    clearInterval(intervalId);
                    refreshData();
                }
                else setLoading(false);
            })
        }, 2000)
        
        return () => {
            clearInterval(intervalId);
        };
    }, [year, month]);

    const openManualAdjustment = (kpeId: string, creatorId: string, creatorName: string) => {
        setOpen(true);
        setKPEId(kpeId);
        setCreatorId(creatorId);
        setCreatorName(creatorName);
    }
    const handleClose = () => {
        setOpen(false);
        setKPEId('');
        setCreatorId('');
        setCreatorName('');
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const deleteAdditionalPayment = (id: string) => {
        setPaymentSummaries(paymentSummaries.map((ps) => {
            if(ps.tmAdditionalPayments){
            ps.totalEarnings -= ps.tmAdditionalPayments.find((ap) => ap.id === id)?.amount || 0;
            ps.tmAdditionalPayments = ps.tmAdditionalPayments.filter((ap) => ap.id !== id)}
            return ps;
        }));
    };

    const updateAdditionalPayment = (ap: AdditionalPaymentTM) => {
        setPaymentSummaries(paymentSummaries.map((ps) => {
            if (ps.channelPaymentSummaries.findIndex((cps) => cps.channelKpeId === ap.channelKpeId) > -1) {
                if(ps.tmAdditionalPayments){
                ps.tmAdditionalPayments = [...ps.tmAdditionalPayments, ap];
                ps.totalEarnings = ps.totalEarnings + ap.amount;}
            }
            return ps;
        }));
    }

    const lockUpPayment = () => {
        setLoading(true);
        lockYTPayment(year, month)
            .then(() => {
                getLockedYTPayment(year, month)
                    .then((res: AxiosResponse<LockedPayment>) => {
                        if (res.status === 200) {
                            setPaymentsLocked(res.data.locked);
                            setLoading(false);
                        }
                    });
            });
    }

    const exportData = () => {
        setLoadingCreator(true);
        const idsToExportString = idsToExport.length > 0 ? idsToExport.join(','): '';
        
        downloadCSV(year, month, 'talent-manager',idsToExportString).then((response) => {
            setLoadingCreator(false);
            downloadFile(response.data);
        })
    }

    const downloadFile = (file: Blob | MediaSource) => {
        const href = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `talent-manager-summary-${year}-${month}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const onSubmitPayments = () => {

        setLoadingPayments(true);
        const obj = dataFromChild ? JSON.parse(dataFromChild) : {};
        const creators: Array<string> = Object.keys(obj).filter((key) => obj[key]);
        if (creators.length < 1) {
            updateMainSnackbar(true, "error", "No records selected");
            setDataFromChild('');
            setLoadingPayments(false)
            return false;
        } else {
            let data: PaymentsReq = {
                "year": year,
                "month": month,
                "batchName":`TM-payment-${year}-${month}`,
                "ids": creators
            };
            // Todo: Improve error messages - Concat all the messages or show a table with each error (TBD:UI)
            submitTMPayments(data)
                .then((res: AxiosResponse<{ batchStatus: string }>) => {
                    if (res.data.batchStatus !== "ERROR") {
                        updateMainSnackbar(true, "success", "Selected payments have been sent");
                        setDataFromChild('');
                    } else {
                        updateMainSnackbar(true, "error", "An error has occurred. Try again");
                        setDataFromChild('');
                    }
                })
                .finally(() => setLoadingPayments(false))
        }
    }

    const [dataFromChild, setDataFromChild] = useState<string | null>(null);
    const receiveDataFromChild = (dataRef: React.MutableRefObject<string | null>) => {
        setDataFromChild(dataRef.current);
    };

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = paymentSummaries.filter((row) =>{

        const searchTermLower = searchTerm.toLowerCase();

        // Check if fullName matches the search term
        const fullNameMatch = row.fullName ? row.fullName.toLowerCase().includes(searchTermLower) : false;

        // Check if any channelName or channelKpeId matches the search term
        const channelMatch = row.channelPaymentSummaries.some((channel) => {
            const channelNameMatch = channel.channelName ? channel.channelName.toLowerCase().includes(searchTermLower) : false;
            const channelKpeIdMatch = channel.channelKpeId ? channel.channelKpeId.toLowerCase().includes(searchTermLower) : false;
            return channelNameMatch || channelKpeIdMatch;
        });

    return fullNameMatch || channelMatch;    
    }
    );
    
    return (
        <Box className={'youtube-summary-container'}>
            <Paper elevation={1} sx={{width: '90%',height:'85vh', py: 3, px: 4}}>
                <div style={{borderBottom: "1px solid rgb(224, 224, 224)", display:'flex'}}>
                <div style={{flex:0.4,flexDirection:'row'}}>
                    <Box sx={{display: 'flex', alignItems: 'center',pb:2}}>
                           {(loading || YTProgressLoading) && <CircularProgress size={20} sx={{ mr: 2 }} />}
                            <Typography variant={'h4'}  className={'h4-custom'} sx={{mr:2,display:'inline'}} noWrap >
                                Talent manager summary
                            </Typography>
                            {checklist !== null && 
                            getProcessingStatus(checklist) && 
                            (checklist.channelState !== 3 && checklist.edfState !== 3 && checklist.crmContractsStatus !== 3 && checklist.calculationsStatus !==3) &&
                            (<Button   className={'success-custom-cs'}
                                variant="outlined" 
                                sx={{ gap: '8px', color: '#FF7A00',borderColor:'#FF7A00',":hover":{borderColor:'#FF7A00'}}}
                                >
                                <svg width="25" height="25" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
                                </svg>
                                PROCESSING
                            </Button>)}    
                            {checklist !== null &&
                            getNotStartedStatus(checklist) && 
                            (<Button
                            className={'success-custom-cs'}
                            variant="outlined"
                            sx={{ gap: '8px',  color: 'blue',width: '11rem' }} 
                            >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.27 4.5L9.57 6H13.5V10.5H10.98L10.68 9H5.25V4.5H9.27ZM10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8L10.5 3Z" fill="#0A2AE7"/>
                            </svg>
                                YET TO START
                            </Button>)}  
                            {checklist != null &&
                            getProessedStatus(checklist) &&
                            (<Button  
                            className={'success-custom-cs'}
                            variant="outlined" 
                            sx={{ gap: '8px', color: 'green', }}
                            color="success"
                            >
                             {ProcessIcon(true)}
                            </Button>)}                  
                    </Box>
                    </div>
                    <div style={{justifyContent:'flex-end',alignItems:'flex-start',textAlign:'start',flex:0.6,display:'flex'}}>
                        <Button onClick={refreshData} variant={'outlined'} size="small" sx={{mr: 2,mt:0.5}}>
                        <RefreshOutlinedIcon />
                        </Button>
                        <TextField
                            label="Search"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            variant="standard"
                            sx={{height:"34px", mr: 2, mt: '-10px'}}
                            />
                        <Button disabled={paymentSummaries.length === 0 || !paymentsLocked || loading || loadingPayments || !getProessedStatus(checklist)} variant={'contained'} onClick={onSubmitPayments} sx={{mr: 1}}>
                            SUBMIT PAYMENTS
                            {loadingPayments ? <SyncAltOutlined sx={{ml: 1}}/>: <></>}
                        </Button>
                        {/* <Button disabled={paymentSummaries.length === 0 || !paymentsLocked || loading} variant={'contained'} onClick={lockUpPayment} sx={{mr: 1}} > */}
                        {/* <Button disabled={true} variant={'contained'} onClick={lockUpPayment} sx={{mr: 1}} >
                            LOCK PAYMENTS</Button> */}
                        {/* <Button disabled={paymentSummaries.length === 0 || loading || loadingPayments} variant={'contained'} onClick={exportData}> */}
                        <Button disabled={isExportable(checklist)} variant={'contained'} onClick={exportData}>
                            Export Data
                            {loadingCreator ? <RefreshOutlinedIcon className="refresh-btn refresh-animation" sx={{ml: 1}}/>: <></>}
                        </Button>
                        </div>
                    </div>
                {/* <Box sx={{width: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 4}}>
                    <CreatorSummaryCard
                        title={'Gross YT'}
                        total={paymentSummaries.reduce((acc, cur) => acc + cur.totalYTGrossAcrossChannels, 0)}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Net YT'}
                        total={paymentSummaries.reduce((acc, cur) => acc + cur.totalYTNetAcrossChannels, 0)}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Gross Paid Media'}
                        total={paymentSummaries.reduce((acc, cur) => acc + cur.totalGamGrossAcrossChannels, 0)}
                        loading={loading}
                    />
                    <CreatorSummaryCard
                        title={'Net Paid Media'}
                        total={paymentSummaries.reduce((acc, cur) => acc + cur.totalGamNetAcrossChannels, 0)}
                        loading={loading}
                    />
                </Box> */}
                <Box sx={{
                    width: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    {loading ? <Skeleton sx={{width: 1, height: 400}} variant={"rectangular"}/> :
                        <>
                            <CreatorSummaryItemWrapper
                                paymentSummaries={filteredData}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                loading={loading}
                                creatorSummary={true}
                                openManualAdjustment={openManualAdjustment}
                                deleteAdditionalPayment={deleteAdditionalPayment}
                                locked={paymentsLocked}
                                sendDataToParent={receiveDataFromChild}
                                idsToExport={idsToExport}
                                setIdsToExport={setIdsToExport}
                                searchedTerm={searchTerm}
                            />
                            <AdditionalPaymentDialog
                                open={open}
                                handleClose={handleClose}
                                kpeId={kpeId} talentManagerId={creatorId} creatorName={creatorName}
                                updateAdditionalPayments={updateAdditionalPayment}
                            />
                        </>}
                </Box>
            </Paper>
        </Box>
    )
}

export default CreatorSummary;