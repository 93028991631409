import {
    AccordionDetails, Avatar,
    Box,
    Button,
    Paper, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {PaymentSummary} from "../model/PaymentSummary";
import {ChannelPaymentSummary} from "../model/ChannelPaymentSummary";
import {useState} from "react";
import YTSummaryTable from "../YoutubeSummary/YTSummaryTable/YTSummaryTable";
import {YTEarningGroupEnum} from "../model/YTEarning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AdditionalPaymentTable from "./AdditionalPaymentTable";
import * as React from "react";

interface CSIProps {
    paymentSummary: PaymentSummary;
    openManualAdjustment: (kpeId: string, creatorId: string, creatorName: string) => void;
    deleteAdditionalPayment: (id: string) => void;
    isCreatorReview: boolean;
    locked: boolean;
    searchedTerm: string;
}

const formatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits:2,
    maximumFractionDigits:2
});

const CreatorSummaryItemDetail = (props: CSIProps) => {
    const {paymentSummary, openManualAdjustment} = props;
    const [showYTDetails, setShowYTDetails] = useState(-1);

    const updateShowYTDetails = (id: number) => {
        if (showYTDetails === id) {
            setShowYTDetails(-1);
        } else {
            setShowYTDetails(id);
        }
    }

    const highlightText = (text: string, highlight: string) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => 
            part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part
        );
    }

    return <>
        {paymentSummary.channelPaymentSummaries.map((ch: ChannelPaymentSummary, id: number) => (
            <>
                <TableRow key={ch.channelKpeId}>
                    <TableCell width={'16px'}></TableCell>
                    <TableCell width={'calc(46% - 8px)'} sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}} colSpan={2}>
                        <Avatar sx={{ width: 32, height: 32, display: 'inline-block', verticalAlign: 'middle' }} alt={ch?.channelName} src={ch?.channelName}/>
                        <Box sx={{display: 'inline-block', marginLeft: 1, verticalAlign: 'middle'}}>
                            <p style={{margin:'0', lineHeight:'1.2em'}}>{ch.error ? <ErrorOutlineIcon sx={{color: 'red'}}/> : <> </>}<b>{highlightText(ch.channelName, props.searchedTerm)}</b></p>
                            <span>
                                {ch.error ? <ErrorOutlineIcon sx={{color: 'red'}}/> : <> </>}
                                {highlightText(ch.channelKpeId, props.searchedTerm)}
                            </span>
                        </Box>
                    </TableCell>    
                    <TableCell width={'15%'} align={"right"}>
                        <b>{formatter.format(ch.ytGrossSummary ? ch.ytGrossSummary : 0)}</b>
                    </TableCell>
                    <TableCell width={'15%'} align={"right"}>
                        <b>
                            {!props.isCreatorReview ? formatter.format(ch.ytNetSummary ? ch.ytNetSummary : 0):
                            (ch.ytNetSummary + paymentSummary.additionalPayments.filter((ap) => ap.channelKpeId === ch.channelKpeId).reduce((acc, cur) => acc + cur.amount, 0)).toLocaleString(undefined, {
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                        </b>
                    </TableCell>
                    <TableCell width={'20%'} colSpan={2} align={"right"}>
                        {props.isCreatorReview ? <Box sx={{width: 1}}>
                            <Button
                                sx={{float: 'right'}}
                                variant={'text'}
                                onClick={() => openManualAdjustment(ch.channelKpeId, paymentSummary.crmCreator.creatorId, `${paymentSummary.crmCreator.firstName} ${paymentSummary.crmCreator.lastName}`)}
                                disabled={props.locked}
                            >
                                {`Manual Adjustment`}
                            </Button>
                        </Box> : <></>}
                    </TableCell>
                </TableRow>
                <TableRow key={ch.channelKpeId+id}>
                    <TableCell width={'12px'}></TableCell>
                    <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}></TableCell>
                    <TableCell></TableCell>
                    <TableCell align={"right"}>
                        Gross earnings (US$)
                    </TableCell>
                    <TableCell align={"right"}>
                        Net earnings (US$)
                    </TableCell>
                    <TableCell align={"right"}>
                        Rev share (%)
                    </TableCell>
                    <TableCell align={"center"}>
                        Reconciliation (US$)
                    </TableCell>
                </TableRow>
                {props.isCreatorReview ?
                    <>
                        <TableRow key={ch.channelKpeId+"PM"}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}><b>Paid Media</b></TableCell>
                            <TableCell>Talent Earnings</TableCell>
                            <TableCell align='right'>
                            {((ch.gamGrossSummary ? ch.gamGrossSummary : 0) + (ch.grossOverage ? ch.grossOverage : 0))
                            .toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align='right'>
                                {((ch.gamNetSummary ? ch.gamNetSummary : 0) + (ch.talentNetOverage ? ch.talentNetOverage : 0))
                                .toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align="right">
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow key={ch.channelKpeId+"LE"}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}><b></b></TableCell>
                            <TableCell>Licensing earnings</TableCell>
                            <TableCell align='right'>
                                {ch.gamBpGrossEarnings === null ?
                                (0).toFixed(2): 
                                ch.gamBpGrossEarnings.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align='right'>
                                {ch.gamBpEarnings === null ? 
                                (0).toFixed(2):
                                ch.gamBpEarnings.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align="right">
                            {typeof ch.bpRevShare === 'number' ? ch.bpRevShare.toFixed(2) : ''}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        
                        <TableRow key={ch.channelKpeId+"YT"}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}><b>Youtube</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell align='right'>
                            {ch.ytGrossSummary === null ?
                            (0).toFixed(2) : 
                            ch.ytGrossSummary.toLocaleString(undefined, {
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align='right'>
                            {ch.ytNetSummary === null ? 
                            (0).toFixed(2) :
                            ch.ytNetSummary.toLocaleString(undefined, {
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                            })}</TableCell>
                        </TableRow>
                    </>
                    : <></>}
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.AD}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.AD)}
                    sx={{pt: 2}}
                    key={ch.channelKpeId+"AD"}
                />
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.SUBSCRIPTION}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.SUBSCRIPTION)}
                    sx={{pt: 2}}
                    key={ch.channelKpeId+"SB"}
                />
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.PAID_FEATURE}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.PAID_FEATURE)}
                    sx={{pt: 2}}
                    key={ch.channelKpeId+"PF"}
                />
                {paymentSummary.additionalPayments.filter((ap) => ap.channelKpeId === ch.channelKpeId).length > 0 ?
                    <AdditionalPaymentTable
                        sx={{mt: 2}}
                        additionalPayments={paymentSummary.additionalPayments.filter((ap) => ap.channelKpeId === ch.channelKpeId)}
                        deleteAdditionalPayment={props.deleteAdditionalPayment}
                        locked={props.locked}
                    />
                    :
                    <></>
                }
                <TableRow className={'last-child'}>
                    <TableCell/><TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}} colSpan={5}/>
                </TableRow>
            </>))}
    </>
}
export default CreatorSummaryItemDetail;