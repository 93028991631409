import {
    Autocomplete,
    Box, Button, Grid,
    Skeleton,
    TextField
} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {downloadAssignedAssetsCSV, loadAssignedAssets, unassignAsset,getUniqueCMS} from "../../services/YTService";
import {AxiosResponse} from "axios";
import {AssignedAsset} from "../../model/AssignedAsset";
import {ContentOwnerResponse} from "../../model/ContentOwner";
import {Page} from "../../model/Page";
import {DataGrid, GridColDef, GridRenderCellParams, GridRowId} from "@mui/x-data-grid";
import { CurrentReportDate } from "../../hooks/useReportDate";

interface AssignedAssetsProps {
    assignedAssets: any;
    setAssignedAssets: any;
    unassignedAssets: any;
    setUnassignedAssets: any;
    uniqueCMSArray: any;
    setUniqueCMSArray: any;
    loading:boolean;
}

const AssignedAssets = (props: AssignedAssetsProps) => {
    const {assignedAssets, setAssignedAssets, unassignedAssets, setUnassignedAssets,uniqueCMSArray,setUniqueCMSArray,loading} = props;
    // const [assignedAssets, setAssignedAssets] = useState<AssignedAsset[]>([]);
    // const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const {year, month} = useContext(CurrentReportDate);
    // const [recordsPerPage, setRecordsPerPage] = useState(100);
    const [cmsFilter, setCMSFilter] = useState<string | null>(null);
    // const [uniqueCMSArray, setUniqueCMSArray] = useState<string[]>([]);

    // useEffect(() => {
        // getUniqueCMS().then((res: AxiosResponse<ContentOwnerResponse>) => {    
        // const uniqueCMS = res.data.contentOwners.map(contentOwner => contentOwner.name) 
        // setUniqueCMSArray(uniqueCMS);           
        // });
        // loadAssignedAssets(year, month)
        //   .then((res: AxiosResponse<AssignedAsset[]>) => {
        //         // setRecordsPerPage(res.data.totalElements)
        //         // setPages(res.data.totalPages);
        //         const updatedAssets = res.data.map((asset) => {
        //             return {
        //                 ...asset,
        //                 id: asset.assetId,
        //                 channelName: asset.channelName ? asset.channelName: "",
        //                 creatorName: asset.creatorName ? asset.creatorName: ""
        //             }
        //         })
        //         setAssignedAssets(updatedAssets);
        //     });
    // },[]);

    const getBoldValue = (params: GridRenderCellParams<string>) => {
        return (<b>{params.id}</b>);
    }
    const getEmptyCol = (params: GridRenderCellParams<string>) => {
        return (<span>{' '}</span>);
    }

    const unassign = (id: GridRowId) => {
        unassignAsset(id.toString()).then(() => {
            setAssignedAssets(assignedAssets.filter((asset: { assetId: string; })=> asset.assetId !== id.toString()))
            const asset = assignedAssets.find((asset: { assetId: string; }) => asset.assetId === id.toString());
            setUnassignedAssets([...unassignedAssets, {
                ...asset,
                creator: asset.creatorName,
            }]);
        });
    }

    const getUnAssignButton = (params: GridRenderCellParams<string>) => {
        return (<Button onClick={() => unassign(params.id)}>{`Unassign`}</Button>);
    }

    const downloadFile = (file: Blob | MediaSource) => {
        const href = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `assigned-assets.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const exportData = () => {
        downloadAssignedAssetsCSV().then((response) => {
            downloadFile(response.data);
        })
    }

    const columns: GridColDef[] = [
        { field: 'assetId', headerName: 'Asset ID', flex: 1, renderCell: getBoldValue },
        { field: 'channelKpeId', headerName: 'Channel ID', flex: 1.3},
        { field: 'channelName', headerName: 'Channel Name', flex: 1},
        { field: 'creatorName', headerName: 'Creator Name', flex: 1},
        { field: 'assetTitle', headerName: 'asset Title', flex: 1},
        { field: 'assetLabels', headerName: 'Asset Labels', flex: 1},
        { field: 'customId', headerName: 'Custom ID', flex: 1},
        { field: 'cms', headerName: 'CMS', flex: 1},
        { field: 'assignedDate', headerName: 'Assigned Date', flex: 1},
        {
            field: 'UnassignButton',
            headerName: '',
            width: 168,
            renderCell: getUnAssignButton,
            flex: 1,
            sortable: false,
            align: 'right'
        },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const updateCMSFilter = (
        event: React.ChangeEvent<{}>,
        newValue: string | null
    ) => {
        setCMSFilter(newValue);
    };


    const filteredData = useMemo(()=>{
         
        return assignedAssets.filter((row: { assetId: string; channelName: string; creatorName: string; channelKpeId: string; assetTitle: string; assetLabels: string; customId: string; cms: string; }) =>{
            // @ts-ignore
            return (row.assetId ?
                (row.channelName?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.creatorName?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.assetId?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.channelKpeId?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.assetTitle?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.assetLabels?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.customId?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row.cms?.toLowerCase().includes(searchTerm.toLowerCase()))
                : false) && (cmsFilter ? row.cms === cmsFilter : true);
        }
        );
    },[assignedAssets, searchTerm, cmsFilter])

    return (
        <>
       {loading ? 
       <Skeleton sx={{width: 1, height: 400, mt: 4}} variant={"rectangular"}/>:
       <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container className="filters-container" style={{marginTop:"20px"}}>
                <Grid item xs={2} >
                    <Autocomplete
                        disablePortal
                        id="cms-filter-combo-box"
                        options={uniqueCMSArray}
                        renderInput={(params) => <TextField {...params} label="CMS" />}
                        onChange={updateCMSFilter}
                        value={cmsFilter}
                    />
                </Grid>
            </Grid>
            <div style={{ height: 'calc(100vh - 400px)', width: '100%', marginTop: '24px', position:'relative'}}>
                <TextField
                    label="Search"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    size="small"
                    variant="standard"
                    sx={{
                        height:"34px", 
                        position: 'absolute', 
                        right: 0, 
                        top: '-78px'

                    }}/>
                <Button disabled={assignedAssets.length === 0 } variant={'contained'} onClick={exportData} sx={{
                    height:"34px",
                    position: 'absolute',
                    right: 350,
                    top: '-65px'
                }}>
                    Export Data
                </Button>
                <DataGrid sx={{mt: 2}} checkboxSelection disableColumnMenu rows={filteredData} columns={columns} className={"custom-table-container"} components={{
                    NoRowsOverlay: () => <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',bgcolor:'#b2bcfc'}}>
                        {filteredData?.length===0 ? <p>No Manually Assigned Assets Found</p> : <></>}
                    </Box>
                }} />
            </div>
       </Box>}
        </>
    )
}

export default AssignedAssets