//External imports
import {useContext, useEffect, useRef} from 'react'
import {getGoogleEndpoint, getUserInfo, loginGoogle} from "../services/UserService";
import {AxiosError, AxiosResponse} from "axios";
import {ACCESS_TOKEN, GOOGLE_CLIENT} from "./loginConstants";
import {User} from "../model/User";
import {CurrentUserInfo} from "../hooks/useUserInfo";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";

const loadScript = (src: any) =>
    new Promise<void>((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    });

declare let google: any;

const GoogleAuth = () => {
    const {updateUser} = useContext(CurrentUserInfo);
    const nav = useNavigate();
    const googleButton = useRef(null);

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client';
        const id = GOOGLE_CLIENT;

        loadScript(src)
            .then(() => {
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                })
                google.accounts.id.renderButton(
                    googleButton.current,
                    {theme: 'outline', size: 'large'}
                )
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    function handleCredentialResponse(response: any) { 
        loginGoogle(response.credential).then((res: AxiosResponse<string>) => {
            localStorage.setItem(ACCESS_TOKEN, res.data);
            getUserInfo().then((res: AxiosResponse<User>) => {
                updateUser(res.data).then(() => (nav('/youtube/view')));
            });
        }).catch((err: AxiosError) => console.log('error', err));
    }

    return (
        <Box sx={{ my: 'auto', display: 'inline-flex'}}>
            <div ref={googleButton}></div>
        </Box>
    )
}

export default GoogleAuth