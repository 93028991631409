import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AccordionSummary, Typography} from "@mui/material";
import {PaymentSummary} from "../model/PaymentSummary";

interface CSHDProps {
    paymentSummary: PaymentSummary;
    creatorSummary: boolean;
}

const CreatorSummaryHeaderDetail = (props: CSHDProps) => {
    const {paymentSummary} = props;
    const calculateNetPayment = (): number => {
        if (props.creatorSummary) {
            return (paymentSummary.totalYTNetAcrossChannels + paymentSummary.totalGamNetAcrossChannels + paymentSummary.additionalPayments.reduce((acc, cur) => acc + cur.amount, 0));
        }
        return paymentSummary.totalYTNetAcrossChannels;
    }
    return (<AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
    >
        <Typography variant='h6' sx={{width: '33%', flexShrink: 0}}>
            {paymentSummary.crmCreator.firstName} {paymentSummary.crmCreator.lastName}
        </Typography>
        <Typography sx={{width: '33%', color: 'text.secondary', ml: 1}}>
            {(paymentSummary.totalYTGrossAcrossChannels).toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </Typography>
        <Typography sx={{width: '33%', ml: 1}}>
            {(paymentSummary.totalEarnings).toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
        </Typography>
    </AccordionSummary>);
}
export default CreatorSummaryHeaderDetail;