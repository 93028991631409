import {
    Autocomplete,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, TextField
} from "@mui/material";
import {useContext, useState} from "react";
import {assignAsset} from "../../services/YTService";
import {CurrentReportDate} from "../../hooks/useReportDate";

interface UnassignedAssetDialogProps {
    open: boolean;
    assetId: string;
    channels: string[];
    saveTransaction: (channel: string) => void;
    handleClose: () => void;
    assignedAssets: any;
    setAssignedAssets: any;
}

const UnassignedAssetDialog = (props: UnassignedAssetDialogProps) => {
    const {assignedAssets,setAssignedAssets} = props
    const [channel, setChannel] = useState(props.channels[0]);
    const {year, month} = useContext(CurrentReportDate);
    const cancelTransaction = () => {
        handleClose();
    }
    const handleClose = () => {
        setChannel(props.channels[0]);
        props.handleClose();
    }

    const fetchYtChannelId = (channelID: any) => {
        const regex = /\(([^)]+)\)/;

        const match = channelID.match(regex);

        if (match && match.length > 1) {
            const ytChannelID = match[1].substring(2, channel.length);
            return ytChannelID;
        }
    }

    const saveTransaction = () => {
        assignAsset({
            year: year,
            month: month,
            assetId: props.assetId,
            channelId: fetchYtChannelId(channel)
        }).then((res) => {

         const newAssignedAsset = {
            ...res.data,
            id: res.data.assetId,
            channelName: res.data.channelName ? res.data.channelName: "",
            creatorName: res.data.creatorName ? res.data.creatorName: ""
        }
        setAssignedAssets([...assignedAssets,newAssignedAsset]);
        })

        setChannel(props.channels[0]);
        props.saveTransaction(props.assetId);
    }

    return (
        <Dialog open={props.open} onClose={cancelTransaction}>
            <DialogTitle>{props.assetId}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Assign Asset to a Channel
                </DialogContentText>
                <Autocomplete
                    id="channel-combo-box"
                    options={props.channels}
                    getOptionLabel={(option) => option}
                    sx={{ width: 400, mt: 2 }}
                    value={channel}
                    onChange={(event: any, newValue: string | null) => setChannel(newValue === null ? '' : newValue)}
                    renderInput={(params) => <TextField {...params} label="Channel KPE Id" />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelTransaction}>Cancel</Button>
                <Button onClick={saveTransaction}>Assign</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnassignedAssetDialog;