import {
    Box,
    Skeleton,
    IconButton,
    TableContainer,
    Table,
    TableCell, TableRow, TableHead, TableBody, Collapse, TablePagination,
    Checkbox,
    Tooltip,
    TextField
} from "@mui/material";
import React, {ReactNode, useEffect, useRef, useState, useContext} from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CreatorSummaryItemDetail from "./CreatorSummaryItemDetail";
import {PaymentSummary} from "../../model/PaymentSummary";
import CreatorSummaryHeaderDetail from "./CreatorSummaryHeaderDetail";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {CurrentMainSnackbar} from "../../hooks/useMainSnackbar";

interface CSIWProps {
    paymentSummaries: PaymentSummary[];
    currentPage: number;
    recordsPerPage: number;
    loading: boolean;
    creatorSummary: boolean;
    deleteAdditionalPayment: (id: string) => void;
    openManualAdjustment: (kpeId: string, creatorId: string, creatorName: string) => void;
    locked: boolean;
    sendDataToParent?: (dataRef: React.MutableRefObject<string | null>) => void;
    idsToExport ?: string[];
    setIdsToExport ?: (ids: string[]) => void;
    searchedTerm ?: string;
}

const formatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits:2,
    maximumFractionDigits:2
});

const CreatorSummaryItemWrapper = (props: CSIWProps) => {
    const [renderedRows, setRenderedRows] = useState<ReactNode[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const {paymentSummaries, currentPage, recordsPerPage, openManualAdjustment, deleteAdditionalPayment} = props;

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: PaymentSummary[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        return stabilizedThis.map((el) => el[0]);
    }

    useEffect(() => {
        setRenderedRows(paymentSummaries.filter((ps, idx) =>
                idx >= ((currentPage - 1) * recordsPerPage) && idx < currentPage * recordsPerPage
            ).map((paymentSummary, index) => (
                <>
                    <p>{props.locked}</p>
                    <CreatorSummaryHeaderDetail paymentSummary={paymentSummary} creatorSummary={props.creatorSummary}/>
                    <CreatorSummaryItemDetail
                        paymentSummary={paymentSummary}
                        openManualAdjustment={openManualAdjustment}
                        deleteAdditionalPayment={deleteAdditionalPayment}
                        isCreatorReview={props.creatorSummary}
                        locked={props.locked}
                        searchedTerm={props.searchedTerm ? props.searchedTerm : ""}
                    />
                </>
            ))
        );
    }, [props.locked, props.currentPage, props.paymentSummaries, props.recordsPerPage, props.openManualAdjustment, props.deleteAdditionalPayment, expanded]);


    const getFullName = (params: GridRenderCellParams<string>) => {
        return (<>{`${params.row.crmCreator.firstName} ${params.row.crmCreator.lastName}`}</>);
    }
    const getStatusName = (params: GridRenderCellParams<string|any>) => {
        params.row = params;
        switch (params.row.crmCreator.recipientStatus) {
            case 'NOT_INVITED':
                return "Not invited"
            case 'CREATED':
                return "Created"
            case 'INVITATION_SENT':
                return "Invitation sent"
            case 'ACCEPTED':
                return "Accepted"
            case 'DECLINED':
                return "Declined"
            case 'ERROR':
                return "-"
        }
        return "Undefined";
    }
    const getTotalGross  = (params: GridRenderCellParams<string>) => {
        return (<>{`${params.row.totalYTGrossAcrossChannels}`}</>);
    }
    const getTotalNet  = (params: GridRenderCellParams<string>) => {
        return (<>{`${params.row.totalYTNetAcrossChannels}`}</>);
    }

    const [open, setOpen] = useState(false);
    const getCollapsable  = (params: GridRenderCellParams<string>) => {
        return (<>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </>);
    }

    const columns: GridColDef[] = [
        { field: 'creatorName', headerName: 'Creator Names', renderCell: getFullName, flex: 2, align: 'left'},
        { field: 'recipientStatus', headerName: 'Status', flex: 2, align: 'center'},
        { field: 'channelKpeId', headerName: 'Total YT Gross Earnings  (US$)', renderCell: getTotalGross, headerAlign: 'right', align: "right", flex: .4, minWidth: 224 },
        { field: 'channelName', headerName: 'Total YT Net Earnings  (US$)', renderCell: getTotalNet, headerAlign: 'right', align: "right", flex: .4, minWidth: 224} ,
        { field: 'collapsable', headerName: '', renderCell: getCollapsable, flex: .1, minWidth: 168, align: "right"},
    ];


    const [checked, setChecked] = useState([]);
    const { idsToExport,setIdsToExport} = props

    const dataRef = useRef<string | null>(null);
    useEffect(() => {
        dataRef.current = JSON.stringify(checked);
    }, [checked]);

    useEffect(() => {
        if(props.sendDataToParent) props.sendDataToParent(dataRef);
    }, [props.sendDataToParent, checked]);

    useEffect(() => {
        if(idsToExport && setIdsToExport) {
            isAllSelected ?
            setIdsToExport([]):
            setIdsToExport(Object.keys(checked).filter((key: string) => checked[key as keyof typeof checked]));
        }
    }, [checked]);

    const Row = (props: {row: any, creatorSummary: boolean, locked: boolean, searchedTerm: string}) => {
        const [open, setOpen] = React.useState(false);
        const calculateNetPayment = (): number => {
            if (props.creatorSummary) {
                return (props.row.totalYTNetAcrossChannels + props.row.totalGamNetAcrossChannels + props.row.additionalPayments.reduce((acc: any, cur: any) => acc + cur.amount, 0));
            }
            return props.row.totalYTNetAcrossChannels;
        }

        const handleChecked = (id: string) => {
            return (e: React.ChangeEvent<HTMLInputElement>) => {
                const { checked } = e.target;
                setIsAllSelected(false);
                setChecked((values) => ({
                    ...values,
                    [id]: checked
                }));
            }
        };

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}  className={open ? 'active-row': ''}>
                    <TableCell sx={{width: !props.creatorSummary ? "0": "auto"}}>
                    {props.creatorSummary ? <Tooltip title={props.row.crmCreator.recipientStatus!=='ACCEPTED' ? props.row.crmCreator.email : "No ProWallet"}>
                            <span>
                                <Checkbox
                                     // Ignoring this condition for now
                                    // disabled={props.row.crmCreator.recipientStatus!=='ACCEPTED'}
                                    name="selectedChecked[]"
                                    value={props.row.crmCreator.creatorId}
                                    checked={isAllSelected || checked[props.row.crmCreator.creatorId] || false}
                                    onChange={handleChecked(props.row.crmCreator.creatorId)}/>
                            </span>
                        </Tooltip>:<></>}
                    </TableCell>
                    <TableCell width={'30%'}>
                        <b>{props.row.crmCreator.firstName} {props.row.crmCreator.lastName}</b>
                    </TableCell>
                    <TableCell width={'10%'} align="center">{getStatusName(props.row)}</TableCell>
                    <TableCell width={'20%'} align="right">{formatter.format(props.row.totalYTGrossAcrossChannels)}</TableCell>
                    <TableCell width={'19%'} align="right">
                        {formatter.format(props.row.totalEarnings)}
                    </TableCell>
                    <TableCell width={'15%'} align="right" colSpan={2}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                            color={'primary'}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow className={open ? 'collapsable-ctn active-row': 'collapsable-ctn'}>
                    <TableCell sx={{ p: '0 !important' }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit sx={{ margin: 0 }}>
                            <Box sx={{ margin: 0 }}>
                                <Table>
                                    <TableBody>
                                        <CreatorSummaryItemDetail
                                            paymentSummary={props.row}
                                            openManualAdjustment={openManualAdjustment}
                                            deleteAdditionalPayment={deleteAdditionalPayment}
                                            isCreatorReview={props.creatorSummary}
                                            locked={props.locked}
                                            searchedTerm={props.searchedTerm}
                                        />
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof PaymentSummary>('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    useEffect(() => setPage(0),[paymentSummaries])

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };
    const visibleRows = React.useMemo(
        () => {
            const filtered  = paymentSummaries.filter((row) =>{
                // @ts-ignore
                // console.log(row.crmCreator.firstName?.toLowerCase() + ' ' + row.crmCreator.lastName?.toLowerCase(), '===>', searchTerm.toLowerCase());
                let channelNames = row.channelPaymentSummaries.map((cps) => `${cps.channelName}${cps.channelKpeId}`).join(' ');
                // @ts-ignore
                return (
                    (row.crmCreator.firstName?.toLowerCase() + ' ' + row.crmCreator.lastName?.toLowerCase() + channelNames.toLowerCase()).includes(searchTerm.toLowerCase())
                ) ? true: false;
            }
            );
            return stableSort(filtered, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            )
        },
        [order, orderBy, page, rowsPerPage, paymentSummaries, searchTerm],
    );
    
    const [isAllSelected, setIsAllSelected] = useState(false);
    const onSelectAllClick = () => {
        setIsAllSelected(!isAllSelected);
        let all = {};
        let counter = 0;
        paymentSummaries.filter((item) => {
            // Ignoring this condition for now
            // return item.crmCreator.recipientStatus==='ACCEPTED'
            return true;
        }).map((item) => {
            counter++;
            Object.assign(all, {...all, [item.crmCreator.creatorId]: true})
            return {[item.crmCreator.creatorId]: true}
          })
          if(!isAllSelected) {
            // @ts-ignore
            setChecked(all);
            if(counter) {
                updateMainSnackbar(true, "success", `${counter} rows selected`);
            } else  {
                updateMainSnackbar(true, "error", `0 rows selected`);
            }
          } else {
            // @ts-ignore
            setChecked({});
          }
    };

    // const filteredData = paymentSummaries.filter((row) =>{
    //     // @ts-ignore
    //     console.log(row.crmCreator.firstName?.toLowerCase() + ' ' + row.crmCreator.lastName?.toLowerCase(), '===>', searchTerm.toLowerCase());
    //     // @ts-ignore
    //     return ((row.crmCreator.firstName?.toLowerCase() + ' ' + row.crmCreator.lastName?.toLowerCase()).includes(searchTerm.toLowerCase())) ? true: false;
    // }
    // );
    return (
        <>{props.loading ? <Skeleton sx={{width: 1, height: 400, mt: 4}} variant={"rectangular"}/> :
            <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{ height: 500, width: '100%', marginTop: '10px', position: 'relative'}}>
                    {props.creatorSummary ? '':
                        <TextField
                        label="Search"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        size="small"
                        variant="standard"
                        sx={{
                            height:"34px", 
                            position: 'absolute', 
                            right: 0, 
                            top: '-78px'

                        }}
                        />
                    }
                    <TableContainer component={Box} className={'custom-table-container'} sx={{ height: 'calc(100vh - 370px)' }}>
                        <Table aria-label="collapsible table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" sx={{width: !props.creatorSummary ? "0": "auto"}}>
                                        {props.creatorSummary ? <Checkbox
                                            color="primary"
                                            checked={isAllSelected}
                                            onChange={onSelectAllClick}
                                            inputProps={{
                                            'aria-label': 'select all boxes',
                                            }}
                                            disabled={visibleRows.length < 1}
                                        />: <></>}
                                    </TableCell>
                                    { columns.map((obj, i) => {
                                        return (<TableCell align={obj.align} key={obj.headerName}>{obj.headerName}</TableCell>)
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {visibleRows.length === 0 ?
                                    <TableRow sx={{height:'100%'}}>
                                        <TableCell colSpan={8} sx={{height:'100%',p:0}}>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: '24rem', // You can adjust the height as needed
                                              backgroundColor: '#b2bcfc',
                                              color: 'black', 
                                              textAlign: 'center'
                                            }}
                                          >
                                            No rows
                                          </Box>
                                        </TableCell>
                                    </TableRow>
                                :
                                visibleRows.map((row, i) => (
                                    <Row key={i} row={row} creatorSummary={props.creatorSummary} locked={props.locked} searchedTerm={props.searchedTerm ? props.searchedTerm : ""} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        sx={{borderBottom:'1px solid #e0e0e0',borderRight:'1px solid #e0e0e0',borderLeft:'1px solid #e0e0e0'}}
                        count={paymentSummaries.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </Box>
        }</>
    )
}

export default CreatorSummaryItemWrapper;