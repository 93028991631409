import {Box, Link, Typography} from "@mui/material";

const LoginFooter = (props: {sx?:object | null | undefined}) => {
    const typographyVariant = 'caption';
    return (
        <Box sx={{fontSize: "12px", ...props.sx}}>
            <Box>
                <Typography variant={typographyVariant} sx={{pr: .5, fontSize:{xs: "10px", md:"12px"}}}>If you need to update your Google and YouTube app
                    permissions
                    click</Typography>
                <a href='https://myaccount.google.com/permissions' target="_blank" rel="noreferrer">here</a>
                <Typography variant={typographyVariant} sx={{fontSize:{xs: "10px", md:"12px"}}}>. </Typography>
            </Box>
            <Box sx={{pt: .5, pb: 5}}>
                <Box sx={{fontSize:{xs: "10px", md:"12px"}}}>
                    <Typography variant={typographyVariant} sx={{pr: 1, fontSize:{xs: "10px", md:"12px"}}}>&copy; Bent Pixels 2022</Typography>
                    <a href='https://www.bentpixels.com/terms-of-service/' target="_blank" rel="noreferrer">Terms of Service</a>
                    <Typography variant={typographyVariant} sx={{pl: 1, pr: 1, fontSize:{xs: "10px", md:"12px"}}} >&#x2022;</Typography>
                    <a href='https://www.bentpixels.com/privacy-policy/' target="_blank" rel="noreferrer">Privacy Policy </a>
                </Box>
            </Box>
        </Box>

    );

};

const ExternalLink = (props: {href: string, displayText: string}) => {
    return (
        <Link href={props.href} target='blank' sx={{textDecoration: "none"}}>
            <Typography variant={"caption"} color={"primary"}>{props.displayText}</Typography>
        </Link>
    );

};

export default LoginFooter;