import {Box, Paper, Typography} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {

    const redirect = useNavigate();

    useEffect(() => {
        redirect('/youtube/view');
        console.log('redirecting')
    }, [redirect]);
    
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Paper elevation={3} sx={{
            maxWidth: '60%',
            minWidth: '40%',
            mt: 8,
        }}>
            <Typography variant='h3' sx={{mt: 4, px: 15}}>
                Getting Started
            </Typography>
            <Typography variant={'body1'} sx={{mt: 4, px: 15}}>
                Welcome to the app. To get started select a year and month in the top right corner.
                The app <strong>defaults to the first month of the current year.</strong>
            </Typography>
            <Typography variant={'body1'} sx={{mt: 4, px: 15}}>
                To navigate to the different screens select the Menu in the top left and navigate to the
                area of content you would like to review.
            </Typography>
            <Typography variant={'body1'} sx={{my: 4, px: 15}}>
                Enjoy!
            </Typography>
        </Paper>
        </Box>
    );
}

export default Home;