import {AxiosPromise} from "axios";
import {AssetAssignment} from "../model/AssetAssignment";

import axiosInstance from "../hooks/useUserInfo"
export const loadUnassignedAssets = (year: number, month: number, page: number = 0, size: number = 100000): AxiosPromise => {
    return axiosInstance.get(`/api/v1/asset/unassigned/${year}/${month}`);
}

export const assignAsset = (assetAssignment: AssetAssignment): AxiosPromise => {
    return axiosInstance.post(`/api/v1/asset/assign/${assetAssignment.year}/${assetAssignment.month}`, assetAssignment);
}

export const unassignAsset = (assetId: string,page: number=1, size: number=100): AxiosPromise => {
    return axiosInstance.post(`/api/v1/asset/unassign/${assetId}`,{params: {page, size}});
}

export const loadAssignedAssets = (year: number, month: number, page: number = 0, size: number = 100000): AxiosPromise => {
    return axiosInstance.get(`/api/v1/asset/assigned/${year}/${month}`,{
        timeout: 300000, //5 mins
    });
}

export const getUniqueCMS = (): AxiosPromise => {
    return axiosInstance.get(`api/v1/asset/content-owners`);
}

export const getYTChannels = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/asset/channels/${year}/${month}`);
}

export const downloadUnassignedAssetsCSV = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/asset/csv-export/unassigned-assets/${year}/${month}`, { responseType: 'blob' });
}

export const downloadAssignedAssetsCSV = (): AxiosPromise => {
    return axiosInstance.get(`/api/v1/asset/csv-export/assigned-assets`, { responseType: 'blob' });
}