import {Box, Paper, Tab, Tabs} from "@mui/material";
import Review from "../Review/Review";
import {useState} from "react";

function a11yProps(index: number) {
    return {
        id: `gam-tab-${index}`,
        'aria-controls': `gam-tabdetails-${index}`,
    };
}


const MainView = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', pt: "96px", pl: 5, pr: 2}} className={'review-container'}>
            <Paper elevation={1} sx={{width: '90%', pt: 3, pb: 2, px: 4, mb: 5, borderRadius: "4px 4px 16px 16px"}}>
                <Review enableNext={(x) => {}} newRun={false} />
            </Paper>
        </Box>
    );
}

export default MainView;