import {Box, Button, Paper} from "@mui/material";
import {ReactNode, useContext, useEffect, useState} from "react";
import Review from "../Review/Review";
import {getGamChecklistByYearAndMonth, processGamData} from "../services/GamRunService";
import {CurrentReportDate} from "../hooks/useReportDate";
import {AxiosResponse} from "axios";
import {GamChecklist} from "../model/GamChecklist";
import {useNavigate} from "react-router-dom";

const Run = () => {
    const navigate = useNavigate();
    const {year, month} = useContext(CurrentReportDate);
    const [activeStep, setActiveStep] = useState(0);
    const [stepComponent, setStepComponent] = useState<ReactNode>();
    const [nextEnabled, setNextEnabled] = useState(false);
    const [checkList, setCheckList] = useState<GamChecklist>();

    const enableNext = (enabled: boolean) => {
        setNextEnabled(enabled);
    }

    useEffect(() => {
        getGamChecklistByYearAndMonth(year, month)
            .then((res: AxiosResponse<GamChecklist>) => {
                setCheckList(res.data);
            });
        setActiveStep(0);
    }, [year, month])

    const handleNext = () => {
        processGamData(year, month);
        navigate('/gam/view');
    };

    return (
        <Box className={'"review-container'} sx={{width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', background: 'red'}}>
            <Paper elevation={3} sx={{width: '60%', p: 8, mt: 8}}>
                <>
                    <Review enableNext={enableNext} newRun={true}/>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Box sx={{flex: '1 1 auto'}}/>
                        <Button variant={'contained'} onClick={handleNext}>
                            {'Execute GAM and CRM load'}
                        </Button>
                    </Box>
                </>
            </Paper>
        </Box>
    );
}

export default Run;