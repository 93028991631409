import {Card, CardContent, Skeleton, Typography} from "@mui/material";

interface CreatorSummaryCardProps {
    title: string;
    total: number;
    loading: boolean;
}

const CreatorSummaryCard = (props: CreatorSummaryCardProps) => {
    return (
        <>
            {props.loading ?
                <Skeleton sx={{minWidth: 175, minHeight: 97, width: 1, mt: 2}} variant={"rectangular"}/>
                :
                <Card sx={{minWidth: 175, width: 1, mt: 2, mr: 2, background: "#FAFAFA"}} elevation={0}>
                    <CardContent>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            {props.title}
                        </Typography>
                        <Typography variant="h6"  sx={{fontSize: 16}} component="div">
                            {props.total.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </Typography>
                    </CardContent>
                </Card>}
        </>
    );
}
export default CreatorSummaryCard;