import {Box} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {PaymentSummary} from "../model/PaymentSummary";
import {CurrentReportDate} from "../hooks/useReportDate";
import {getErredPayments} from "../services/CreatorSummaryService";
import {AxiosResponse} from "axios";
import CreatorSummaryCard from "../CreatorSummary/CreatorSummaryCard";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";

const formatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits:2,
    maximumFractionDigits:2
});

const YouTubeErrors = () => {
    const {year, month} = useContext(CurrentReportDate);
    const [erredRecords, setErredRecords] = useState<PaymentSummary[]>([]);
    useEffect(() => {
        getErredPayments(year, month)
            .then((res: AxiosResponse<PaymentSummary[]>) => {
                for(let item in res.data) {
                    res.data[item].id = `${res.data[item].channelPaymentSummaries[0].channelName}`;
                    res.data[item].firstName = `${res.data[item].crmCreator.firstName}`;
                    res.data[item].lastName = `${res.data[item].crmCreator.lastName}`;
                    res.data[item].channelName = `${res.data[item].channelPaymentSummaries[0].channelName}`;
                    res.data[item].errorMessage = `${res.data[item].channelPaymentSummaries[0].errorMessage}`;
                    res.data[item].ytGrossSummary = `${formatter.format(res.data[item].channelPaymentSummaries[0].ytGrossSummary)}`;
                    res.data[item].channelKpeId = `${res.data[item].channelPaymentSummaries[0].channelKpeId}`;
                }
                setErredRecords(res.data);
            })
    }, [year, month]);

    const getBoldFirstValue = (params: GridRenderCellParams<string>) => {
        return (<b>{params.value}</b>);
    }

    const getBoldLastValue = (params: GridRenderCellParams<string>) => {
        return (<b>{params.value}</b>);
    }

    const columns: GridColDef[] = [
        { field: 'firstName', headerName: 'First Name', flex: 1, renderCell: getBoldFirstValue },
        { field: 'lastName', headerName: 'Last Name', flex: 1, renderCell: getBoldLastValue },
        { field: 'channelName', headerName: 'Channel Name', flex: 1.4 },
        { field: 'errorMessage', headerName: 'Error Message', flex: 2 },
        { field: 'ytGrossSummary', headerName: 'YT Gross (US$)', flex: 1},
        { field: 'channelKpeId', headerName: 'KPE ID', flex: 1},
    ];

    return (
        <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CreatorSummaryCard title={"YT Gross Earnings in Error"} total={erredRecords.reduce((acc, cur) => acc + cur.totalYTGrossAcrossChannels, 0)}
                                loading={false}/>
            <div style={{ height: 'calc(100vh - 425px)', width: '100%', marginTop: '20px' }}>
                <DataGrid sx={{mt: 2}} disableColumnMenu rows={erredRecords} columns={columns} className={"custom-table-container"} components={{
                    NoRowsOverlay: () => <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',bgcolor:'#b2bcfc'}}>
                        <p>No Youtube Errors Found</p>
                    </Box>
                }} />
            </div>
        </Box>
    )
}

export default YouTubeErrors;