import {AdditionalPayment} from "../../model/AdditionalPayment";
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import {SxProps} from "@mui/system/styleFunctionSx";
import {deleteAdditionalPayment} from "../../services/CreatorSummaryService";
import {AxiosResponse} from "axios";

interface AdditionalPaymentProps {
    additionalPayments: AdditionalPayment[];
    sx?: SxProps;
    deleteAdditionalPayment: (id: string) => void;
    locked: boolean;
}

const AdditionalPaymentTable = (props: AdditionalPaymentProps) => {
    const deleteRecord = (id: string) => {
        deleteAdditionalPayment(id)
            .then((res: AxiosResponse<boolean>) => {
                props.deleteAdditionalPayment(id);
            })
    }

    const getPaymentTypeLabel = (key: string | undefined) => {
        if (key !== undefined) {
            switch (key) {
                case '0':
                case 'PAID_FEATURES':
                    return "Paid Features"
                case '1':
                case 'UGC':
                    return "UGC"
                case '2':
                case 'DSA':
                    return "DSA"
                case '3':
                case 'BRANDED':
                    return "Branded"
                case '4':
                case 'OTHER':
                    return "Other"
                case '5':
                case 'SHORTS':
                    return "Shorts"
                case '6':
                case 'PARTNER_PROVIDED':
                    return "Partner Provided"
            }
        }
        return "";
    }

    return <>
                {props.additionalPayments.map((ap) =>
                    <TableRow key={ap.id}>
                        <TableCell/>
                        <TableCell width={'10px'} align='left' sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)', textTransform: 'capitalize'}}>
                            <b>{getPaymentTypeLabel(ap.paymentType)}</b>
                        </TableCell>
                        <TableCell align={'right'}>
                            {ap.comment}
                        </TableCell>
                        <TableCell align={'left'}>
                        </TableCell>
                        <TableCell align='right'>
                            0.00
                        </TableCell>
                        <TableCell align='right' >
                            {ap.amount.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </TableCell>
                        <TableCell align='right' >
                            0.00
                        </TableCell>
                        <TableCell align='right'>
                            <IconButton onClick={() => deleteRecord(ap.id)} disabled={props.locked}>
                                <DeleteForeverOutlinedIcon sx={{color: 'red'}}/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
        </>
}

export default AdditionalPaymentTable;