import {createContext, useState} from 'react';
import dayjs from 'dayjs';

interface reportDate {
    year: number;
    month: number;
    updateYearAndMonth: (year: number, month: number) => void;
    lastUpdateDate?: Date;
    updateLastUpdateDate: () => Date;
}

export const CurrentReportDate = createContext<reportDate>({
    year: new Date().getFullYear(),
    month: 1,
    updateYearAndMonth: (year: number, month: number) => undefined,
    lastUpdateDate: new Date(),
    updateLastUpdateDate: () => new Date()
});

export const useReportDate = () => {
    const today = dayjs();
    const defaultDateFromStorage = localStorage.getItem('selectedDate');
    const defaultDate = defaultDateFromStorage ? dayjs(defaultDateFromStorage) : today.subtract(1, 'month');

    const [year, setYear] = useState(defaultDate.year());
    const [month, setMonth] = useState(defaultDate.month() + 1);
    const updateYearAndMonth = (year: number, month: number) => {
        setYear(year);
        setMonth(month);
    }
    const updateLastUpdateDate = () => {
        console.log(new Date(), 'last update date')
        return new Date();
    }
    return {year, month, updateYearAndMonth, updateLastUpdateDate};
};