import {
    Box,
    Button,
    CircularProgress, IconButton,
    Popper, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs, Typography
} from "@mui/material";
import './Review.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {ReactNode, useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    calculateGamData,
    getGamChecklistByYearAndMonth, processGamData
} from "../services/GamRunService";
import {AxiosResponse} from "axios";
import {GamChecklist} from "../model/GamChecklist";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import {CurrentReportDate} from "../hooks/useReportDate";
import {CurrentMainSnackbar} from "../hooks/useMainSnackbar";
import {GamProcessingResponse} from "../model/GamProcessingResponse";
import DetailView from "../GAM/DetailView/DetailView";
import {useNavigate} from "react-router-dom";
import SummaryView from "../GAM/SummaryView/SummaryView";
import GroupView from "../GAM/GroupView/GroupView";
import dayjs from "dayjs";
import ModificatioLogs from "../GAM/ModificationLogs/ModificationLogs";
var _ = require('lodash')
interface props {
    enableNext: (enabled: boolean) => void;
    newRun: boolean;
}

function a11yProps(index: number) {
    return {
        id: `gam-tab-${index}`,
        'aria-controls': `gam-tabdetails-${index}`,
    };
}

const checkFlagsForIdleState = (checklist: GamChecklist | null): boolean => {
    return (!checklist?.billingReportStatus &&
            !checklist?.channelLineStatus &&
            !checklist?.gamStatus &&
            !checklist?.crmDataStatus &&
            !checklist?.crmContractsStatus &&
            !checklist?.calculationStatus)
}

const checkFlagsForFailState = (checklist: GamChecklist | null): boolean => {
    return (checklist?.billingReportStatus === 3 ||
            checklist?.channelLineStatus === 3 ||
            checklist?.gamStatus === 3 ||
            checklist?.crmDataStatus === 3 ||
            checklist?.crmContractsStatus === 3 ||
            checklist?.calculationStatus === 3)
}

const checkFlagsForSuccessState = (checklist: GamChecklist | null): boolean => {
    return (checklist?.billingReportStatus === 2 &&
            checklist?.channelLineStatus === 2 &&
            checklist?.gamStatus === 2 &&
            checklist?.crmDataStatus === 2 &&
            checklist?.crmContractsStatus === 2 &&
            checklist?.calculationStatus === 2)
}

function formatTimestamp(timestamp: string | number | Date) {
    const date = new Date(timestamp);
    
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    
    const formattedTime = date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    
    return `${formattedDate} at ${formattedTime}`;
}

const Review = (props: props) => {
    const navigate = useNavigate();
    const {year, month, updateLastUpdateDate} = useContext(CurrentReportDate);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [checklist, setChecklist] = useState<GamChecklist | null>(null);
    const [rows, setRows] = useState<ReactNode>();
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [reportId, setReportId] = useState<string | undefined>();
    const [tabValue, setTabValue] = useState(0);
    const [tabComponent, setTabComponent] = useState<ReactNode>();
    const [inProcess, setInProcess] = useState<Boolean>(false);
    const [handleApiDelay, setHandleApiDelay] = useState<Boolean>(false);
    const apiDelayInterval = useRef<string | number | NodeJS.Timeout | undefined>();
    const checkListRef = useRef<GamChecklist | null>(null);
    const handleNext = () => {
        setInProcess(true);
        setChecklist(null);
        setHandleApiDelay(true);
        processGamData(year, month).then(() => {
        updateMainSnackbar(true, "success", "Process running");    
        })
       setTimeout(()=>{
        refresh();
        callIntervalStatus();
       },15000)

       setTimeout(()=>{
        setHandleApiDelay(false);
       },17000)  
    };

    const handleRestartProcess = () => {
        setInProcess(true);
        setChecklist(null);
        setHandleApiDelay(true);
        processGamData(year, month).then(() => {
        updateMainSnackbar(true, "success", "Process running");    
        })
       setTimeout(()=>{
        refresh();
        callIntervalStatus();
       },15000)

       setTimeout(()=>{
        setHandleApiDelay(false);
       },17000)  
    }

 // Assumptions: calculationRunning wont become true unless all other flags are passed with gamOrCRMRunning == false

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getStatusIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        return status ? <CheckCircleOutlineIcon sx={{color: 'green'}}/> : <ErrorOutlineIcon sx={{color: 'red'}}/>;
    }

    const getProcessingIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        if(status) {
            return (<CircularProgress size={20}/>)
        } else {
            return (<CheckCircleOutlineIcon sx={{color: 'green'}}/>)
        }
    }
    let prevYear = 0;
    let prevMonth = 0;

    const intervalId = useRef<string | number | NodeJS.Timeout | undefined>();
    const callIntervalStatus = useCallback(() => {
        clearInterval(intervalId.current);
        intervalId.current = setInterval(() => {
        let intervalMonth = month;
        let intervalYear = year;
            const storedDate = localStorage.getItem('selectedDate');
        if (storedDate) {
            const parsedDate = dayjs(storedDate);
            intervalYear = parsedDate.year();
            intervalMonth = parsedDate.month() + 1;} // +1 because month is 0-indexed
  
            getGamChecklistByYearAndMonth(intervalYear,intervalMonth).then((res: AxiosResponse<GamChecklist>) => {
                if(res.data)
                    {
            setChecklist(res.data);
              setReportId(res.data.reportId);

              if(checkFlagsForIdleState(res.data)){
                clearInterval(intervalId.current);
              } 
              
              else if(checkFlagsForFailState(res.data)){
                updateMainSnackbar(true, "error", "Process failed");
                clearInterval(intervalId.current);
              }

              else if(checkFlagsForSuccessState(res.data)){
                updateMainSnackbar(true, "success", "Process completed");
                clearInterval(intervalId.current);
              }
              prevMonth = month;
               prevYear = year;
                if(res.data.calculationComplete) {
                    updateMainSnackbar(true, "success", "Process completed");
                    clearInterval(intervalId.current);
                    setTabValue(1);
                    setTimeout(() => setTabValue(0), 300);
                }
                    }
                else {
                    setChecklist(null); 
                    setReportId(undefined);}    
            });
        }, 2000)
        return () => {
            clearInterval(intervalId.current);
        };
    }, [year, month]);
    useEffect(() => {
        console.log(intervalId, checklist?.calculationComplete)
        if (!checklist?.calculationComplete) {
            callIntervalStatus()
        }
        if(intervalId.current) {
            clearInterval(intervalId.current)
        }   
    }   , [year, month]);

    useEffect(() => setHandleApiDelay(false), [year, month]);

    const refresh = useCallback(() => {
        setRefreshing(true);
        getGamChecklistByYearAndMonth(year, month)
            .then((res: AxiosResponse<GamChecklist>) => {
                if(res.data)
                    {
                  setChecklist(res.data);
                  if(!res.data.calculationComplete) {
                      callIntervalStatus();
                  }
                  setReportId(res.data.reportId);
                  setTimeout(() => setRefreshing(false), 800);
                                      }
                  else {
                        setChecklist(null); 
                        setReportId(undefined);
                  }
                                  
                              });
    }, [year, month]);

    const calculate = useCallback((year: number, month: number) => {
        calculateGamData(year, month).then((res: AxiosResponse<GamProcessingResponse>) => {
            if (res.data.error) {
                console.log(res.data.errorMessage);
            }
            refresh();
        });
        setTimeout(() => refresh(), 1500);
    },[refresh]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        let component = <DetailView reportId={reportId}/>;
        switch (tabValue) {
            case 0:
                component = <DetailView reportId={reportId}/>;
                break;
            case 1:
                component = <SummaryView reportId={reportId}/>;
                break;
            case 2:
                component = <ModificatioLogs reportId={reportId}/>;
                break;    
            case 3:
                component = <GroupView reportId={reportId}/>;
                break;
        }
        setTabComponent(component);
    }, [tabValue, reportId])

    useEffect(() => {
        setRows(
            <TableRow
                key={`${year}-${month}`}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell component="th" scope="row">
                    {year}
                </TableCell>
                <TableCell component="th" scope="row">
                    {month}
                </TableCell>
                <TableCell align="center">{getStatusIcon(checklist?.billingReportUploaded)}</TableCell>
                <TableCell align="center">{getStatusIcon(checklist?.channelLineUploaded)}</TableCell>
                <TableCell align="center">{getProcessingIcon(checklist?.gamOrCRMRunning)}</TableCell>
                <TableCell align="center">{getStatusIcon(checklist?.gamUploaded)}</TableCell>
                <TableCell align="center">{getStatusIcon(checklist?.crmDataLoaded)}</TableCell>
                <TableCell align="center">{getStatusIcon(checklist?.crmContractsLoaded)}</TableCell>
                {props.newRun ? <></> :
                    <>
                        <TableCell align="center">{getProcessingIcon(checklist?.calculationRunning)}</TableCell>
                        <TableCell align="center">{getStatusIcon(checklist?.calculationComplete)}</TableCell>
                    </>
                }
            </TableRow>
        );
    }, [checklist, year, month, reportId, calculate, props.newRun])
    
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const popperRef = useRef(null); 
    const handleToggle = (toggleState: boolean) => {
      setOpen(toggleState);
    };

    const id = open ? 'simple-popper' : undefined;

    const subset = Object.fromEntries(
    Object.entries(checklist || {})
    .filter(([key]) => ['billingReportUploaded', 'channelLineUploaded','gamOrCRMRunning','crmContractsLoaded','crmDataLoaded','gamUploaded'].includes(key))
    )
   const gamReportCheckSuccess ={
   billingReportUploaded:true,
   channelLineUploaded:true,
   crmContractsLoaded:true,
   crmDataLoaded:true,
   gamOrCRMRunning:false,
   gamUploaded:true,
}
 const ProcessIcon = (isProcessed:boolean)=>{
    return [getStatusIcon(isProcessed),isProcessed?"Processed":"Failed"]
 }
    return (
        <>

    <div style={{display:'flex'}}>

         <div style={{flex:0.6}}>
         
            <Typography variant={'h4'} sx={{pb: 2,mr:2}} className={'h4-custom'}>
                Paid Media Earnings
            </Typography>
            {(checklist == null || checkFlagsForIdleState(checklist)) && !handleApiDelay && (<Button
            ref={anchorRef}
            className={'success-custom'}
            onMouseLeave={()=>handleToggle(false)}
            aria-describedby={id}
            variant="outlined"
            sx={{ gap: '8px', color: 'gray', }}
            onClick={handleNext}
            onMouseEnter={()=>handleToggle(true)}
            style={{ color: 'blue',width: '150px'}}
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.27 4.5L9.57 6H13.5V10.5H10.98L10.68 9H5.25V4.5H9.27ZM10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8L10.5 3Z" fill="#0A2AE7"/>
            </svg>
            START PROCESS
          </Button>)}
            {(handleApiDelay || (checklist != null && checklist.calculationStatus !== 1 && 
            (checklist.billingReportStatus === 1 || 
             checklist.channelLineStatus === 1 || 
             checklist.gamStatus === 1 ||
             checklist.crmDataStatus === 1 ||
             checklist.crmContractsStatus === 1) &&
             !checkFlagsForFailState(checklist))) &&
            (<Button  ref={anchorRef} className={'success-custom'}
            onMouseLeave={()=>handleToggle(false)}  aria-describedby={id}  variant="outlined" 
            sx={{ gap: '8px', color: '#FF7A00',borderColor:'#FF7A00',":hover":{borderColor:'#FF7A00'} }}
            onMouseEnter={()=>handleToggle(true)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
            </svg>
            PROCESSING
            </Button>)}
            {checklist != null && 
            checklist.billingReportStatus === 2 && 
            checklist.channelLineStatus === 2 && 
            checklist.gamStatus === 2 &&
            checklist.crmDataStatus === 2 &&
            checklist.crmContractsStatus === 2 &&
            checklist.calculationStatus === 2 &&
            !handleApiDelay &&
            (<Button  ref={anchorRef} className={'success-custom'}
            onMouseLeave={()=>handleToggle(false)}  aria-describedby={id}  variant="outlined" 
            sx={{ gap: '8px', color: 'green', }}
            color="success"
            onMouseEnter={()=>handleToggle(true)}>
                {ProcessIcon(true)}
            </Button>)}
            {checklist != null && checkFlagsForFailState(checklist) &&  !handleApiDelay && (<Button  ref={anchorRef} className={'success-custom'}
            onMouseLeave={()=>handleToggle(false)}  aria-describedby={id}  variant="outlined" 
            sx={{gap: '8px', color: 'red', }}
            color="error"
            onMouseEnter={()=>handleToggle(true)}>
                {ProcessIcon(false)}
            </Button>)}

            {checklist != null && 
            checklist.billingReportStatus === 2 &&
            checklist.channelLineStatus === 2 &&
            checklist.gamStatus === 2 &&
            checklist.crmDataStatus === 2 &&
            checklist.crmContractsStatus === 2 &&
            checklist.calculationStatus === 1 &&
            (<Button  ref={anchorRef} className={'success-custom'}
            onMouseLeave={()=>handleToggle(false)}  aria-describedby={id}  variant="outlined" 
            sx={{gap: '8px', color: '#FF7A00',borderColor:'#FF7A00' }}
            onMouseEnter={()=>handleToggle(true)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
            </svg>
            CALCULATING
            </Button>)}

            <Typography variant={'caption'} sx={{alignSelf:'center'}} color='gray'>
                 {checklist?.lastUpdatedOn ? `Data updated: ${formatTimestamp(checklist.lastUpdatedOn)}`: ''}
            </Typography>
            </div>

        <div style={{flex:0.4,display:'flex',justifyContent:'end'}}>
            {checklist != null && !handleApiDelay && (checkFlagsForFailState(checklist) || checkFlagsForSuccessState(checklist)) && (<Button
            className={'success-custom'}
            aria-describedby={id}
            variant="outlined"
            sx={{ gap: '8px' }}
            onClick={handleRestartProcess}    
            style={{ color: 'blue',width: '10rem'}}
          >
            Re-start process    
          </Button>)}
          </div>
    </div>
<div>
    <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        disablePortal={true}
        style={{ zIndex: 9999 }}
        modifiers={[
        {
            name: 'flip',
            enabled: true,
            options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
            },
        },
        {
            name: 'preventOverflow',
            enabled: false,
            options: {
            altAxis: false,
            altBoundary: false,
            tether: false,
            rootBoundary: 'document',
            padding: 8,
            },
        }
        ]}
        popperRef={popperRef}
    >
        <Box
            ref={popperRef}
            className="popper-container"
            >
            <div className="arrow" />
            <Typography align="center" display="flex" gap="8px"> 
              {!handleApiDelay && (checklist == null || checklist.billingReportStatus === 0 || checklist.billingReportStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
              {(handleApiDelay || (checklist !== null && checklist.billingReportStatus === 1)) && (getProcessingIcon(true))}
              {!handleApiDelay && checklist !== null && checklist.billingReportStatus === 2 && (getStatusIcon(true))}
              {!handleApiDelay && checklist !== null && checklist.billingReportStatus === 3 && (getStatusIcon(false))}
              Billing Uploaded 
          </Typography>
          <Typography align="center" display="flex" gap="8px" sx={{ mt: 2 }}>
            {(checklist == null || checklist.channelLineStatus === 0 || checklist.channelLineStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
            {checklist !== null && checklist.channelLineStatus === 1 && (getProcessingIcon(true))}
            {checklist !== null && checklist.channelLineStatus === 2 && (getStatusIcon(true))}
            {checklist !== null && checklist.channelLineStatus === 3 && (getStatusIcon(false))}
              Channel uploaded
          </Typography>
        <Typography align="center" display="flex" gap="8px" sx={{ mt: 2 }}>
        {(checklist === null || (!checklist.gamStatus && !checklist.crmDataStatus && !checklist.crmContractsStatus)) && 
        (<RemoveCircleOutlineIcon style={{ color: 'gray' }}/>)}
        {checklist !==null && 
        (checklist.gamStatus !==3 && checklist.crmDataStatus !==3 && checklist.crmContractsStatus !==3) &&
        (checklist.gamStatus ===1 || checklist.crmDataStatus ===1 || checklist.crmContractsStatus ===1)
        && (getProcessingIcon(true))}
        {checklist !== null && checklist.gamStatus===2 && checklist.crmDataStatus===2 && checklist.crmContractsStatus===2 &&  (getStatusIcon(true))}
        {checklist !== null && (checklist.gamStatus===3 || checklist.crmDataStatus===3 || checklist.crmContractsStatus===3) &&  (getStatusIcon(false))}
            CRM / GAM / Contracts
        </Typography>
        <Typography sx = {{ml:4 , mr:4}}>
            <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
            {(checklist == null || checklist.gamStatus === 0 || checklist.gamStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
              {checklist !== null && checklist.gamStatus === 1 && (getProcessingIcon(true))}
              {checklist !== null && checklist.gamStatus === 2 && (getStatusIcon(true))}
              {checklist !== null && checklist.gamStatus === 3 && (getStatusIcon(false))}
                <Typography fontSize="12px" marginTop='4px'>GAM Uploaded</Typography>
            </Typography>
            <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
            {(checklist == null || checklist.crmDataStatus === 0 || checklist.crmDataStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
              {checklist !== null && checklist.crmDataStatus === 1 && (getProcessingIcon(true))}
              {checklist !== null && checklist.crmDataStatus === 2 && (getStatusIcon(true))}
              {checklist !== null && checklist.crmDataStatus === 3 && (getStatusIcon(false))}
                <Typography fontSize="12px" marginTop='4px'>CRM Uploaded</Typography>
            </Typography>
            <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
            {(checklist == null || checklist.crmContractsStatus === 0 || checklist.crmContractsStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
              {checklist !== null && checklist.crmContractsStatus === 1 && (getProcessingIcon(true))}
              {checklist !== null && checklist.crmContractsStatus === 2 && (getStatusIcon(true))}
              {checklist !== null && checklist.crmContractsStatus === 3 && (getStatusIcon(false))}
                <Typography fontSize="12px" marginTop='4px'>Contracts Uploaded</Typography>
            </Typography>   
        </Typography>
        <Typography align="center" display="flex" gap="8px" sx={{ mt: 2 }}>
              {(checklist == null || checklist.calculationStatus === 0 || checklist.calculationStatus === null) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
              {checklist !== null && checklist.calculationStatus === 1 && (getProcessingIcon(true))}
              {checklist !== null && checklist.calculationStatus === 2 && (getStatusIcon(true))}
              {checklist !== null && checklist.calculationStatus === 3 && (getStatusIcon(false))}
        Calculations
        </Typography>
        </Box>
    </Popper>
    </div>

            {!props.newRun ?
                <>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', mt: 2}}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Line Details" {...a11yProps(0)} />
                            <Tab label="Summary" {...a11yProps(1)} />
                            <Tab label="Modification Logs" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    {tabComponent}
                </>
                :
                <></>}
        </>
    )
}
export default Review;  