import {Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {useState} from "react";
import {GamGroupSummary} from "../../model/GamGroupSummary";

interface GroupViewProps {
    reportId: string | undefined;
}
const GroupView = (props: GroupViewProps) => {
    const [groupData, setGroupData] = useState<GamGroupSummary[]>();
    const [groupRows, setGroupRows] = useState();
    return (
        <>
            {props.reportId !== undefined ?
                <TableContainer component={Paper} sx={{minWidth: 650, mb: 4, mx: 'auto'}}>
                    <Table sx={{minWidth: 650}} size="small">
                        <TableHead>
                            <TableRow key={'at'}>
                                {/*{groupRows.advertiserTitle}*/}
                            </TableRow>
                            <TableRow key={'ot'}>
                                {/*{groupRows.orderTitle}*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <></>
            }
            </>
    );
}

export default GroupView;