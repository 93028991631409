import {ReactNode, useContext, useEffect, useRef, useState} from "react";
import './YoutubeSummary.scss'
import {CurrentReportDate} from "../hooks/useReportDate";
import {PaymentSummary} from "../model/PaymentSummary";
import {
    getPaymentSummary, getProgress,
    ingestAllYouTubeData,
    loadAndGetPaymentSummary,
} from "../services/CreatorSummaryService";
import {AxiosResponse} from "axios";
import {Alert, Box, Button, Grid, LinearProgress,CircularProgress, Pagination, Paper, Tab, Tabs, Typography,Popper} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';

import YouTubeErrors from "./YouTubeErrors";
import CreatorSummaryItemWrapper from "./creatorSummary/CreatorSummaryItemWrapper";
import UnassignedAssets from "./Unassigned/UnassignedAssets";
import AssignedAssets from "./Assigned/AssignedAssets";
import { CurrentMainSnackbar } from "../hooks/useMainSnackbar";
import {YTSummary} from '../model/YTSummary'
import { UnassignedAsset } from "../model/UnassignedAsset";
import { AssignedAsset } from "../model/AssignedAsset";
import { loadUnassignedAssets,loadAssignedAssets,getYTChannels,getUniqueCMS } from "../services/YTService";
import { ContentOwnerResponse } from "../model/ContentOwner";
function a11yProps(index: number) {
    return {
        id: `gam-tab-${index}`,
        'aria-controls': `gam-tabdetails-${index}`,
    };
}

function formatTimestamp(timestamp: string | number | Date) {
    const date = new Date(timestamp);
    
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    
    const formattedTime = date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
    
    return `${formattedDate} at ${formattedTime}`;
}

const checkForRestartProcess = (checklist : YTSummary ) =>{
    const states = [
        checklist?.affiliateTaxSummaryState,
        checklist?.assetSummaryState,
        checklist?.ecommerceState,
        checklist?.redRawMusicState,
        checklist?.redRawVideoState,
        checklist?.videoSummaryState,
        checklist?.claimsState,
        checklist?.shortsAdsState,
        checklist?.shortsSubsState,
        ];

        const allStates = (value: number | undefined) => states.every((state) => state === value);
        const anyState = (value: number | undefined) => states.some((state) => state === value);

        switch (true) {
        case allStates(0):
            return false
        case anyState(3):
            return true;
        case anyState(1):
            return false;
        case allStates(2):
            return true
        default:
           return true
        }
}

const getPartialStatus = (flags: YTSummary | null): boolean => {
    const states = [
        flags?.affiliateTaxSummaryState,
        flags?.assetSummaryState,
        flags?.ecommerceState,
        flags?.redRawMusicState,
        flags?.redRawVideoState,
        flags?.videoSummaryState,
        flags?.claimsState,
        flags?.shortsAdsState,
        flags?.shortsSubsState,
        flags?.channelState,
        flags?.crmContractsStatus,
        flags?.calculationsStatus
                  ];

        const allStates = (value: number | undefined) => states.every((state) => state === value);
        const anyState = (value: number | undefined) => states.some((state) => state === value);

        switch (true) {
                case flags === null || allStates(0):
                    return false;
                case anyState(1):
                    return false;
                case allStates(2):
                    return false;
                case anyState(3):
                    return false;
                case flags?.calculationsStatus === 1:
                    return false;
                default:
                   return true;
                }
}


const filterEpRecords = (data :PaymentSummary[])=>{

  const records = data.filter(item => 
    item.channelPaymentSummaries.every(summary => 
      summary.ytEarnings.every(earning => !earning.earningType.startsWith('EP')) // Ensure no earningType starts with 'EP'
    )
  );
    return records;
}

const YouTubeSummary = () => {
    const {year, month} = useContext(CurrentReportDate);
    const [apiResponse, setApiResponse] = useState<PaymentSummary[]>([]);
    const [paymentSummaries, setPaymentSummaries] = useState<PaymentSummary[]>([]);
    const [unassignedAssets, setUnassignedAssets] = useState<UnassignedAsset[]>([]);
    const [assignedAssets, setAssignedAssets] = useState<AssignedAsset[]>([]);
    const [channels, setChannels] = useState<string[]>([]);
    const [uniqueCMSArray, setUniqueCMSArray] = useState<string[]>([]);
    const [checklist, setChecklist] = useState<YTSummary | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [calculationStart, setCalculationStart] = useState('');
    const [progress, setProgress] =  useState(0);
    const [recordsPerPage,] = useState(100);
    const [tabValue, setTabValue] = useState(0);
    const [tabComponent, setTabComponent] = useState<ReactNode>();
    const [loading, setLoading] = useState(true);
    const [YTProgressLoading, setYTProgressLoading] = useState(false);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [intervalRunner,setIntervalRunner] = useState(false);
    const [coverApiDelay,setCoverApiDelay] = useState(false);
    const [loadingStatus,setLoadingStatus] = useState({
        assignedAssets:false,
        unassignedAssets:false,
        ytdetails:false
    });
    const yearRef = useRef(year);
    const monthRef = useRef(month);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const popperRef = useRef(null); 
    const handleToggle = (toggleState: boolean) => {
      setOpen(toggleState);
    };

    const id = open ? 'simple-popper' : undefined;

    const getStatusIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        return status ? <CheckCircleOutlineIcon sx={{color: 'green'}}/> : <ErrorOutlineIcon sx={{color: 'red'}}/>;
    }

    const getProcessingIcon = (status: boolean | undefined): ReactNode => {
        if (status === undefined) {
            return <ErrorOutlineIcon sx={{color: 'red'}}/>;
        }
        if(status) {
            return (<CircularProgress size={20}/>)
        } else {
            return (<CheckCircleOutlineIcon sx={{color: 'green'}}/>)
        }
    }

    const ProcessIcon = (isProcessed:boolean)=>{
        return [getStatusIcon(isProcessed),isProcessed?"Processed":"Failed"]
     }

    // const initialRender = useRef(true);
    const fetchPaymentSummary = () =>{

        getPaymentSummary(year, month, '')
        .then((res: AxiosResponse<PaymentSummary[]>) => {
            if(res.data[0]?.year !== year || res.data[0].month !== month)
            return;
            setApiResponse(res.data);
            for(let item in res.data) {
                res.data[item].id = res.data[item].crmCreator.creatorId;
            }
            // const filteredEpRecords = filterEpRecords(res.data)
            setPaymentSummaries(res.data);
            setCurrentPage(1);
            setLoading(false);
        })
        .catch((err) => console.error(err))
        .finally(()=> setLoadingStatus(prev=>({...prev,ytdetails:false})))
    }
    useEffect(() => {
        setChecklist(null);
        yearRef.current = year;
        monthRef.current = month;
        setCoverApiDelay(false)
    },[month,year])

    useEffect(()=>{
        setLoadingStatus({
            assignedAssets:true,
            unassignedAssets:true,
            ytdetails:true
        });
        loadUnassignedAssets(year, month)
        .then((res: AxiosResponse<UnassignedAsset[]>) => {
            // setRecordsPerPage(res.data.totalElements)
            // setPages(res.data.totalPages); 
            const updatedAssets = res.data.map(asset => ({
                ...asset,
                id: asset.assetId,
            }));
            setUnassignedAssets(updatedAssets);
        }).finally(()=> setLoadingStatus(prev=>({...prev,unassignedAssets:false})))
        
        loadAssignedAssets(year, month)
        .then((res: AxiosResponse<AssignedAsset[]>) => {
              // setRecordsPerPage(res.data.totalElements)
              // setPages(res.data.totalPages);
              const updatedAssets = res.data.map((asset) => {
                  return {
                      ...asset,
                      id: asset.assetId,
                      channelName: asset.channelName ? asset.channelName: "",
                      creatorName: asset.creatorName ? asset.creatorName: ""
                  }
              })
             setAssignedAssets(updatedAssets);
          }).finally(()=> setLoadingStatus(prev=>({...prev,assignedAssets:false})))

        getUniqueCMS().then((res: AxiosResponse<ContentOwnerResponse>) => {    
        const uniqueCMS = res.data.contentOwners.map(contentOwner => contentOwner.name) 
        setUniqueCMSArray(uniqueCMS);           
        });

        getYTChannels(year, month)
            .then((res: AxiosResponse<string[]>) => {
                let uniqueChannels: string[] = [];
                for (const channel of res.data) {
                    let key = Object.keys(channel)[0];
                    let value = Object.values(channel)[0];
                    let label = `${value} (${key})`;
                    if (!uniqueChannels.includes(label)) {
                        uniqueChannels.push(label);
                    }
                }
                setChannels(uniqueChannels);
            })
    },[year,month])

    useEffect(() => {
        // if (initialRender.current) {
        //     initialRender.current = false;
        //     return;
        // }

        setLoading(true);
        setYTProgressLoading(true)
        setPaymentSummaries([])
        const intervalId = setInterval(() => {
            getProgress(year, month).then((res: AxiosResponse<YTSummary>) => {
                let percentComplete = res.data.percentComplete;
                let calculationStart = res.data.ingestionStart;
                setYTProgressLoading(false)
            
             if (res.data && res.data.month === monthRef.current && res.data.year === yearRef.current) {
                    setChecklist(res.data);
                    
                    if(res.data &&
                        res.data.channelState === 0 &&
                        res.data.crmContractsStatus === 0 &&
                        res.data.calculationsStatus === 0 &&
                        res.data.affiliateTaxSummaryState === 0 &&
                        res.data.assetSummaryState === 0 &&
                        res.data.ecommerceState === 0 &&
                        res.data.redRawMusicState === 0 &&
                        res.data.redRawVideoState === 0 &&
                        res.data.videoSummaryState === 0 &&
                        res.data.claimsState === 0 &&
                        res.data.shortsAdsState === 0 &&
                        res.data.shortsSubsState === 0
                    ){
                        clearInterval(intervalId);
                        setLoading(false)
                    }

                    if(res.data.channelState === 2 && 
                    res.data.crmContractsStatus === 2 && 
                    res.data.calculationsStatus === 2 &&
                    res.data.affiliateTaxSummaryState === 2  &&
                    res.data.assetSummaryState === 2 &&
                    res.data.ecommerceState === 2 &&
                    res.data.redRawMusicState === 2 &&
                    res.data.videoSummaryState === 2 &&
                    res.data.redRawVideoState === 2 &&
                    res.data.claimsState === 2 &&
                    res.data.shortsAdsState === 2 &&
                    res.data.shortsSubsState === 2)
                    {
                        updateMainSnackbar(true, "success", "Calculations complete");
                        fetchPaymentSummary()
                        clearInterval(intervalId);
                    }
                    else if(getPartialStatus(res.data)){
                        fetchPaymentSummary();
                        clearInterval(intervalId);
                    }
                    else if (res.data && (res.data.channelState === 3 || 
                            res.data.crmContractsStatus === 3 || 
                            res.data.calculationsStatus === 3 ||
                            res.data.affiliateTaxSummaryState === 3 ||
                            res.data.assetSummaryState === 3 ||
                            res.data.ecommerceState === 3 ||
                            res.data.redRawMusicState === 3 ||
                            res.data.redRawVideoState === 3 ||
                            res.data.videoSummaryState === 3 ||
                            res.data.claimsState === 3 ||
                            res.data.shortsAdsState === 3 ||
                            res.data.shortsSubsState === 3
                            )){
                        updateMainSnackbar(true, "error", "No calculations in progress");
                        setLoading(false)
                        clearInterval(intervalId);
                    }
                    else setLoading(false)

            }
            })
        }, 2000)
        setProgress(0);
        return () => {
            clearInterval(intervalId);
        };
    }, [ year, month, intervalRunner]);

    const loadSummaries = () => {
        setLoading(true);
        loadAndGetPaymentSummary(year, month)
            .then((res: AxiosResponse<PaymentSummary[]>) => {
                setApiResponse(res.data);
                setPaymentSummaries(res.data);
                setCurrentPage(1);
                setLoading(false);
            })
            .catch((err) => console.error(err));
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getProgressContent = (year: number, month: number) => {
        getProgress(year, month).then((res: AxiosResponse<{ percentComplete: string|number, calculationStart: string }>) => {
            let percentComplete = res.data.percentComplete;
            if (typeof percentComplete === "number") {
                if (percentComplete !== 1) {
                    setProgress(percentComplete);
                }
                if (percentComplete === 1) {
                    updateMainSnackbar(true, "success", "Calculations complete");
                    setProgress(0);
                }
            } else {
                updateMainSnackbar(true, "error", "No calculations in progress");
                setProgress(0);
            }
        })
    }

    useEffect(() => {
        let component = <YouTubeErrors/>;
        switch (tabValue) {
            case 0:
                component = <CreatorSummaryItemWrapper
                    paymentSummaries={paymentSummaries}
                    loading={loading}
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    creatorSummary={false}
                    openManualAdjustment={(x: string, y: string) => {}}
                    deleteAdditionalPayment={(x: string) => {}}
                    locked={false}
                />;
                break;
            case 1:
                component = <UnassignedAssets 
                            unassignedAssets={unassignedAssets} 
                            setUnassignedAssets={setUnassignedAssets}
                            assignedAssets={assignedAssets}
                            setAssignedAssets={setAssignedAssets}
                            channels={channels}
                            setChannels={setChannels}
                            uniqueCMSArray={uniqueCMSArray}
                            setUniqueCMSArray={setUniqueCMSArray}
                            loading={loadingStatus.unassignedAssets}
                            />
                break;
            case 2:
                component = <AssignedAssets 
                            assignedAssets={assignedAssets} 
                            setAssignedAssets={setAssignedAssets}
                            unassignedAssets={unassignedAssets} 
                            setUnassignedAssets={setUnassignedAssets}
                            uniqueCMSArray={uniqueCMSArray}
                            setUniqueCMSArray={setUniqueCMSArray}
                            loading={loadingStatus.assignedAssets}
                            />
                break;
            case 3:
                component = <YouTubeErrors/>;
                break;
        }
        setTabComponent(component);
    }, [tabValue, year, month, paymentSummaries, currentPage, loading,assignedAssets,unassignedAssets]);


    return (
        <>
        
            <Box className={'youtube-summary-container'} >
                <Paper elevation={1} sx={{width: '90%',height: 'calc(100vh - 150px)', py: 3, px: 4}}>
                    <div style={{display:'flex',marginBottom:'2rem'}}>

                        <div style={{flex:0.6,flexDirection:'row',marginTop:'5px'}}>

                            <Typography variant={'h4'} sx={{mr:2,display:'inline'}} className={'h4-custom'}>
                                Youtube Earnings
                            </Typography>

                        <div ref={anchorRef} onMouseLeave={()=>handleToggle(false)} onMouseEnter={()=>handleToggle(true)} style={{display:'inline-flex',minWidth:'150px',minHeight:'20px'}} >
                         {!YTProgressLoading && <>
                            {!coverApiDelay && ((checklist &&
                            (checklist.channelState === 0 &&
                            checklist.affiliateTaxSummaryState === 0 &&
                            checklist.assetSummaryState === 0 &&
                            checklist.ecommerceState === 0 &&
                            checklist.redRawMusicState === 0 &&
                            checklist.redRawVideoState === 0 &&
                            checklist.videoSummaryState === 0 &&
                            checklist.claimsState === 0 &&
                            checklist.shortsAdsState === 0 &&
                            checklist.shortsSubsState === 0 &&
                            checklist.crmContractsStatus === 0 &&
                            checklist.calculationsStatus === 0))) && 
                            (<Button
                            className={'success-custom-yt'}
                            aria-describedby={id}
                            variant="outlined"
                            sx={{ gap: '8px', color: 'gray', }}
                            onClick={() => {
                                ingestAllYouTubeData(year, month);
                                setChecklist(null)
                                setCoverApiDelay(true);
                                setTimeout(()=>setIntervalRunner(prev => !prev),15000) 
                                setTimeout(() => setCoverApiDelay(false),16000)
                            }}
                            style={{ color: 'blue',width: '10rem'}}
                            >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.27 4.5L9.57 6H13.5V10.5H10.98L10.68 9H5.25V4.5H9.27ZM10.5 3H3.75V15.75H5.25V10.5H9.45L9.75 12H15V4.5H10.8L10.5 3Z" fill="#0A2AE7"/>
                            </svg>
                            START PROCESS
                            </Button>)}
                            {(() => {
                                const states = [
                                    checklist?.affiliateTaxSummaryState,
                                    checklist?.assetSummaryState,
                                    checklist?.ecommerceState,
                                    checklist?.redRawMusicState,
                                    checklist?.redRawVideoState,
                                    checklist?.videoSummaryState,
                                    checklist?.claimsState,
                                    checklist?.shortsAdsState,
                                    checklist?.shortsSubsState,
                                    checklist?.channelState,
                                    checklist?.crmContractsStatus,
                                    checklist?.calculationsStatus
                                              ];

                                    const allStates = (value: number | undefined) => states.every((state) => state === value);
                                    const anyState = (value: number | undefined) => states.some((state) => state === value);

                                    switch (true) {
                                            case coverApiDelay:
                                                return <></>;
                                            case checklist===null || allStates(0):
                                                return <></>;
                                            case anyState(1):
                                                return <></>;
                                            case allStates(2):
                                                return <></>;
                                            case anyState(3):
                                                return <></>;
                                            case checklist?.calculationsStatus === 1:
                                                return <></>
                                            default:
                                               return <Button  className={'partial-custom-yt'}
                                               aria-describedby={id}  variant="outlined" 
                                               sx={{ gap: '8px', color: 'green', }}
                                               color="success"
                                               >
                                                <FlakyOutlinedIcon sx={{color: 'green'}}/>
                                                Partiallay Processed
                                               </Button>;
                                            }
                                    })()}
                                        
                            {(coverApiDelay || (checklist !== null && 
                            (checklist.channelState === 1 || 
                             checklist.affiliateTaxSummaryState === 1 ||
                             checklist.assetSummaryState === 1 ||
                             checklist.ecommerceState === 1 ||
                             checklist.redRawMusicState === 1 ||
                             checklist.redRawVideoState === 1 ||
                             checklist.videoSummaryState === 1 ||
                             checklist.claimsState === 1 ||
                             checklist.shortsAdsState === 1 ||
                             checklist.shortsSubsState === 1 ||
                             checklist.crmContractsStatus === 1) && 
                            (checklist.channelState !== 3 && 
                             checklist.affiliateTaxSummaryState !== 3 &&
                             checklist.assetSummaryState !== 3 &&
                             checklist.ecommerceState !== 3 &&
                             checklist.redRawMusicState !== 3 &&
                             checklist.redRawVideoState !== 3 &&
                             checklist.videoSummaryState !== 3 &&
                             checklist.claimsState !== 3 &&
                             checklist.shortsAdsState !== 3 &&
                             checklist.shortsSubsState !== 3 &&
                             checklist.crmContractsStatus !== 3 && 
                             checklist.calculationsStatus !==3) &&
                             checklist.calculationsStatus !== 1)) && 
                            (<Button className={'success-custom-yt'}
                             aria-describedby={id}  variant="outlined" 
                            sx={{ gap: '8px', color: '#FF7A00',borderColor:'#FF7A00',my:1 ,":hover":{borderColor:'#FF7A00'}}}
                            >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
                            </svg>
                            PROCESSING
                            </Button>)}
                            {checklist != null &&
                            !coverApiDelay &&
                            checklist.channelState === 2 &&
                            checklist.affiliateTaxSummaryState === 2 &&
                            checklist.assetSummaryState === 2 &&
                            checklist.ecommerceState === 2 &&
                            checklist.redRawMusicState === 2 &&
                            checklist.redRawVideoState === 2 &&
                            checklist.videoSummaryState === 2 &&
                            checklist.claimsState === 2 &&
                            checklist.shortsAdsState === 2 &&
                            checklist.shortsSubsState === 2 &&
                            checklist.crmContractsStatus === 2 &&
                            checklist.calculationsStatus === 2 &&
                            (<Button className={'success-custom-yt'}
                             aria-describedby={id}  variant="outlined" 
                            sx={{ gap: '8px', color: 'green', }}
                            color="success"
                            >
                                {ProcessIcon(true)}
                            </Button>)}
                            {checklist != null && 
                            !coverApiDelay &&
                            (checklist.channelState === 3 ||
                            checklist.affiliateTaxSummaryState === 3 ||
                            checklist.assetSummaryState === 3 ||
                            checklist.ecommerceState === 3 ||
                            checklist.redRawMusicState === 3 ||
                            checklist.redRawVideoState === 3 ||
                            checklist.videoSummaryState === 3 ||
                            checklist.claimsState === 3 ||
                            checklist.shortsAdsState === 3 ||
                            checklist.shortsSubsState === 3 ||
                            checklist.crmContractsStatus === 3 ||
                            checklist.calculationsStatus === 3) && 
                            (<Button  className={'success-custom-yt'}
                                 aria-describedby={id}  variant="outlined" 
                            sx={{gap: '8px', color: 'red', }}
                            color="error"
                            >
                                {ProcessIcon(false)}
                            </Button>)}

                            {checklist != null && 
                            checklist.channelState !== 3 &&
                            checklist.affiliateTaxSummaryState !== 3 &&
                            checklist.assetSummaryState !== 3 &&
                            checklist.ecommerceState !== 3 &&
                            checklist.redRawMusicState !== 3 &&
                            checklist.redRawVideoState !== 3 &&
                            checklist.videoSummaryState !== 3 &&
                            checklist.claimsState !== 3 &&
                            checklist.shortsAdsState !== 3 &&
                            checklist.shortsSubsState !== 3 &&
                            checklist.crmContractsStatus !== 3 &&
                            checklist.calculationsStatus === 1 &&     
                            (<Button  className={'success-custom-yt'}
                                 aria-describedby={id}  variant="outlined" 
                            sx={{gap: '8px', color: '#FF7A00',borderColor:'#FF7A00',my:1 ,":hover":{borderColor:'#FF7A00'}}}
                                >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.1175 4.10999C10.9425 4.10999 11.6175 3.43499 11.6175 2.60999C11.6175 1.78499 10.9425 1.10999 10.1175 1.10999C9.29248 1.10999 8.61748 1.78499 8.61748 2.60999C8.61748 3.43499 9.29248 4.10999 10.1175 4.10999ZM7.41748 14.535L8.16748 11.235L9.74248 12.735V17.235H11.2425V11.61L9.66748 10.11L10.1175 7.85999C11.0925 8.98499 12.5925 9.73499 14.2425 9.73499V8.23499C12.8175 8.23499 11.6175 7.48499 11.0175 6.43499L10.2675 5.23499C9.96748 4.78499 9.51748 4.48499 8.99248 4.48499C8.76748 4.48499 8.61748 4.55999 8.39248 4.55999L4.49248 6.20999V9.73499H5.99248V7.18499L7.34248 6.65999L6.14248 12.735L2.46748 11.985L2.16748 13.485L7.41748 14.535Z" fill="#FF7A00"/>
                            </svg>
                            CALCULATING
                            </Button>)}

                          </>
                         }
                         </div>
                            <Typography variant={'caption'} sx={{alignSelf:'center'}} color='gray'>
                                    {checklist?.calculationEnd ? `Data updated: ${formatTimestamp(checklist.calculationEnd)}`: ''}
                            </Typography>
                        </div>

                        {!YTProgressLoading && <div style={{flex:0.4,display:'flex',justifyContent:'end',margin:'5px'}}>
                            {checklist && 
                            !coverApiDelay &&
                            ((checklist.channelState === 3 ||
                            checklist.crmContractsStatus === 3 ||
                            checklist.calculationsStatus === 3) ||
                            (checkForRestartProcess(checklist) &&                            
                            (checklist.crmContractsStatus === 2 || checklist.crmContractsStatus === 0) &&
                            (checklist.calculationsStatus === 2 || checklist.calculationsStatus === 0)  &&
                            (checklist.channelState === 2 || checklist.channelState === 0))) &&
                            (<Button
                            className={'success-custom-yt'}
                            aria-describedby={id}
                            variant="outlined"
                            sx={{ gap: '8px' }}
                            onClick={() => {
                                ingestAllYouTubeData(year, month);
                                setChecklist(null)
                                setCoverApiDelay(true);
                                setTimeout(()=>setIntervalRunner(prev => !prev),15000)
                                setTimeout(() => setCoverApiDelay(false),16000) 
                            }}
                            style={{ color: 'blue',width: '11rem',height:'2rem'}}
                            >
                            Re-start process    
                            </Button>)}
                        </div>}
                    </div>
                         
                    {(checklist || coverApiDelay) && <div>
                        <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorRef.current}
                            placement="bottom"
                            disablePortal={true}
                            style={{ zIndex: 9999 }}
                            modifiers={[
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                altBoundary: true,
                                rootBoundary: 'document',
                                padding: 8,
                                },
                            },
                            {
                                name: 'preventOverflow',
                                enabled: false,
                                options: {
                                altAxis: false,
                                altBoundary: false,
                                tether: false,
                                rootBoundary: 'document',
                                padding: 8,
                                },
                            }
                            ]}
                            popperRef={popperRef}
                        >
                                <Box
                                    ref={popperRef}
                                    className="popper-container"
                                    >
                                    <div className="arrow" />
                                    <Typography align="center" display="flex" gap="8px" my="7px">
                                        {(() => {
                                            const states = [
                                            checklist?.affiliateTaxSummaryState,
                                            checklist?.assetSummaryState,
                                            checklist?.ecommerceState,
                                            checklist?.redRawMusicState,
                                            checklist?.redRawVideoState,
                                            checklist?.videoSummaryState,
                                            checklist?.claimsState,
                                            checklist?.shortsAdsState,
                                            checklist?.shortsSubsState,
                                            ];

                                            const allStates = (value: number | undefined) => states.every((state) => state === value);
                                            const anyState = (value: number | undefined) => states.some((state) => state === value);

                                            switch (true) {
                                            case coverApiDelay:
                                                return getProcessingIcon(true);
                                            case  allStates(0):
                                                return <RemoveCircleOutlineIcon style={{ color: 'gray' }} />;
                                            case anyState(1):
                                                return getProcessingIcon(true);
                                            case allStates(2):
                                                return getStatusIcon(true);
                                            case anyState(3):
                                                return getStatusIcon(false);
                                            default:
                                               return <FlakyOutlinedIcon style={{ color: 'green' }} />;
                                            }
                                        })()}
                                        EDF
                                        </Typography>
                                        <Typography sx = {{ml:4 , mr:4}}>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.affiliateTaxSummaryState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.affiliateTaxSummaryState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.affiliateTaxSummaryState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.affiliateTaxSummaryState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.affiliateTaxSummaryState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Affiliate Tax Summary</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.ecommerceState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.ecommerceState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.ecommerceState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.ecommerceState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.ecommerceState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Ecommerce Paid Features</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.assetSummaryState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.assetSummaryState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.assetSummaryState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.assetSummaryState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.assetSummaryState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Asset Summary</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.videoSummaryState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.videoSummaryState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.videoSummaryState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.videoSummaryState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.videoSummaryState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Video Summary</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.shortsAdsState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.shortsAdsState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.shortsAdsState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.shortsAdsState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.shortsAdsState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Short Ads Video Revenue </Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.shortsSubsState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.shortsSubsState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.shortsSubsState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.shortsSubsState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.shortsSubsState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Short Subs Video Revenue</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.redRawMusicState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.redRawMusicState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.redRawMusicState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.redRawMusicState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.redRawMusicState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Red Music Summary</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.redRawVideoState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.redRawVideoState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.redRawVideoState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.redRawVideoState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.redRawVideoState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Red Summary Video</Typography>
                                        </Typography>
                                        <Typography align="center" display="flex" gap="8px" fontSize="12px" sx={{ mt: 2 }}>
                                        {(coverApiDelay || (checklist && checklist.claimsState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                        {!coverApiDelay && checklist && checklist.claimsState === 1 && (getProcessingIcon(true))}
                                        {!coverApiDelay && checklist && checklist.claimsState === 2 && (getStatusIcon(true))}
                                        {!coverApiDelay && checklist && checklist.claimsState === 3 && (getStatusIcon(false))}
                                        {!coverApiDelay && checklist && checklist.claimsState === 4 && (<FlakyOutlinedIcon style={{ color: 'green' }} />)}
                                            <Typography fontSize="12px" marginTop='4px'>Claim Summary</Typography>
                                        </Typography>
                                    </Typography>
                                <Typography align="center" display="flex" gap="8px" my="7px"> 
                                    {(coverApiDelay || (checklist && checklist.channelState === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                    {!coverApiDelay && checklist && checklist.channelState === 1 && (getProcessingIcon(true))}
                                    {!coverApiDelay && checklist && checklist.channelState === 2 && (getStatusIcon(true))}
                                    {!coverApiDelay && checklist && checklist.channelState === 3 && (getStatusIcon(false))}
                                    Channels 
                                </Typography>


                                <Typography align="center" display="flex" gap="8px" my="7px"> 
                                    {(coverApiDelay || (checklist && checklist.crmContractsStatus === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                    {!coverApiDelay && checklist && checklist.crmContractsStatus === 1 && (getProcessingIcon(true))}
                                    {!coverApiDelay && checklist && checklist.crmContractsStatus === 2 && (getStatusIcon(true))}
                                    {!coverApiDelay && checklist && checklist.crmContractsStatus === 3 && (getStatusIcon(false))}
                                    CRM / Contracts
                                </Typography>

                                <Typography align="center" display="flex" gap="8px" my="7px"> 
                                    {(coverApiDelay || (checklist && checklist.calculationsStatus === 0)) && (<RemoveCircleOutlineIcon style={{ color: 'gray' }} />)}
                                    {!coverApiDelay && checklist && checklist.calculationsStatus === 1 && (getProcessingIcon(true))}
                                    {!coverApiDelay && checklist && checklist.calculationsStatus === 2 && (getStatusIcon(true))}
                                    {!coverApiDelay && checklist && checklist.calculationsStatus === 3 && (getStatusIcon(false))}
                                    Calculations 
                                </Typography>

                                </Box>
                        </Popper>
                    </div>}
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Youtube Tabs for Error and Details">
            
                            <Tab label="Youtube Details" {...a11yProps(0)} 
                            sx={{display:'flex',flexDirection:'row'}} 
                            icon={loading? <CircularProgress size="18px" sx={{mr:1}} />:<></>} 
                            />
                            <Tab label="Unassigned Assets" {...a11yProps(1)}
                            sx={{display:'flex',flexDirection:'row'}} 
                            icon={loadingStatus.unassignedAssets ? <CircularProgress size="18px" sx={{mr:1}} />:<></>}  
                            />
                            <Tab label="Manually Assigned Assets" {...a11yProps(2)} 
                            sx={{display:'flex',flexDirection:'row'}} 
                            icon={loadingStatus.assignedAssets ? <CircularProgress size="18px" sx={{mr:1}} />:<></>} 
                            />
                            <Tab label="Youtube Errors" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    {tabComponent}
                </Paper>
            </Box>
        </>
    );
}
export default YouTubeSummary;
