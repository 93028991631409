import {
    Avatar,
    Box, Button, Grid, IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {User} from "../model/User";
import {addUser, getAllusers, softDeleteUser, updateUser} from "../services/UserService";
import {AxiosResponse} from "axios";
import {CurrentUserInfo} from "../hooks/useUserInfo";
import UserModal from "./UserModal";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {getProgress, ingestAllYouTubeData} from "../services/CreatorSummaryService";

const Admin = () => {
    const {user} = useContext(CurrentUserInfo);
    const [users, setUsers] = useState<User[]>([]);
    const [rows, setRows] = useState<ReactNode[]>([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        setRows(users.map((row, id) => (
            <TableRow key={row.email}>
                <TableCell sx={{display: 'flex', alignItems: 'center'}}><Avatar alt={row.name} src={row.imageUrl} sx={{mr: 2}}></Avatar>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell><Switch checked={row.role === 'ADMIN'} onChange={(event) => updateUserRole(event, id)}/></TableCell>
                <TableCell><Switch checked={row.active} onChange={(event) => toggleActive(event, row)}/></TableCell>
            </TableRow>
        )))
    },[users]);

    const toggleActive = (event: React.ChangeEvent<HTMLInputElement>, deletionUser: User) => {
        if (deletionUser.email !== user?.email) {
            deletionUser.active = event.target.checked;
            softDeleteUser(deletionUser)
                .then(() => refresh());
            } else {
                alert("you can't deactivate yourself");
            }
    }

    const updateUserRole = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const subUsers = users.map((user) => user);
        subUsers[id].role = event.target.checked ? 'ADMIN' : 'USER';
        setUsers(subUsers);
        updateUser(subUsers[id]).then(() => refresh());
    }

    const refresh = () => {
        getAllusers().then((res: AxiosResponse<User[]>) => {
            setUsers(res.data);
        });
    }

    const handleClose = (email: string | undefined) => {
        if (email !== undefined) {
            addUser({
                email: email.toLowerCase(),
                role: 'USER',
                name: '',
                imageUrl: '',
                active: true,
                provider: 'google'
            }).then(() => refresh());
        }
        setOpen(false);
    }


    const getNameField = (params: GridRenderCellParams<string>) => {
        return (<><Avatar alt={params.row.name} src={params.row.imageUrl} sx={{mr: 2}}></Avatar>{params.row.name}</>);
    }
    const getRoleField = (params: GridRenderCellParams<string>) => {
        return (<><Switch checked={params.row.role === 'ADMIN'} onChange={(event) => updateUserRole(event, params.row.id)}/></>);
    }
    const getActiveField = (params: GridRenderCellParams<string>) => {
        return (<><Switch checked={params.row.active} onChange={(event) => toggleActive(event, params.row)}/></>);
    }

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1.25, renderCell: getNameField},
        { field: 'email', headerName: 'Email', flex: 1.25},
        { field: 'role', headerName: 'Admin', flex: 0.25, renderCell: getRoleField},
        { field: 'active', headerName: 'Active', flex: 0.25, renderCell: getActiveField},
    ];

    return (
        <Box className={'youtube-summary-container'}>
            <Paper elevation={1} sx={{width: '90%', py: 3, px: 4}}>
                <Grid container sx={{borderBottom: "1px solid rgb(224, 224, 224)"}}>
                    <Grid item xs={3}>
                        <Typography variant={'h4'} sx={{pb: 2}} className={'h4-custom'}>
                            Admin
                        </Typography>
                    </Grid>
                    <Grid item xs={9} justifyContent="flex-end" textAlign="right">
                        <Button variant={'contained'} onClick={() => setOpen(true)}>Add User</Button>
                    </Grid>
                </Grid>
                <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{ height: 500, width: '100%', marginTop: '24px' }}>
                        <DataGrid sx={{mt: 2}} disableColumnMenu rows={users} columns={columns} className={"custom-table-container"} />
                    </div>
                    <UserModal open={open} handleClose={handleClose} />
                </Box>
            </Paper>
        </Box>
    )
}

export default Admin;
