import {
    AccordionDetails, Avatar,
    Box,
    Button,
    Paper, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {PaymentSummary} from "../model/PaymentSummary";
import {ChannelPaymentSummary} from "../model/ChannelPaymentSummary";
import {useState} from "react";
import YTSummaryTable from "../YoutubeSummary/YTSummaryTable/YTSummaryTable";
import {YTEarningGroupEnum} from "../model/YTEarning";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AdditionalPaymentTable from "./AdditionalPaymentTable";
import * as React from "react";

interface CSIProps {
    paymentSummary: PaymentSummary;
    openManualAdjustment: (kpeId: string, creatorId: string, creatorName: string) => void;
    deleteAdditionalPayment: (id: string) => void;
    isCreatorReview: boolean;
    locked: boolean;
    searchedTerm: string;
}

const formatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits:2,
    maximumFractionDigits:2
});

const CreatorSummaryItemDetail = (props: CSIProps) => {
    const {paymentSummary, openManualAdjustment} = props;
    const [showYTDetails, setShowYTDetails] = useState(-1);

    const updateShowYTDetails = (id: number) => {
        if (showYTDetails === id) {
            setShowYTDetails(-1);
        } else {
            setShowYTDetails(id);
        }
    }

    const highlightText = (text: string, highlight: string) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) => 
            part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part
        );
    }

    return <>
        {paymentSummary.channelPaymentSummaries.map((ch: ChannelPaymentSummary, id: number) => (
            <>
                <TableRow key={ch.channelName+id}>
                    <TableCell width={'16px'}></TableCell>
                    <TableCell width={'calc(46% - 8px)'} sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}} colSpan={2}>
                        <Avatar sx={{ width: 32, height: 32, display: 'inline-block', verticalAlign: 'middle' }} alt={ch?.channelName} src={ch?.channelName}/>
                        <Box sx={{display: 'inline-block', marginLeft: 1, verticalAlign: 'middle'}}>
                            <p style={{margin:'0', lineHeight:'1.2em'}}>
                                <b>{highlightText(ch.channelName, props.searchedTerm)}</b>
                                {ch.error ? 
                                <Tooltip title={ch.errorMessage}>
                                    <ErrorOutlineIcon sx={{color: 'red', verticalAlign: 'middle', ml: 1}}/>
                                </Tooltip> : <> </>}
                            </p>    
                            <span>
                             {highlightText(ch.channelKpeId, props.searchedTerm)}
                            </span>
                        </Box>
                    </TableCell>    
                    <TableCell width={'20%'} align={"right"}>
                        <b>{formatter.format(ch.ytGrossSummary)}</b>
                    </TableCell>
                    <TableCell width={'19%'} align={"right"}>
                        <b>
                            { paymentSummary.tmAdditionalPayments ? 
                                !props.isCreatorReview ? formatter.format(ch.ytNetSummary):
                                    (
                                        ch.ytNetSummary + 
                                        paymentSummary.tmAdditionalPayments
                                            .filter((ap) => ap.channelKpeId === ch.channelKpeId)
                                            .reduce((acc, cur) => acc + cur.amount, 0))
                                            .toLocaleString(undefined, {
                                                currency: 'USD',
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })
                                : <></>
                            }
                        </b>
                    </TableCell>
                    <TableCell width={'15%'} colSpan={2} align={"right"}>
                        {props.isCreatorReview ? <Box sx={{width: 1}}>
                            <Button
                                sx={{float: 'right'}}
                                variant={'text'}
                                onClick={() => {
                                    // @ts-ignore
                                    openManualAdjustment(ch.channelKpeId, paymentSummary.talentManagerId, paymentSummary.groupName);
                                }}
                                disabled={props.locked}
                            >
                                {`Manual Adjustment`}
                            </Button>
                        </Box> : <></>}
                    </TableCell>
                </TableRow>
                <TableRow key={ch.channelKpeId+id}>
                    <TableCell width={'16px'}></TableCell>
                    <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}></TableCell>
                    <TableCell></TableCell>
                    <TableCell align={"right"}>TM earnings (US$)</TableCell>
                    <TableCell align={"right"}>
                        Gross earnings (US$)
                    </TableCell>
                    <TableCell align={"right"}>
                        Net earnings (US$)
                    </TableCell>
                    <TableCell align={"right"}>
                        Rev share (%)
                    </TableCell>
                </TableRow>
                {props.isCreatorReview ?
                    <>
                        <TableRow key={ch.channelKpeId+"PM"}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}><b>Paid Media</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align='right'>{ch.gamGrossSummary.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align='right'>{ch.gamNetSummary.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow key={ch.channelKpeId+"YT"}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}><b>Youtube</b></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align='right'>{ch.ytGrossSummary.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                            <TableCell align='right'>{ch.ytNetSummary.toLocaleString(undefined, {
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</TableCell>
                        </TableRow>
                    </>
                    : <></>}
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.AD}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.AD)}
                    sx={{pt: 2}} 
                    key={ch.channelKpeId+"AD"}
                    view={'tm'}
                />
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.SUBSCRIPTION}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.SUBSCRIPTION)}
                    sx={{pt: 2}}
                    key={ch.channelKpeId+"SB"}
                    view={'tm'}
                />
                <YTSummaryTable
                    isCreatorReview={props.isCreatorReview}
                    earningGroup={YTEarningGroupEnum.PAID_FEATURE}
                    earnings={ch.ytEarnings.filter((earning) => earning.earningGroup === YTEarningGroupEnum.PAID_FEATURE)}
                    sx={{pt: 2}}
                    key={ch.channelKpeId+"PF"}
                    view={'tm'}
                />
                {
                // @ts-ignore
                paymentSummary.tmAdditionalPayments.filter((ap) => ap.channelKpeId === ch.channelKpeId).length > 0 ?
                    <AdditionalPaymentTable
                        sx={{mt: 2}}
                        // @ts-ignore
                        additionalPayments={paymentSummary.tmAdditionalPayments.filter((ap) => ap.channelKpeId === ch.channelKpeId)}
                        deleteAdditionalPayment={props.deleteAdditionalPayment}
                        locked={props.locked}
                    />
                    :
                    <></>
                }
                <TableRow className={'last-child'}>
                    <TableCell/><TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}} colSpan={5}/>
                </TableRow>
            </>))}
    </>
}
export default CreatorSummaryItemDetail;