import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import './NavDrawer.scss';
import Toolbar from '@mui/material/Toolbar';
import ChevronRight from "@mui/icons-material/ChevronRight";
import {ReactNode, useEffect} from "react";
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import InsightsIcon from '@mui/icons-material/Insights';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import {useNavigate} from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import {ChevronLeft} from "@mui/icons-material";

interface NavDrawerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}
interface navItem {
    name: string;
    icon: ReactNode;
    navUrl: string;
}

const navItems: navItem[] = [
    {
        name: 'Paid Media Earnings',
        icon: <PaidOutlinedIcon />,
        navUrl: '/gam/view',
    },
    {
        name: 'Youtube Earnings',
        icon: <PaymentsOutlinedIcon />,
        navUrl: '/youtube/view',
    },
    {
        name: 'Creator Summary',
        icon: <InsertChartOutlinedIcon />,
        navUrl: '/creator/view',
    },
    {
        name: 'Talent Manager Summary',
        icon: <WaterfallChartIcon />,
        navUrl: '/talent-manager/view',
    },
    {
        name: 'Group Summary',
        icon: <InsightsIcon />,
        navUrl: '/group/view',
    },
    {
        name: 'Payments Monitor',
        icon: <DisplaySettingsIcon />,
        navUrl: '/monitor/view',
    },
]
const NavDrawer = (props: NavDrawerProps) => {
    const theme = useTheme();

    const navigate = useNavigate();
    const handleDrawerClose = () => {
        props.setOpen(!props.open);
    };

    const navigateAndClose = (route: string) => {
        handleDrawerClose();
        navigate(route);
    }

    useEffect(() => {
        props.setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])

    

    return (
        <Box sx={{position: "relative", width: 'auto'}} className={"custom-nav-drawer"}>
            <Box
                sx={{
                    width: 264,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 240,
                    },
                    position: "absolute",
                    height: '100vh',
                    background: '#fff',
                    transform: props.open ? "translate(-240px,0)": "",
                    transition: "all ease .6s",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <Toolbar />
                <p>
                    {props.open}</p>
                <IconButton
                    onClick={handleDrawerClose}
                    size="small"
                    sx={{
                        right: "-12px",
                        width: "24px",
                        height: "24px",
                        color: "#000",
                        position: "absolute",
                        top: "120px",
                        zIndex: "2",
                        background: "#fff",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        boxShadow: "0px 1px 2px -1px rgba(0, 0, 0, 0.2)",
                        borderRadius: "48px",
                        "&:hover":{
                            background: "#fff"
                        }
                    }}>
                    {props.open ? <ChevronRight/>:<ChevronLeft/>}
                </IconButton>
                <List>
                    {navItems.map((item) => (
                        <ListItem key={item.name} disablePadding onClick={() => {
                            navigateAndClose(item.navUrl);
                        }} className={window.location.pathname === item.navUrl ? 'active':''}>
                            <ListItemButton sx={{pr: "24px"}}>
                                <ListItemIcon sx={{pr: "18px", width: "22px", minWidth: "0"}}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    )
}

export default NavDrawer;