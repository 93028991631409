import React, { useState, ChangeEvent, useEffect } from 'react';
import {GamCalculation} from "../../model/GamCalculation";
import { Dialog, TextField, Button, DialogActions, Box, DialogContent, DialogTitle, Typography } from '@mui/material';

interface EditRowModalProps {
  open: boolean;
  rowData: GamCalculation;
  locked: boolean;
  onSave: (editedRow: GamCalculation) => void;
  onCancel: () => void;
}

const EditRowModal: React.FC<EditRowModalProps> = ({ open, rowData, locked, onSave, onCancel }) => {
  const [editedRow, setEditedRow] = useState<GamCalculation>(rowData);
  const [validForm, setValidForm] = React.useState(false);
  const [validPercentage, setValidPercentage] = React.useState(true);
  const [validPercentageOverage, setValidPercentageOverage] = React.useState(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedRow((prevRow) => ({
      ...prevRow,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(editedRow);
  };

  useEffect(() => {
    setEditedRow(rowData);
  }, [rowData])


  useEffect(() => {
    const revShare = (editedRow?.talentRevShare * 100) + (editedRow?.groupRevShare * 100) + (editedRow?.bpRevShare * 100) === 100;
    const overage = (editedRow?.groupOverageRevShare * 100) + (editedRow?.bpOverageRevShare * 100) === 100 ;
    setValidPercentage(revShare ? true: false);
    setValidPercentageOverage(overage ? true: false);
    setValidForm(
        (editedRow?.talentRevShare == 0 || !!editedRow?.talentRevShare) && 
        (editedRow?.groupRevShare == 0 || !!editedRow?.groupRevShare) && 
        (editedRow?.bpRevShare == 0 || !!editedRow?.bpRevShare) && 
        (editedRow?.groupOverageRevShare == 0 || !!editedRow?.groupOverageRevShare) && 
        (editedRow?.bpOverageRevShare == 0 || !!editedRow?.bpOverageRevShare) &&
        (editedRow?.bpPaidMediaTalentOverage == 0 || !!editedRow?.bpPaidMediaTalentOverage) &&
        (editedRow?.wholeSaleRateCard == 0 || !!editedRow?.wholeSaleRateCard) && 
        (editedRow?.impressions == 0 || !!editedRow?.impressions) && 
        (editedRow?.rate == 0 || !!editedRow?.rate) && 
        (editedRow?.cpm == 0 || !!editedRow?.cpm) &&
        !!revShare &&
        !!overage ? true: false)
}, [
    editedRow, open, 
    editedRow?.talentRevShare, 
    editedRow?.groupRevShare, 
    editedRow?.bpRevShare, 
    editedRow?.bpPaidMediaTalentOverage,
    editedRow?.groupOverageRevShare,
    editedRow?.bpOverageRevShare,
    editedRow?.wholeSaleRateCard,
    editedRow?.cpm, 
    editedRow?.rate, 
    editedRow?.impressions]);

  return (
    <Dialog open={open} style={{maxWidth: '504px', margin:'0 auto'}}>
        <DialogTitle>
            <Box sx={{display: 'inline-block', marginLeft: 0, verticalAlign: 'middle'}}>
                <p style={{margin:'0', lineHeight:'1.2em', fontSize: '20px'}}><b>Update Values </b></p>
            </Box>
        </DialogTitle>
        {locked ? <>
            <DialogContent>
                <p style={{wordBreak: 'break-all'}}>The campaign <b>{editedRow.orderTitle}</b> is locked and the row can not be edited.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} sx={{width: '100px'}}>Close</Button>
            </DialogActions>
        </>:<>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Talent Rev Share"
                    name="talentRevShare"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.talentRevShare}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Group Rev Share"
                    name="groupRevShare"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.groupRevShare}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="BP Rev Share"
                    name="bpRevShare"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.bpRevShare}
                    onChange={handleChange}
                />
                {!validPercentage ? <>
                <Typography style={{display: "block", marginBottom: "16px"}} color="error" variant="caption">
                    Talent Rev Share + Group Rev Share + BP Rev Share have to add up to 1
                </Typography></>: <></>}
                <TextField
                    margin="dense"
                    label="Group Overage"
                    name="groupOverageRevShare"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.groupOverageRevShare}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="BP Overage"
                    name="bpOverageRevShare"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.bpOverageRevShare}
                    onChange={handleChange}
                />
                {!validPercentageOverage ? <>
                <Typography style={{display: "block", marginBottom: "16px"}} color="error" variant="caption">
                    Group Overage + BP Overage have to add up to 1
                </Typography></>: <></>}
                <TextField
                    margin="dense"
                    label="Talent Overage"
                    name="bpPaidMediaTalentOverage"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.bpPaidMediaTalentOverage}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Wholesale rate"
                    name="wholeSaleRateCard"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.wholeSaleRateCard}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Impressions"
                    name="impressions"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.impressions}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Rate"
                    name="rate"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.rate}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    label="Service Fee"
                    name="cpm"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={editedRow?.cpm}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} sx={{width: '100px'}}>Cancel</Button>
                <Button onClick={handleSave} disabled={!validForm} variant="contained" sx={{width: '80px'}}>Update</Button>
            </DialogActions>
        </>}
    </Dialog>
  );
};

export default EditRowModal;