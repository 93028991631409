import * as React from 'react';
import {useState} from 'react'
import {
    Avatar,
    Button,
    Box,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    Typography
} from "@mui/material";
import {useContext} from "react";
import {CurrentUserInfo} from "../../hooks/useUserInfo";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import {useNavigate} from "react-router-dom";
interface AvatarMenuProps {
    sx?: any
}
const AvatarMenu = (props: AvatarMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const {user, logout} = useContext(CurrentUserInfo);
    const [isUser, setIsUser] = useState(() => {
        const userInfo = localStorage.getItem("userInfo");
        if(!userInfo || userInfo === "undefined" || userInfo === null){
            logout();
            return {}
        }
        return JSON.parse(userInfo);
      });
   
    return (
        <Box sx={{display: "flex", pl: 5, pr: 1, height: 80, alignItems: "center", ...props.sx}}>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }} alt={isUser?.name} src={isUser?.imageUrl}/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: "200px",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <RemoveRedEyeOutlinedIcon />
                    </ListItemIcon>
                    <Typography variant={"body2"}>{isUser?.name}
                        <Typography variant={"caption"} sx={{display:"block"}}>{isUser?.email}</Typography>
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {navigate('/admin')}}>
                    <ListItemIcon>
                        <AssignmentIndOutlinedIcon />
                    </ListItemIcon>
                    Admin
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ArrowBackOutlined fontSize="small"  sx={{ marginLeft: '-30px', paddingRight: '11px'}}></ArrowBackOutlined> Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AvatarMenu;