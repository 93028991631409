import { useContext, useEffect } from "react";
import { CurrentUserInfo } from "../hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import { Paper, Container, Typography, Box } from "@mui/material";
import GoogleAuth from "./GoogleAuth";
import LoginFooter from "./LoginFooter";
import { useTheme } from '@mui/material/styles';

const Login = () => {
    const theme = useTheme();
    const colors = theme.palette;

    const { authenticated, logout } = useContext(CurrentUserInfo);
    const nav = useNavigate();
    useEffect(() => {
        if (authenticated) {
            nav("/youtube/view");
        } else {
            logout();
        }
    }, [authenticated]);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh)",
            background: colors.background.default
        }}>
            <Paper sx={{
                width: "70%",
                maxWidth: "784px",
                padding: "40px",
                textAlign: "center"
            }}>
                <Typography
                    variant="h4" gutterBottom
                    mb={3}
                    sx={{
                        textAlign: "center",
                        fontSize: "34px",
                        lineHeight: "123.5%",
                        letterSpacing: "0.25px"
                    }}>
                    Authenticate
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",}}>
                    Please use your Google account to authenticate 
                </Typography>
                <Box mt={6} sx={{justifyContent:"center", alignItems:"center"}}>
                    <GoogleAuth />
                </Box>
            </Paper>
            <LoginFooter sx={{position: "absolute", bottom: 2, textAlign: "center"}}></LoginFooter>
        </Box>
    )
}

export default Login;