import {createContext, useState} from 'react';
import {AlertColor} from '@mui/material';

interface mainSnackbar {
    isOpen: boolean;
    type: AlertColor;
    message: string;
    updateMainSnackbar: (isOpen: boolean, type: AlertColor, message: string) => void;
}

export const CurrentMainSnackbar = createContext<mainSnackbar>({
    isOpen: false,
    type: "success",
    message: "Default text",
    updateMainSnackbar: (isOpen: boolean, type: AlertColor, message: string) => undefined
});

export const useMainSnackbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState<AlertColor>("success");
    const [message, setMessage] = useState('');
    const updateMainSnackbar = (isOpen: boolean, type: AlertColor, message: string) => {
        setIsOpen(isOpen);
        setType(type);
        setMessage(message);
    }
    return {isOpen, type, message, updateMainSnackbar, setIsOpen};
};