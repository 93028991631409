import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { forwardRef, useCallback, useState } from 'react';
import { Popover, Box, TextField, Typography, IconButton, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon


interface DatePickerValueProps {
  handleApply: (startDate: any, endDate: any) => void;
  resetDateFilter : () => void;
  startDateRef: React.RefObject<Dayjs | null>;
  endDateRef: React.RefObject<Dayjs | null>;
}

const DatePickerValue = forwardRef<HTMLDivElement, DatePickerValueProps>(({
  handleApply,
  resetDateFilter,
  startDateRef,
  endDateRef,
}, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pickerStartDate, setPickerStartDate] = useState<Dayjs | null>(startDateRef.current);
  const [pickerEndDate, setPickerEndDate] = useState<Dayjs | null>(endDateRef.current);
  const [error, setError] = useState<string | null>(null);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onApplyClick = useCallback(() => {
    if (pickerStartDate && pickerEndDate) {
      if (pickerStartDate.isAfter(pickerEndDate)) {
        setError('Start date cannot be after end date.');
      } else if (pickerEndDate.isBefore(pickerStartDate)) {
        setError('End date cannot be before start date.');
      } else {
        setError(null);
        handleApply(pickerStartDate, pickerEndDate);
        handleClose();
      }
    }
  }, [pickerStartDate, pickerEndDate, handleApply, handleClose]);

  const open = Boolean(anchorEl);

  return (
    <>
      <Autocomplete
        options={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="DATE RANGE"
            disabled
          />
        )}
        open={false}
        clearOnEscape={false}
        onInputChange={(event, value, reason) => {
          if (reason === 'clear') {
            resetDateFilter();
          }
        }}
        value={'Date Range'}
        componentsProps={{
          popupIndicator: {
            onClick: (event) => {
              event.stopPropagation();
              handleOpen(event);
            },
          },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, position: 'relative' }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography sx={{ mb: 3 }}>Select Date Range</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <DatePicker
                label="Start Date"
                value={pickerStartDate}
                onChange={(newValue) => setPickerStartDate(newValue)}
                sx={{ width: 200, fontSize: '0.875rem' }}
              />
              <Typography variant="body1" sx={{ flexShrink: 0 }}>
                TO
              </Typography>
              <DatePicker
                label="End Date"
                value={pickerEndDate}
                onChange={(newValue) => setPickerEndDate(newValue)}
                sx={{ width: 200, fontSize: '0.875rem' }}
              />
            </Box>
            {error && (
              <Typography variant='caption' color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, mt: 2 }}>
              <Button onClick={handleClose} sx={{ width: '100px' }}>Cancel</Button>
              <Button onClick={onApplyClick} variant="contained" sx={{ width: '80px' }}>Apply</Button>
            </Box>
          </LocalizationProvider>
        </Box>
      </Popover>
    </>
  );
});

export default DatePickerValue;
