import {ReactNode, useContext, useEffect} from "react";
import {CurrentUserInfo} from "../hooks/useUserInfo";
import {useNavigate} from "react-router-dom";
import {ACCESS_TOKEN} from "../Login/loginConstants";

interface SecureRoute {
    children: ReactNode;
}
const SecureRoute = (props: SecureRoute) => {
    const {authenticated} = useContext(CurrentUserInfo);
    const nav = useNavigate();
    useEffect(() => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        if (!accessToken) {
            setTimeout(() => (nav('/login')));
        }
    },[authenticated])
    return (
        <>{props.children}</>
    )
}

export default SecureRoute;