import {User} from "../model/User";
import {createContext, useState} from "react";
import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {ACCESS_TOKEN} from "../Login/loginConstants";
import { redirect } from "react-router-dom";

interface userInfo {
    user: User | undefined;
    authenticated: boolean;
    updateUser: (user: User | undefined)  => Promise<boolean>;
    logout: () => void;
}

export const CurrentUserInfo = createContext<userInfo>({
    user: undefined,
    authenticated: false,
    updateUser: (user: User | undefined) => (Promise.resolve(false)),
    logout: () => undefined,
});

let baseURL = process.env.NODE_ENV === 'development' ? "http://localhost:8080" : "/"
const axiosInstance = axios.create({
    baseURL
});

export const useUserInfo = () => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const [authenticated, setAuthenticated] = useState(false);
    const [authInterceptor, setAuthInterceptor] = useState<number | undefined>(undefined);
    const updateUser = async (aUser: User | undefined): Promise<boolean> => {
        localStorage.setItem('userInfo', JSON.stringify(aUser));
        if (localStorage.getItem("userInfo")) {
            const userInfo = localStorage.getItem("userInfo");
           
            if (userInfo && (userInfo !== "undefined" || userInfo !== null)) {
                setUser(JSON.parse(userInfo));
                setAuthenticated(true);
                setAuthInterceptor(axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
                    const token = localStorage.getItem(ACCESS_TOKEN);
                    if (token) {
                        config.headers.Authorization = `Bearer ${token}`;
                    } else {
                        delete config.headers.Authorization;
                    }
                    return config;
                }, (error) => {
                    return Promise.reject(error);
                }));
                
                axios.interceptors.response.use((res: AxiosResponse) => {
                    return res;
                }, (error: AxiosError) => {
                    if (error.response?.status === 401) {
                        logout();
                        window.location.reload();
                        redirect('/login');

                    }
                    return Promise.reject(error);
                });
            }
        } else {
            setUser(undefined);
            setAuthenticated(false);
            localStorage.clear();
            if (authInterceptor !== undefined) {
                axios.interceptors.request.eject(authInterceptor);
            }
        }

        return Promise.resolve(true);
    };

    axiosInstance.interceptors.request.use((config:InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            delete config.headers.Authorization;
        }
        return config;
      },(error)=>{
        return Promise.reject(error);
      });
      axiosInstance.interceptors.response.use((res:AxiosResponse) => {
        return res;
      },(error:AxiosError)=>{
        if (error.response?.status === 401) {
            logout();
            window.location.reload();
           return redirect('/login');

        }
        return Promise.reject(error);
      });


    const logout = () => {
        setAuthenticated(false);
        localStorage.clear();
       return redirect('/login');

    };
    
    return { user, authenticated, updateUser, logout };
};
export default axiosInstance