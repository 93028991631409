import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";

interface UserModalProps {
    open: boolean;
    handleClose: (email: string | undefined) => void
}

const UserModal = (props: UserModalProps) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const onClose = () => {
        setEmail('');
        props.handleClose(undefined);
    }

    const onSubmit = () => {
        props.handleClose(email);
    }

    const checkAndSetEmail = (emailInput: string) => {
        if (emailInput.includes("@") && emailInput.includes(".")) {
            if (!emailInput.toUpperCase().includes("@BENTPIXELS.COM")) {
                setError(true);
            } else {
                setError(false);
            }
        } else {
            setError(false);
        }
        setEmail(emailInput);
    }

    return (
        <Dialog onClose={onClose} open={props.open}>
            <DialogTitle>Add new user</DialogTitle>
            <DialogContent sx={{width: 450}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkAndSetEmail(event.target.value)}
                    helperText={error ? 'This is not a BentPixels email. Are you sure you want to add it?' : ''}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>Add User</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserModal;