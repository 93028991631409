import {createTheme, Theme} from "@mui/material";

export const BentPixelsTheme: Theme = createTheme({
    palette: {
        primary: {
            main: "#0A2AE7",
            contrastText: '#FFFFFF',
        },
        background: {
            default: "#3550f714",
            paper: "#FFFFFF"
        }
    },
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-completed': {
                        color: '#14A81C'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: 'rgba(53, 80, 247, 0.04)',
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: "Poppins",
        h1: {
            fontWeight: 300,
            fontSize: "96px",
            letterSpacing: "-1.5px"
        },
        h2: {
            fontWeight: 300,
            fontSize: "60px",
            letterSpacing: "-0.5px"
        },
        h3: {
            fontWeight: 400,
            fontSize: "34px",
            letterSpacing: "0.25px"
        },
        h4: {
            fontWeight: 400,
            fontSize: "24px",
        },
        h5: {
            fontWeight: 400,
            fontSize: "60px",
            letterSpacing: "0.15px"
        },
        h6: {
            fontWeight: 500,
            fontSize: "20px",
            letterSpacing: "0.15px",
            color: "#20272EDE"

        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "16px",
            letterSpacing: "0.15px"

        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "14px",
            letterSpacing: "0.1px"
        },
        body1: {
            fontWeight: 400,
            fontSize: "16px",
            letterSpacing: "0.15px"
        },
        body2: {
            fontWeight: 400,
            fontSize: "14px",
            letterSpacing: "0.17px"
        },
        caption: {
            fontWeight: 400,
            fontSize: "12px",
            color: "rgba(32, 39, 46, 0.87)"
        },
        button: {
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "0.4px"
        }
    }
});