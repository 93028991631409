import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {useCallback, useContext, useEffect, useState,useRef} from "react";
import {
  DataGrid,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {GamCalculation,GamCalculationResponse} from "../../model/GamCalculation";
import { EditRow } from "../../model/EditRow";
import {getGamDataForReportId, getModLogs} from "../../services/GamRunService";
import { updateRow } from "../../services/CreatorSummaryService";
import {AxiosResponse} from "axios";
import {CurrentReportDate} from "../../hooks/useReportDate";
import {CurrentMainSnackbar} from "../../hooks/useMainSnackbar";
import '../DetailView/detailview.css';
import '../../Review/Review.scss';
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import * as React from "react";
import EditRowModal from "./editRowModal"
import {LockedPayment} from "../../model/LockedPayment";
import {getLockedPayments} from "../../services/LockService";
import { log } from "console";
import { gamTableColumns } from "./logsTableColumns";
import DatePickerValue from "./DateRange";
import dayjs, { Dayjs } from 'dayjs';

interface ModificatioLogsProps {
  reportId: string | undefined;
}

interface filterInterface {
  error: string | null;
  orderTitle: string | null;
  kpeId: string | null;
  errorMessage: string | null;
  userName: string | null;
}

function transformData(data: any): any[] {
    const transformedData: any[] = [];

    data.gamCalculationHistories.forEach((entry: any) => {
        // If gamCalculationRevisions has only one entry, skip this entry
        if (entry.gamCalculationRevisions.length <= 1) {
            return;
        }
        // Sort the revisions by timestamp
        const revisionsSorted = [...entry.gamCalculationRevisions].sort(
            (a: any, b: any) => a.auditUser.timestamp - b.auditUser.timestamp
        );

        // Iterate through the sorted revisions and create the transformed structure
        for (let i = 1; i < revisionsSorted.length; i++) {
            const prevRevision: any = revisionsSorted[i - 1];
            const currentRevision: any = revisionsSorted[i];

            // If userName is null, skip this entry
            if (!currentRevision.auditUser.userName) {
                continue;
            }

            const transformedEntry: any = {
                advertiserId: entry.compositeKey.advertiserId,
                advertiserTitle: entry.compositeKey.advertiserTitle,
                assetLabel: entry.compositeKey.assetLabel,
                gamOrderId: entry.compositeKey.gamOrderId,
                lineItemId: entry.compositeKey.lineItemId,
                lineItemTitle: entry.compositeKey.lineItemTitle,
                orderTitle: entry.compositeKey.orderTitle,
                reportId: entry.compositeKey.reportId,
                ytChannelId: entry.compositeKey.ytChannelId,
                ytChannelKpeId: entry.compositeKey.ytChannelKpeId,
                revisions: null,
                errorMessage: currentRevision.errorMessage,
                groupName:currentRevision.groupName,
                timestamp: currentRevision.auditUser.timestamp,
                userName: currentRevision.auditUser.userName,
                countryCode:currentRevision.countryCode,
                id: currentRevision.auditUser.id,
                contractId: currentRevision.contractId,
                overwritten: currentRevision.overwritten,
                error: currentRevision.error,
                auditUser:null, 
            };

            // Compare each attribute and store the previous and current values
            for (const key in prevRevision) {
                if (!(key in transformedEntry)) { // Skip the attributes already added
                    transformedEntry[key] = {
                        prev: prevRevision[key],
                        current: currentRevision[key]
                    };
                }
            }

            // Append the transformed entry to the list
            transformedData.push(transformedEntry);
        }
    });

    return transformedData;
}
  
const ModificatioLogs = (props: ModificatioLogsProps) => {
  const columns = gamTableColumns;
  const {year, month, lastUpdateDate} = useContext(CurrentReportDate);
  const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
  const [loading, setLoading] = useState(true);
  const [originalRows, setOriginalRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [filters, setFilters] = useState<filterInterface>({
      error: null,
      orderTitle: null,
      kpeId: null,
      errorMessage: null,
      userName: null
  });
  const [otFilter, setOTFilter] = useState<string | null>(null);
  const [kpeFilter, setKPEFilter] = useState<string | null>(null);
  const [emFilter, setEMFilter] = useState<string | null>(null);
  const [errorFilter, setErrorFilter] = useState<string | null>(null);
  const [errorFilterOptions, setErrorFilterOptions] = useState([]);
  const [orderTitleFilterOptions, setOrderTitleFilterOptions] = useState([]);
  const [kpeIdFilterOptions, setKpeIdFilterOptions] = useState([]);
  const [errorMessageFilterOptions, setErrorMessageFilterOptions] = useState([]);
  const [lockedPayments, setLockedPayments] = useState<LockedPayment[]>([]);
  const [userNameFilter, setUserNameFilter] = useState<string | null>(null);
  const [userNameFilterOptions, setUserNameFilterOptions] = useState<string[]>([]);
  const startDateRef = useRef(dayjs())
  const endDateRef = useRef(dayjs())

  const refresh = useCallback(() => {
      if (props.reportId !== undefined) {
          setLoading(true);
          getModLogs(props.reportId).then((res: AxiosResponse<any[]>) => {
              const tranformedData = transformData(res.data)
              setOriginalRows(tranformedData);
              setFilteredRows(tranformedData);
              setTimeout(() => setLoading(false), 800);
          }).catch((error) => {
            //   updateMainSnackbar(true, "error", "Error fetching data");
              setLoading(false);
          });
      } else {
          setOriginalRows([]);
          setFilteredRows([]);
          setLoading(false);
      }

      getLockedPayments(year, month)
          .then((res: AxiosResponse<LockedPayment[]>) => {
              if (res.status === 200) {
                  setLockedPayments(res.data);
              }
          });
  }, [props.reportId]);

  useEffect(() => {
      refresh();
  }, [refresh, lastUpdateDate]);

  const updateErrorFilter = (event: any, newValue: string | null) => {
      let newValueFormatted = newValue?.replace(/\s/g, "_").toUpperCase();
      newValueFormatted = newValueFormatted?.replace(/,_/g, ", ")
      setFilters({...filters, error: newValueFormatted ?? null})
      setErrorFilter(newValue)
  }

  const updateOTFilter = (event: any, newValue: string | null) => {
      setFilters({...filters, orderTitle: newValue});
      setOTFilter(newValue);
  }
  const updateKPEFilter = (event: any, newValue: string | null) => {
      setFilters({...filters, kpeId: newValue});
      setKPEFilter(newValue);
  }
  const updateEMFilter = (event: any, newValue: string | null) => {
      setFilters({...filters, errorMessage: newValue});
      setEMFilter(newValue);
  }

  const updateUserNameFilter = (event: any, newValue: string | null) => {
    setFilters({...filters, userName: newValue});
    setUserNameFilter(newValue);
  };
  
  const handleDateRangeApply = (startDate: Dayjs, endDate: Dayjs) => {
    if (!startDate || !endDate) return;
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    // Convert startDate and endDate to Date objects and normalize to start of day
    const start = new Date(startDate.toISOString());
    const end = new Date(endDate.toISOString());
  
    // Normalize start and end dates to midnight
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999); // Set end to the end of the day
  
    const filteredData = originalRows.filter(row => {
      // Convert the row's timestamp to a Date object and normalize to midnight
      const rowTimestamp = new Date(row.timestamp);
      rowTimestamp.setHours(0, 0, 0, 0);
  
      // Check if the row's timestamp is within the date range (inclusive)
      return rowTimestamp >= start && rowTimestamp <= end;
    });
  
    setFilteredRows(filteredData);
  };
  
  const resetDateFilter = () => setFilteredRows(originalRows)

  useEffect(() => {
      let rows = originalRows;
      if (filters.error !== null) {
          rows = rows.filter((row) => {
              const arr = filters.error?.split(", ")
              return row.errorTypes?.[0] === arr?.[0]
          });
      }
      if (filters.orderTitle !== null) {
          rows = rows.filter((row) => row.orderTitle === filters.orderTitle);
      }
      if (filters.kpeId !== null) {
          rows = rows.filter((row) => row.ytChannelKpeId === filters.kpeId);
      }
      if (filters.errorMessage !== null) {
          rows = rows.filter((row) => row.errorMessage === filters.errorMessage);
      }
      if (filters.userName !== null) {
        rows = rows.filter((row) => row.userName === filters.userName);
      }
      setFilteredRows(rows);
  }, [filters, originalRows]);

  const [openSearch, setOpenSearch] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setOpenSearch(!openSearch);
  };
  useEffect(() => {
     let err: any[] = [];
      let ot = new Set();
      let kpe = new Set();
      let em = new Set();
      let un = new Set();
      filteredRows.forEach((row) => {
          if (row.errorTypes?.length && !err.includes(row.errorTypes.join(", "))) {
              err.push(row.errorTypes.join(", "));
          }
          ot.add(row.orderTitle);
          kpe.add(row.ytChannelKpeId);
          em.add(row.errorMessage);
          if (row.userName) {
            un.add(row.userName);
          }
      });
      // remove underscore for the error filter values and set camel case
      err = err.map((item) => 
        item.replace(/_/g, " ")
            .toLowerCase()
            .replace(/\b\w/g, (l: string) => l.toUpperCase())
    );
    // @ts-ignore
    setErrorFilterOptions([...err]);
    // @ts-ignore
    setOrderTitleFilterOptions([...ot]);
    // @ts-ignore
    setKpeIdFilterOptions([...kpe]);
    // @ts-ignore
    setErrorMessageFilterOptions([...em]);
    // @ts-ignore
    setUserNameFilterOptions(Array.from(un));
}, [filteredRows]);

  const [openModal, setOpenModal] = React.useState(false);
  const [alertLocked, setAlertLocked] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [editFields, setEditFields] = useState<EditRow>();

  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
      setOpenModal(true);
  }, [editFields])

  const getRowOverwritten = (params: GridRowParams) => {
      const row: GamCalculation = params.row as GamCalculation;
      if (row.overwritten) {
        return 'bg-yellow';
      }
      return '';
  };

  return (
      <>
      <Grid container={true} sx={{my: 2}} spacing={2}>
         {loading ?<Skeleton sx={{width: 1, height: 400}} variant={"rectangular"}/> :
          <Grid item sm={12}>
              <DataGrid
                  sx={{height: 'calc(100vh - 350px)'}}
                  localeText={{
                      toolbarColumns: "",
                      toolbarDensity: "",
                      toolbarFilters: ""
                  }}
                  rows={filteredRows}
                  columns={columns}
                  density={'compact'}
                  onRowDoubleClick={(params) => {
                      let isLocked = lockedPayments.filter((item) => {
                          return item.paymentId === params.row.gamOrderId
                      })
                      setAlertLocked(isLocked.length > 0 );
                      setSelectedRow(params.row);
                      setOpenModal(true);
                  }}
                  className="details-data-container"
                //   getRowClassName={getRowOverwritten}
                  components={{
                      Toolbar: () => (
                          <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
                              <Grid container className="filters-container">
                                  <Grid item xs={7}>
                                     <Autocomplete
                                            disablePortal
                                            id="user-name-filter-combo-box"
                                            options={userNameFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="User"/>}
                                            onChange={updateUserNameFilter}
                                            value={userNameFilter}
                                            />
                                      <Autocomplete
                                            disablePortal
                                            id="ot-filter-combo-box"
                                            options={orderTitleFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="Order Title"/>}
                                            onChange={updateOTFilter}
                                            value={otFilter}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="error-message-combo-box"
                                            options={kpeIdFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="KPE ID"/>}
                                            onChange={updateKPEFilter}
                                            value={kpeFilter}
                                        />
                                        <DatePickerValue 
                                        handleApply={handleDateRangeApply} 
                                        resetDateFilter={resetDateFilter}
                                        startDateRef={startDateRef} 
                                        endDateRef={endDateRef} 
                                        />
                                  </Grid>
                                  <Grid item xs={5}  textAlign="right" className="filter-buttons-container">
                                      {/* <GridToolbarExport /> */}
                                      <GridToolbarColumnsButton placeholder={'column'} startIcon={<ViewColumnOutlinedIcon />}/>
                                      <GridToolbarDensitySelector placeholder={'menu'} startIcon={<MenuOutlinedIcon />}/>
                                      <GridToolbarFilterButton placeholder={'filter'} />
                                      {!openSearch ? <IconButton
                                          onClick={handleClick}
                                          size="small"
                                          className="search-component"
                                          sx={{ ml: 2 }}
                                          aria-controls={openSearch ? 'open-search' : undefined}
                                          aria-haspopup="true"
                                          aria-expanded={openSearch ? 'true' : undefined}>
                                          <SearchOutlinedIcon color="primary" />
                                      </IconButton>: <Box className="input-search-container">
                                          <GridToolbarQuickFilter
                                          className="search-component" onClose={handleClick} sx={{pr: 4}}/>
                                          <IconButton className="closing-btn" onClick={handleClick} size="small">
                                              <SearchOffIcon color="primary" />
                                          </IconButton>
                                      </Box>}
                                      <Button variant="outlined" onClick={refresh} className={`refresh-btn ${loading && 'refresh-animation'}`}>
                                          <RefreshOutlinedIcon/>
                                      </Button>
                                  </Grid>
                              </Grid>
                          </GridToolbarContainer>
                      )
                  }}
              />
          </Grid>}
      </Grid>
      {selectedRow && (
          <EditRowModal
          open={openModal}
          rowData={selectedRow}
          onCancel={handleCloseModal}
          locked={alertLocked}
          />
      )}
      </>
  );
}

export default ModificatioLogs;