import {
    Autocomplete,
    Box,
    Button, Grid,
    Skeleton,
    TextField,
} from "@mui/material";
import '../YoutubeSummary.scss';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import React, {useContext, useEffect, useState} from "react";
import {
    getYTChannels,
    loadUnassignedAssets,
    downloadUnassignedAssetsCSV,
    getUniqueCMS
} from "../../services/YTService";
import {CurrentReportDate} from "../../hooks/useReportDate";
import {AxiosResponse} from "axios";
import {UnassignedAsset} from "../../model/UnassignedAsset";
import {ContentOwnerResponse} from "../../model/ContentOwner";
import UnassignedAssetDialog from "./UnassignedAssetDialog";
import {Page} from "../../model/Page";

interface UnassignedAssetsProps {
    unassignedAssets: any;
    setUnassignedAssets: any;
    assignedAssets: any;
    setAssignedAssets: any;
    uniqueCMSArray: any;
    setUniqueCMSArray: any;
    channels: any;
    setChannels: any;
    loading:boolean;
}

const UnassignedAssets = (props: UnassignedAssetsProps) => {
    const {unassignedAssets, 
          setUnassignedAssets, 
          assignedAssets, 
          setAssignedAssets,
          channels,
          setChannels,
          uniqueCMSArray,
          setUniqueCMSArray,
          loading
        } = props;
    const {year, month} = useContext(CurrentReportDate);
    // const [unassignedAssets, setUnassignedAssets] = useState<UnassignedAsset[]>([]);
    const [open, setOpen] = useState(false);
    const [assetId, setAssetId] = useState('');
    // const [channels, setChannels] = useState<string[]>([]);
    const [pages, setPages] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [recordsPerPage, setRecordsPerPage] = useState(100);
    const [cmsFilter, setCMSFilter] = useState<string | null>(null);
    // const [uniqueCMSArray, setUniqueCMSArray] = useState<string[]>([]);

    const getAssignButton = (params: GridRenderCellParams<string>) => {
        return (<Button onClick={() => openDialog(`${params.id}`)}>{`Assign`}</Button>);
    }

    const columns: GridColDef[] = [
        { field: 'assetId', headerName: 'Asset ID', flex: 1 },
        { field: 'assetTitle', headerName: 'Asset Titles', flex: 1.5 },
        { field: 'assetLabels', headerName: 'Asset Labels', flex: 1.5 },
        { field: 'customId', headerName: 'Custom ID', flex: 1 },
        { field: 'cms', headerName: 'CMS', flex: 1 },
        { field: 'creator', headerName: 'Creator', flex: 1 },
        { field: 'earnings', headerName: 'Earnings (US$)', flex: 1},
        {
            field: 'assignButton',
            headerName: '',
            width: 168,
            renderCell: getAssignButton,
            flex: 1,
            sortable: false,
            align: 'right'
        },
    ];

    // useEffect(() => {
        // getUniqueCMS().then((res: AxiosResponse<ContentOwnerResponse>) => {    
        // const uniqueCMS = res.data.contentOwners.map(contentOwner => contentOwner.name) 
        // setUniqueCMSArray(uniqueCMS);           
        // });
        // loadUnassignedAssets(year, month)
        //     .then((res: AxiosResponse<UnassignedAsset[]>) => {
        //         setRecordsPerPage(res.data.totalElements)
        //         setPages(res.data.totalPages); 
        //         const updatedAssets = res.data.map(asset => ({
        //             ...asset,
        //             id: asset.assetId,
        //         }));
        //         setUnassignedAssets(updatedAssets);
        //     });
        // getYTChannels(year, month)
        //     .then((res: AxiosResponse<string[]>) => {
        //         let uniqueChannels: string[] = [];
        //         for (const channel of res.data) {
        //             let key = Object.keys(channel)[0];
        //             let value = Object.values(channel)[0];
        //             let label = `${value} (${key})`;
        //             if (!uniqueChannels.includes(label)) {
        //                 uniqueChannels.push(label);
        //             }
        //         }
        //         setChannels(uniqueChannels);
        //     })
    // }, [year, month]);

    const openDialog = (assetId: string) => {
        setAssetId(assetId);
        setOpen(true);
    }

    const saveTransaction = (assetId: string) => {
        setUnassignedAssets(unassignedAssets.filter((ua: { assetId: string; }) => ua.assetId !== assetId));
        setAssetId('');
        setOpen(false);
    }

    const cancelTransaction = () => {
        setAssetId('');
        setOpen(false);
    }

    const downloadFile = (file: Blob | MediaSource) => {
        const href = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `unassigned-assets-${year}-${month}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const exportData = () => {
        downloadUnassignedAssetsCSV(year, month).then((response) => {
            downloadFile(response.data);
        })
    }

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = unassignedAssets.filter((row: UnassignedAsset) => {
        const searchTermLower = searchTerm.toLowerCase();
    
        const titleMatches = row.assetTitle
            ? row.assetTitle.toLowerCase().includes(searchTermLower)
            : false;
    
        const idMatches = row.assetId
            ? row.assetId.toLowerCase().includes(searchTermLower)
            : false;
    
        const cmsMatches = cmsFilter ? row.cms === cmsFilter : true;
    
        return (titleMatches || idMatches) && cmsMatches;
    });

    const updateCMSFilter = (
        event: React.ChangeEvent<{}>,
        newValue: string | null
    ) => {
        setCMSFilter(newValue);
    };

    return (
        <>
        {loading ? 
        <Skeleton sx={{width: 1, height: 400, mt: 4}} variant={"rectangular"}/> :
        <Box sx={{width: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container className="filters-container" style={{marginTop:"20px"}}>
                <Grid item xs={2} >
            <Autocomplete
                disablePortal
                id="cms-filter-combo-box"
                options={uniqueCMSArray}
                renderInput={(params) => <TextField {...params} label="CMS" />}
                onChange={updateCMSFilter}
                value={cmsFilter}
            />
                </Grid>
            </Grid>

            <div style={{ height: 'calc(100vh - 400px)', width: '100%', marginTop: '24px', position: 'relative'}}>

                <TextField
                    label="Search"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    size="small"
                    variant="standard"
                    sx={{
                        height:"34px", 
                        position: 'absolute', 
                        right: 0, 
                        top: '-78px'

                    }}/>
                <Button disabled={channels.length === 0 } variant={'contained'} onClick={exportData} sx={{
                    height:"34px",
                    position: 'absolute',
                    right: 350,
                    top: '-65px'
                }}>
                    Export Data
                </Button>
                <DataGrid sx={{mt: 2}} checkboxSelection disableColumnMenu rows={filteredData} columns={columns} className={"custom-table-container"} components={{
                    NoRowsOverlay: () => <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',bgcolor:'#b2bcfc'}}>
                          {filteredData?.length === 0 ? <p>No Unassigned Assets Found</p> : <></>}
                    </Box>
                }} />
            </div>
            {
                open && (<UnassignedAssetDialog open={open} assetId={assetId} channels={channels} saveTransaction={saveTransaction} handleClose={cancelTransaction} setAssignedAssets={setAssignedAssets} assignedAssets={assignedAssets} />)
            }
            
        </Box>}
        </>
    )
}

export default UnassignedAssets