import {
    Box,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableCell, TableBody, Button, IconButton, TextareaAutosize
} from "@mui/material";
import {forwardRef, ReactNode, SyntheticEvent, useCallback, useContext, useEffect, useState} from "react";
import {GamSummary} from "../../model/GamSummary";
import {
    getGamSummaryDataForReportId,
    uploadGamSummary
} from "../../services/GamRunService";
import {AxiosResponse} from "axios";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import {GamStakeholder} from "../../model/GamStakeholder";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import {CurrentReportDate} from "../../hooks/useReportDate";
import {LockedPayment} from "../../model/LockedPayment";
import {getLockedPayments, lockGamPayment} from "../../services/LockService";

interface SummaryViewProps {
    reportId: string | undefined;
}

interface SummaryRows {
    lockButton: ReactNode[];
    advertiserTitle: ReactNode[];
    orderTitle: ReactNode[];
    google: ReactNode[];
    channelRevShare: ReactNode[];
    groupRevShare: ReactNode[];
    bpRevShare: ReactNode[];
    bpServiceFee: ReactNode[];
    bpOverage: ReactNode[];
    groupOverage: ReactNode[];
    invoiceTotal: ReactNode[];
    notes: ReactNode[];
    netTotal: ReactNode[];
    ud: ReactNode[];
    bpTotal: ReactNode[];
    lessUd: ReactNode[];
    pgADX: ReactNode[];
    seller: ReactNode[];
    campaignManager: ReactNode[];
    chiefGrowthOfficer: ReactNode[];
    departmentManager: ReactNode[];
    bpFinal: ReactNode[];
    grossMargin: ReactNode[];
    netMargin: ReactNode[];
    bpTalentOverageDollars: ReactNode[];
    residualOverageDollars: ReactNode[];
    licensingCost: ReactNode[];
}

const formatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    minimumIntegerDigits: 1,
    minimumFractionDigits:2,
    maximumFractionDigits:2
});

const SummaryViewTableCells = (props: { children?: ReactNode, align: "center" | "inherit" | "left" | "right" | "justify" | undefined, className?: string | undefined }) => {
    return (
        <TableCell align={props.align} sx={{width: '300px'}} className={props.className}>
            {props.children}
        </TableCell>
    )
};

const SummaryView = (props: SummaryViewProps) => {
    const [summaryData, setSummaryData] = useState<GamSummary[] | undefined>();
    const [summaryRows, setSummaryRows] = useState<SummaryRows>({
        lockButton: [],
        advertiserTitle: [],
        orderTitle: [],
        google: [],
        channelRevShare: [],
        groupRevShare: [],
        bpRevShare: [],
        bpServiceFee: [],
        bpOverage: [],
        groupOverage: [],
        invoiceTotal: [],
        notes: [],
        netTotal: [],
        ud: [],
        bpTotal: [],
        lessUd: [],
        pgADX: [],
        seller: [],
        campaignManager: [],
        chiefGrowthOfficer: [],
        departmentManager: [],
        bpFinal: [],
        grossMargin: [],
        netMargin: [],
        bpTalentOverageDollars: [],
        residualOverageDollars: [],
        licensingCost: []
    });
    const [stakeholderPayments, setStakeholderPayments] = useState<Map<string, Map<number, number>>>();
    const [stakeholderNames, setStakeholderNames] = useState<string[]>()
    const [stakeholderPaymentLines, setStakeholderPaymentLines] = useState<ReactNode[][]>([]);
    const [showStakeholders, setShowStakeholders] = useState(false);
    const {year, month} = useContext(CurrentReportDate);
    const [lockedPayments, setLockedPayments] = useState<LockedPayment[]>([]);

    useEffect(() => {
        if (props.reportId !== undefined) {
            getGamSummaryDataForReportId(props.reportId)
                .then((res: AxiosResponse<GamSummary[]>) => {
                    setSummaryData(res.data);
                });
            getLockedPayments(year, month)
                .then((res: AxiosResponse<LockedPayment[]>) => {
                    if (res.status === 200) {
                        setLockedPayments(res.data);
                    }
                    else if (res.status === 204) setLockedPayments([])
                });
        }
    }, [props.reportId]);

    const calculateTotal = useCallback((row: GamSummary): number => {
        return row.google +
            row.channelRevShare +
            row.groupRevShare +
            row.bpRevShare +
            row.bpServiceFee +
            row.bpOverage +
            row.groupOverage +
            row.bpTalentOverageDollars;
    }, []);

    const calculateBPtotal = useCallback((row: GamSummary): number => {
        return row.bpOverage + row.bpServiceFee + row.bpRevShare;
    }, []);

    const calculateUD = useCallback((row: GamSummary): number => {
        return row.invoiceTotal - calculateTotal(row);
    }, [calculateTotal]);

    const calculateSubtotal = useCallback((row: GamSummary): number => {
        const pgAdxAmount = row.programmatic ? row.invoiceTotal * -0.05 : 0;

        return calculateBPtotal(row) +
            pgAdxAmount +
            calculateUD(row);
    }, [calculateUD, calculateBPtotal]);

    const calculateSellerTotal = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        const dateWithFixedTimeStamp = `${year}-${String(month).padStart(2, '0')}-01T00:00:00`
        const revShare: number = row.stakeholders
        .filter((sh) => sh.roleName === "Seller" && (
            (sh.startDate === null && sh.endDate === null) || 
            (sh.startDate !== null && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp) && sh.endDate === null) || 
            (sh.endDate !== null && new Date(`${sh.endDate}T00:00:00`) > new Date(dateWithFixedTimeStamp) && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp))
        ))
        .reduce((ac, sh) => ac + sh.sellerCommission, 0);
        return calculateSubtotal(row) * -1 * (revShare / 100);
    }, [calculateSubtotal, month, year]);

    const calculateCMtotal = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        const dateWithFixedTimeStamp = `${year}-${String(month).padStart(2, '0')}-01T00:00:00`
        const revShare: number = row.stakeholders
        .filter((sh) => sh.roleName === "Campaign Manager" && sh.name !== row.sellerName && (
            (sh.startDate === null && sh.endDate === null) || 
            (sh.startDate !== null && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp) && sh.endDate === null) || 
            (sh.endDate !== null && new Date(`${sh.endDate}T00:00:00`) > new Date(dateWithFixedTimeStamp) && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp))
        ))
        .reduce((ac, sh) => ac + sh.campaignManagerCommission, 0);
        return calculateSubtotal(row) * -1 * (revShare / 100);
    }, [calculateSubtotal, month, year]);

    const calculateDMtotal = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        const dateWithFixedTimeStamp = `${year}-${String(month).padStart(2, '0')}-01T00:00:00`
        const revShare: number = row.stakeholders
        .filter((sh) => sh.roleName === "Department Manager" && sh.name !== row.sellerName && (
            (sh.startDate === null && sh.endDate === null) ||
            (sh.startDate !== null && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp) && sh.endDate === null) || 
            (sh.endDate !== null && new Date(`${sh.endDate}T00:00:00`) > new Date(dateWithFixedTimeStamp) && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp))
        ))
        .reduce((ac, sh) => ac + sh.departmentManagerCommission, 0);
        return calculateSubtotal(row) * -1 * (revShare / 100);
    }, [calculateSubtotal, month, year]);

    const calculateCGOtotal = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        const dateWithFixedTimeStamp = `${year}-${String(month).padStart(2, '0')}-01T00:00:00`
        const revShare: number = row.stakeholders
        .filter((sh) => sh.roleName === "Chief Growth Officer" && sh.name !== row.sellerName && (
            (sh.startDate === null && sh.endDate === null) ||
            (sh.startDate !== null && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp) && sh.endDate === null) || 
            (sh.endDate !== null && new Date(`${sh.endDate}T00:00:00`) > new Date(dateWithFixedTimeStamp) && new Date(`${sh.startDate}T00:00:00`) <= new Date(dateWithFixedTimeStamp))
        ))
        .reduce((ac, sh) => ac + sh.chiefGrowthCommission, 0);
        return calculateSubtotal(row) * -1 * (revShare / 100);
    }, [calculateSubtotal, month, year]);


    const calculateBPFinal = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        return calculateSubtotal(row) +
            calculateSellerTotal(row) +
            calculateDMtotal(row) +
            calculateCMtotal(row) +
            calculateCGOtotal(row);
    }, [calculateSubtotal, calculateSellerTotal, calculateDMtotal, calculateCMtotal, calculateCGOtotal]);

    const calculateGrossMargin = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        return ((calculateBPtotal(row) + calculateUD(row)) / row.invoiceTotal) * 100;
    }, [calculateBPtotal, calculateUD]);

    const calculateNetMargin = useCallback((row: GamSummary): number => {
        if (row.invoiceTotal === undefined || row.invoiceTotal === 0) {
            return 0;
        }
        return (calculateBPFinal(row) / row.invoiceTotal) * 100;
    }, [calculateBPFinal]);

    const calculateBpTalentOverageDollars = useCallback((row: GamSummary): number => {
        return Number(row.bpTalentOverageDollars);
    }, []);

    const calculateResidualOverageDollars = useCallback((row: GamSummary): number => {
        return Number(row.residualOverageDollars);
    }, []);

    const calculateNetlicensingCost = useCallback((row: GamSummary): number => {
        return Number(row.licensingCost);
    }, []);

    const setInvoiceTotal = useCallback((id: number, total: string) => {
        // TODO: apply debounce
        if (summaryData) {
            const s = summaryData.map((d: GamSummary, index: number) => {
                if (id === index) {
                    d.invoiceTotal = Number(total);
                    if (props.reportId) {
                        uploadGamSummary(props.reportId, d);
                    }
                }
                return d;
            });
            setSummaryData(s);
        }
    }, [summaryData, props.reportId]);

    const advertiserNotesChange  = (id: number, notes: string) => {

        if (summaryData) {
            const newSummaryData = summaryData.map((summaryItem: GamSummary, index: number) => {
                if (id === index) {
                    return {
                        ...summaryItem,
                        notes: notes
                    };
                }
                return summaryItem;
            });
            setSummaryData(newSummaryData);
        }
    };

    const handleNotesBlur = (id: number) => {
        if (summaryData && props.reportId) {
            uploadGamSummary(props.reportId, summaryData[id]);
        }
    };

    const generateEmptyRow = (): ReactNode[] => {
        const cells: ReactNode[] = [];
        summaryData?.forEach((row, index) => {
            cells.push(<SummaryViewTableCells key={index} align={'right'}>&nbsp;</SummaryViewTableCells>)
        })
        cells.push(<SummaryViewTableCells key={'empty'} align={'right'}>&nbsp;</SummaryViewTableCells>);
        return cells;
    }

    const isPaymentLocked = (id: number): boolean => {
        const lockedPayment = lockedPayments.find((lp) => lp.paymentId === id);
        if (lockedPayment === undefined) {
            return false;
        }
        return lockedPayment.locked;
    }

    const lockPayment = (id: number) => {
        lockGamPayment(year, month, id)
            .then(() => getLockedPayments(year, month)
                .then((res: AxiosResponse<LockedPayment[]>) => {
                    if (res.status === 200) {
                        setLockedPayments(res.data);
                    }
                    else if (res.status === 204) setLockedPayments([])
                }));
    }

    useEffect(() => {
        const rows: SummaryRows = {
            lockButton: [<SummaryViewTableCells align={'left'}/>],
            advertiserTitle: [<SummaryViewTableCells align={'left'}><b>Advertiser</b></SummaryViewTableCells>],
            orderTitle: [<SummaryViewTableCells align={'left'}><b>Campaign</b></SummaryViewTableCells>],
            google: [<SummaryViewTableCells align={'left'}><b>Google (US$)</b></SummaryViewTableCells>],
            channelRevShare: [<SummaryViewTableCells align={'left'}><b>Channel Rev Share (US$)</b></SummaryViewTableCells>],
            groupRevShare: [<SummaryViewTableCells align={'left'}><b>Group Rev Share  (US$)</b></SummaryViewTableCells>],
            bpRevShare: [<SummaryViewTableCells align={'left'}><b>BP - Rev Share (US$)</b></SummaryViewTableCells>],
            bpServiceFee: [<SummaryViewTableCells align={'left'}><b>BP - Service Fee (US$)</b></SummaryViewTableCells>],
            bpOverage: [<SummaryViewTableCells align={'left'}><b>BP - Overage (US$)</b></SummaryViewTableCells>],
            groupOverage: [<SummaryViewTableCells align={'left'}><b>Group Overage (US$)</b></SummaryViewTableCells>],
            invoiceTotal: [<SummaryViewTableCells align={'left'}><b>Invoice Total</b></SummaryViewTableCells>],
            notes: [<SummaryViewTableCells align={'left'}><b>Other (Notes)</b></SummaryViewTableCells>],
            netTotal: [<SummaryViewTableCells align={'left'}><b>Net Total (US$)</b></SummaryViewTableCells>],
            bpTalentOverageDollars: [<SummaryViewTableCells align={'left'}><b>Channel Overage (US$)</b></SummaryViewTableCells>],
            residualOverageDollars: [<SummaryViewTableCells align={'left'}><b>Residual Overage (US$)</b></SummaryViewTableCells>],
            ud: [<SummaryViewTableCells align={'left'}><b>Unreconciled Difference</b></SummaryViewTableCells>],
            bpTotal: [<SummaryViewTableCells align={'left'}><b>Bent Pixels Total</b></SummaryViewTableCells>],
            lessUd: [<SummaryViewTableCells align={'left'}><b>Less UD</b></SummaryViewTableCells>],
            pgADX: [<SummaryViewTableCells align={'left'}><b>Less PG AdX Fee</b></SummaryViewTableCells>],
            seller: [<SummaryViewTableCells align={'left'}><b>Less Seller</b></SummaryViewTableCells>],
            licensingCost: [<SummaryViewTableCells align={'left'}><b>Less Licensing Cost</b></SummaryViewTableCells>],
            campaignManager: [<SummaryViewTableCells align={'left'}><b>Less Campaign Manager</b></SummaryViewTableCells>],
            chiefGrowthOfficer: [<SummaryViewTableCells align={'left'}><b>Less CGO</b></SummaryViewTableCells>],
            departmentManager: [<SummaryViewTableCells align={'left'}><b>Less Department Manager</b></SummaryViewTableCells>],
            bpFinal: [<SummaryViewTableCells align={'left'}><b>BP Final Net</b></SummaryViewTableCells>],
            grossMargin: [<SummaryViewTableCells align={'left'}><b>Gross Margin</b></SummaryViewTableCells>],
            netMargin: [<SummaryViewTableCells align={'left'}><b>Net Margin</b></SummaryViewTableCells>],
        };

        // notes: [<SummaryViewTableCells align={'left'}>Other (Notes)</SummaryViewTableCells>],

        summaryData?.forEach((row: GamSummary, id: number) => {
            rows.lockButton.push(<SummaryViewTableCells
                align={'center'} key={id}><IconButton disabled={isPaymentLocked(row.gamOrderId)} onClick={() => lockPayment(row.gamOrderId)}>
                {isPaymentLocked(row.gamOrderId) ? <LockIcon/> : <LockOpenOutlinedIcon/>}
            </IconButton>
            </SummaryViewTableCells>);
            rows.advertiserTitle.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'left'}>{row.advertiserTitle}</SummaryViewTableCells>);
            rows.orderTitle.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'left'}>
                <Typography variant={'body1'} sx={{display: 'flex', alignItems: 'left', flexDirection: 'column'}}>
                    {row.error && !row.ignoreBillingErrors ?
                        <ErrorOutlineIcon sx={{color: 'red', pr: 1}}/> : <> </>}{row.orderTitle}
                </Typography>
            </SummaryViewTableCells>)
            rows.google.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.google)}</SummaryViewTableCells>)
            rows.channelRevShare.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.channelRevShare)}</SummaryViewTableCells>)
            rows.groupRevShare.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.groupRevShare)}</SummaryViewTableCells>)
            rows.bpRevShare.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.bpRevShare)}</SummaryViewTableCells>)
            rows.bpServiceFee.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.bpServiceFee)}</SummaryViewTableCells>)
            rows.bpOverage.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.bpOverage)}</SummaryViewTableCells>)
            rows.groupOverage.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                align={'right'}>{formatter.format(row.groupOverage)}</SummaryViewTableCells>)
            rows.bpTalentOverageDollars.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateBpTalentOverageDollars(row))}</SummaryViewTableCells>);
            rows.invoiceTotal.push(<SummaryViewTableCells align={'right'}>  
                <TextField
                    onChange={(event) => {
                        setInvoiceTotal(id, event.target.value)
                    }}
                    disabled={isPaymentLocked(row.gamOrderId)}
                    placeholder="Value"
                    name="numberformat"
                    id="formatted-numberformat-input"
                    value={row.invoiceTotal === 0 ? undefined : row.invoiceTotal}
                    InputProps={{
                        inputComponent: NumericFormatCustom as any
                    }}
                    variant="outlined"
                    size={'small'}
                />
            </SummaryViewTableCells>)
            rows.notes.push(<SummaryViewTableCells align={'right'}>
                <TextareaAutosize minRows={3}
                                  value={row.notes}
                                  onChange= {e => advertiserNotesChange(id, e.target.value) }
                                  onBlur= {e => handleNotesBlur(id) }
                />
            </SummaryViewTableCells>)
            const netTotal = calculateTotal(row);
            rows. residualOverageDollars.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateResidualOverageDollars(row))}</SummaryViewTableCells>);
            rows.netTotal.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(netTotal)}</SummaryViewTableCells>)
            rows.ud.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateUD(row))}</SummaryViewTableCells>)
            rows.bpTotal.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateBPtotal(row))}</SummaryViewTableCells>);
            rows.lessUd.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(row.invoiceTotal - netTotal)}</SummaryViewTableCells>)
            rows.pgADX.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(row.programmatic ? row.invoiceTotal * -0.05 : 0)}</SummaryViewTableCells>)
            rows.seller.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateSellerTotal(row))}</SummaryViewTableCells>);
            rows.campaignManager.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateCMtotal(row))}</SummaryViewTableCells>);
            rows.chiefGrowthOfficer.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateCGOtotal(row))}</SummaryViewTableCells>);
            rows.departmentManager.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateDMtotal(row))}</SummaryViewTableCells>);
            rows.bpFinal.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{formatter.format(calculateBPFinal(row))}</SummaryViewTableCells>);
            rows.grossMargin.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{calculateGrossMargin(row).toFixed(2)}%</SummaryViewTableCells>);
            rows.netMargin.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{calculateNetMargin(row).toFixed(2)}%</SummaryViewTableCells>);
            rows.licensingCost.push(<SummaryViewTableCells
                className={isPaymentLocked(row.gamOrderId) ? 'disabled':''}
                key={row.gamOrderId} align={'right'}>{calculateNetlicensingCost(row).toFixed(2)}</SummaryViewTableCells>);

        });
        setSummaryRows(rows);
    }, [year,month,summaryData, calculateBPFinal, calculateBPtotal, calculateCGOtotal, calculateCMtotal, calculateDMtotal, calculateGrossMargin, calculateNetMargin,calculateNetlicensingCost, calculateBpTalentOverageDollars, calculateResidualOverageDollars, calculateSellerTotal, calculateTotal, calculateUD, setInvoiceTotal, lockedPayments]);
    const getValueForStakeholder = useCallback((sh: GamStakeholder, row: GamSummary) => {
        switch (sh.roleName) {
            case "Seller":
                return calculateSellerTotal(row);
            case "Campaign Manager":
                return calculateCMtotal(row);
            case "Department Manager":
                return calculateDMtotal(row);
            case "Chief Growth Officer":
                return calculateCGOtotal(row);
            default:
                return 0;
        }
    }, [calculateSellerTotal, calculateCMtotal, calculateDMtotal, calculateCGOtotal]);

    useEffect(() => {
        const personMap: Map<string, Map<number, number>> = new Map<string, Map<number, number>>();
        summaryData?.forEach((row: GamSummary) => {
            row.stakeholders.forEach((sh) => {
                let innerMap: Map<number, number> | undefined;
                if (personMap.has(sh.name)) {
                    innerMap = personMap.get(sh.name);
                } else {
                    innerMap = new Map<number, number>();
                }
                let earnings = getValueForStakeholder(sh, row) * -1;
                if (innerMap) {
                    const acumulator = personMap.get(sh.name)?.get(row.gamOrderId);
                    earnings = acumulator ? acumulator + earnings : earnings;
                    innerMap.set(row.gamOrderId, earnings);
                    personMap.set(sh.name, innerMap);
                }
            });
        });
        setStakeholderPayments(personMap);
        setStakeholderNames(Array.from(personMap.keys()).sort());
    }, [getValueForStakeholder, summaryData]);

    useEffect(() => {
        const internalStakeholderPaymentLines: ReactNode[][] = [];
        stakeholderNames?.forEach((shn) => {
            const lines: ReactNode[] = [];
            const personEarnings = stakeholderPayments?.get(shn);
            if (personEarnings !== undefined) {
                lines.push(<SummaryViewTableCells key={shn} align={'center'}>{shn}</SummaryViewTableCells>)
                summaryData?.forEach((row: GamSummary) => {
                    let earning: number | undefined;
                    earning = personEarnings.get(row.gamOrderId);
                    if (earning === undefined) {
                        earning = 0;
                    }
                    lines.push(<SummaryViewTableCells
                        align={"right"}>{formatter.format(Math.abs(earning))}</SummaryViewTableCells>)
                });
            }
            internalStakeholderPaymentLines.push(lines);
        });
        setStakeholderPaymentLines(internalStakeholderPaymentLines);

    }, [stakeholderNames, stakeholderPayments,year,month]);

    return (
        <>
            {props.reportId !== undefined ?
                <Box sx={{width: 1, mt: 2}}>
                    <TableContainer className="review-summary-container" component={Paper} elevation={0} sx={{height: 'calc(100vh - 380px)', mb: 4, mx: 'auto'}}>
                        <Table sx={{minWidth: 650, maxHeight: 500}} size="small">
                            <TableHead>
                                <TableRow key={'lb'}>
                                    {summaryRows.lockButton}
                                </TableRow>
                                <TableRow key={'at'}>
                                    {summaryRows.advertiserTitle}
                                </TableRow>
                                <TableRow key={'ot'}>
                                    {summaryRows.orderTitle}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'it'}>
                                    {summaryRows.invoiceTotal}
                                </TableRow>
                                <TableRow key={'e1'}>
                                    {summaryRows.notes}
                                </TableRow>
                                <TableRow key={'go'}>
                                    {summaryRows.google}
                                </TableRow>
                                <TableRow key={'crs'}>
                                    {summaryRows.channelRevShare}
                                </TableRow>
                                <TableRow key={'grs'}>
                                    {summaryRows.groupRevShare}
                                </TableRow>
                                <TableRow key={'brs'}>
                                    {summaryRows.bpRevShare}
                                </TableRow>
                                <TableRow key={'bto'}>
                                    {summaryRows.bpTalentOverageDollars}
                                </TableRow>
                                <TableRow key={'gov'}>
                                    {summaryRows.groupOverage}
                                </TableRow>
                                <TableRow key={'bo'}>
                                    {summaryRows.bpOverage}
                                </TableRow>
                                {/* <TableRow key={'ro'}>
                                    {summaryRows.residualOverageDollars}
                                </TableRow> */}
                                <TableRow key={'bsf'}>
                                    {summaryRows.bpServiceFee}
                                </TableRow>
                                <TableRow key={'net'}>
                                    {summaryRows.netTotal}
                                </TableRow>
                                {/* The following fields are commmented because the design removed this fields, It'll be removed after confirm all fields are ok*/}
                                <TableRow key={'e2'}>
                                    {generateEmptyRow()}
                                </TableRow>
                                <TableRow key={'ud'}>
                                    {summaryRows.ud}
                                </TableRow>
                                <TableRow key={'e3'}>
                                    {generateEmptyRow()}
                                </TableRow>
                                <TableRow key={'bpt'}>
                                    {summaryRows.bpTotal}
                                </TableRow>
                                <TableRow key={'lud'}>
                                    {summaryRows.lessUd}
                                </TableRow>
                                <TableRow key={'pga'}>
                                    {summaryRows.pgADX}
                                </TableRow>
                                {<TableRow key={'licensingCost'}>
                                    {summaryRows.licensingCost}
                                </TableRow>}
                                <TableRow key={'seell'}>
                                    {summaryRows.seller}
                                </TableRow>
                                <TableRow key={'cam'}>
                                    {summaryRows.campaignManager}
                                </TableRow>
                                <TableRow key={'cgo'}>
                                    {summaryRows.chiefGrowthOfficer}
                                </TableRow>
                                <TableRow key={'depm'}>
                                    {summaryRows.departmentManager}
                                </TableRow>
                                <TableRow key={'bpf'}>
                                    {summaryRows.bpFinal}
                                </TableRow>
                                <TableRow key={'e4'}>
                                    {generateEmptyRow()}
                                </TableRow>
                                <TableRow key={'grom'}>
                                    {summaryRows.grossMargin}
                                </TableRow>
                                <TableRow key={'netm'}>
                                    {summaryRows.netMargin}
                                </TableRow>
                                <TableRow key={'e5'}>
                                    {generateEmptyRow()}
                                </TableRow>
                                {showStakeholders ? stakeholderPaymentLines.map((line, id) => <TableRow
                                    key={`${line}-${id}`}>{line}</TableRow>) : <></>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box className={'stakeholders-container'}>
                        <Button
                            className={'stackeholder-btn'}
                            sx={{float: 'right', fontSize: '12px'}}
                            variant={'text'}
                            onClick={() => setShowStakeholders(!showStakeholders)}>
                            {!showStakeholders ? 'Show':'Hide'} Stakeholders {showStakeholders ? <ArrowUpwardOutlinedIcon className={'arrow-stakeholders'}/> : <ArrowDownwardOutlinedIcon className={'arrow-stakeholders'}/>}
                        </Button>
                    </Box>
                </Box>
                :
                <Box><Typography variant={'h6'}>Calculate the report to see data appear</Typography></Box>
            }
        </>
    )
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix=""
            />
        );
    },
);

export default SummaryView;