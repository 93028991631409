import {Box, Typography} from "@mui/material";

const BuildMe = () => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant={'h1'}>BUILD ME</Typography>
            <iframe src="https://giphy.com/embed/xZsLh7B3KMMyUptD9D" width="480" height="270" frameBorder="0"
                    className="giphy-embed" allowFullScreen></iframe>
            <p><a href="https://giphy.com/gifs/tlceurope-xZsLh7B3KMMyUptD9D">via GIPHY</a></p>
        </Box>
    )
}

export default BuildMe;