import {AxiosPromise} from "axios";
import {LockedPayment} from "../model/LockedPayment";
import {LockedPaymentType} from "../model/LockedPaymentType";
import axiosInstance from "../hooks/useUserInfo"
export const getLockedPayments = (year: number, month: number) : AxiosPromise => {
    return axiosInstance.get(`/api/v1/locked/${year}/${month}`);
}

export const lockGamPayment = (year: number, month: number, id: number) : AxiosPromise => {
    const lp: LockedPayment = {
        year: year,
        month: month,
        paymentId: id,
        paymentType: LockedPaymentType.PAID_MEDIA,
        locked: true
    }
    return axiosInstance.post(`/api/v1/locked/${year}/${month}`, lp);
}

export const getLockedYTPayment = (year: number, month: number) : AxiosPromise => {
    return axiosInstance.get(`/api/v1/locked/yt/${year}/${month}`);
}

export const lockYTPayment = (year: number, month: number) : AxiosPromise => {
    const lp: LockedPayment = {
        year: year,
        month: month,
        paymentId: 1,
        paymentType: LockedPaymentType.YT,
        locked: true
    }
    return axiosInstance.post(`/api/v1/locked/${year}/${month}`, lp);
}
