export interface YTEarning {
    isFirst: boolean;
    earningGroup: YTEarningGroupEnum;
    earningType: YTEarningTypeEnum;
    talentManagerEarnings?: number;
    groupEarnings?: number;
    grossEarnings: number;
    netEarnings: number;
    revShare: number;
    bpEarnings?: number;
}

export enum YTEarningGroupEnum {
    AD = 'AD',
    SUBSCRIPTION = 'SUBSCRIPTION',
    PAID_FEATURE = 'PAID_FEATURE'
}

export enum YTEarningTypeEnum {
    PARTNER_PROVIDED = 'PARTNER_PROVIDED',
    UGC_MATCH = 'UGC_MATCH',
    UGC_OTHER = 'UGC_OTHER',
    UGC_MANUAL = 'UGC_MANUAL',
    SGT = 'SGT',
    SCT = 'SCT',
    SVD = 'SVD',
    SST = 'SST',
    SPT = 'SPT',
    SHORTS_ADS = 'SHORTS_ADS',
    SHORTS_SUBS = 'SHORTS_SUBS',
    YOUTUBE_PLAYER_FOR_EDUCATION = 'YOUTUBE_PLAYER_FOR_EDUCATION',
    SUPER_THANKS = 'SUPER_THANKS',
    GIFTED_MEMBERSHIPS = 'GIFTED_MEMBERSHIPS',
    AFFILIATE_PROGRAM = 'AFFILIATE_PROGRAM',
    EP_PARTNER_PROVIDED = 'EP_PARTNER_PROVIDED',
    EP_SGT = 'EP_SGT',
    EP_SCT = 'EP_SCT',
    EP_SVD = 'EP_SVD',
    EP_SST = 'EP_SST',
    EP_SPT = 'EP_SPT',
    EP_SHORTS_ADS = 'EP_SHORTS_ADS',
    EP_SHORTS_SUBS = 'EP_SHORTS_SUBS',
    EP_YOUTUBE_PLAYER_FOR_EDUCATION = 'EP_YOUTUBE_PLAYER_FOR_EDUCATION',
    EP_SUPER_THANKS = 'EP_SUPER_THANKS',
    EP_GIFTED_MEMBERSHIPS = 'EP_GIFTED_MEMBERSHIPS',
    EP_AFFILIATE_PROGRAM = 'EP_AFFILIATE_PROGRAM'
}

