import {
    Autocomplete,
    Box,
    Button,
    Grid,
    IconButton,
    Skeleton,
    TextField,
} from "@mui/material";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {useCallback, useContext, useEffect, useState} from "react";
import {
    DataGrid,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {GamCalculation} from "../../model/GamCalculation";
import { EditRow } from "../../model/EditRow";
import {getGamDataForReportId} from "../../services/GamRunService";
import { updateRow } from "../../services/CreatorSummaryService";
import {AxiosResponse} from "axios";
import {CurrentReportDate} from "../../hooks/useReportDate";
import {CurrentMainSnackbar} from "../../hooks/useMainSnackbar";
import './detailview.css';
import '../../Review/Review.scss';
import {gamTableColumns} from "./gamTableColumns";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import * as React from "react";
import EditRowModal from "./editRowModal"
import {LockedPayment} from "../../model/LockedPayment";
import {getLockedPayments} from "../../services/LockService";
import { log } from "console";

interface detailViewProps {
    reportId: string | undefined;
}

interface filterInterface {
    error: string | null;
    orderTitle: string | null;
    kpeId: string | null;
    errorMessage: string | null;
}

const DetailView = (props: detailViewProps) => {
    const columns = gamTableColumns;
    const {year, month, lastUpdateDate} = useContext(CurrentReportDate);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);
    const [loading, setLoading] = useState(true);
    const [originalRows, setOriginalRows] = useState<GamCalculation[]>([]);
    const [filteredRows, setFilteredRows] = useState<GamCalculation[]>([]);
    const [filters, setFilters] = useState<filterInterface>({
        error: null,
        orderTitle: null,
        kpeId: null,
        errorMessage: null
    });
    const [otFilter, setOTFilter] = useState<string | null>(null);
    const [kpeFilter, setKPEFilter] = useState<string | null>(null);
    const [emFilter, setEMFilter] = useState<string | null>(null);
    const [errorFilter, setErrorFilter] = useState<string | null>(null);
    const [errorFilterOptions, setErrorFilterOptions] = useState([]);
    const [orderTitleFilterOptions, setOrderTitleFilterOptions] = useState([]);
    const [kpeIdFilterOptions, setKpeIdFilterOptions] = useState([]);
    const [errorMessageFilterOptions, setErrorMessageFilterOptions] = useState([]);
    const [lockedPayments, setLockedPayments] = useState<LockedPayment[]>([]);
    const prevRate = React.useRef<number | null>(null);

    const refresh = useCallback(() => {
        if (props.reportId !== undefined) {
            setLoading(true);
            getGamDataForReportId(props.reportId).then((res: AxiosResponse<GamCalculation[]>) => {
                setOriginalRows(res.data);
                setFilteredRows(res.data);
            }).catch((error) => {
                updateMainSnackbar(true, "error", "Error fetching data");
            }).finally(() => {
                setTimeout(() => setLoading(false), 1000);
            });
        } else {
            setOriginalRows([]);
            setFilteredRows([]);
            setLoading(false);
        }

        getLockedPayments(year, month)
            .then((res: AxiosResponse<LockedPayment[]>) => {
                if (res.status === 200) {
                    setLockedPayments(res.data);
                }
            });
    }, [props.reportId]);

    useEffect(() => {
        refresh();
    }, [refresh, lastUpdateDate]);

    const updateErrorFilter = (event: any, newValue: string | null) => {
        let newValueFormatted = newValue?.replace(/\s/g, "_").toUpperCase();
        newValueFormatted = newValueFormatted?.replace(/,_/g, ", ")
        setFilters({...filters, error: newValueFormatted ?? null})
        setErrorFilter(newValue)
    }

    const updateOTFilter = (event: any, newValue: string | null) => {
        setFilters({...filters, orderTitle: newValue});
        setOTFilter(newValue);
    }
    const updateKPEFilter = (event: any, newValue: string | null) => {
        setFilters({...filters, kpeId: newValue});
        setKPEFilter(newValue);
    }
    const updateEMFilter = (event: any, newValue: string | null) => {
        setFilters({...filters, errorMessage: newValue});
        setEMFilter(newValue);
    }

    useEffect(() => {
        let rows = originalRows;
        if (filters.error !== null) {
            rows = rows.filter((row) => {
                const arr = filters.error?.split(", ")
                return row.errorTypes?.[0] === arr?.[0]
            });
        }
        if (filters.orderTitle !== null) {
            rows = rows.filter((row) => row.orderTitle === filters.orderTitle);
        }
        if (filters.kpeId !== null) {
            rows = rows.filter((row) => row.ytChannelKpeId === filters.kpeId);
        }
        if (filters.errorMessage !== null) {
            rows = rows.filter((row) => row.errorMessage === filters.errorMessage);
        }
        setFilteredRows(rows);
    }, [filters, originalRows]);

    const [openSearch, setOpenSearch] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpenSearch(!openSearch);
    };
    useEffect(() => {
        let err = new Array();
        let ot = new Set();
        let kpe = new Set();
        let em = new Set();
        filteredRows.forEach((row) => {
            if(!!row.errorTypes?.length && !err.includes(row.errorTypes.join(", "))) { 
                err.push(row.errorTypes.join(", "));
            }
            ot.add(row.orderTitle);
            kpe.add(row.ytChannelKpeId);
            em.add(row.errorMessage);
        });  
        // remove underscore for the error filter values and set camel case
        err = err.map((item) => item.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (l: string) => l.toUpperCase()));
        // @ts-ignore
        setErrorFilterOptions([...err]);
        // @ts-ignore
        setOrderTitleFilterOptions([...ot]);
        // @ts-ignore
        setKpeIdFilterOptions([...kpe].filter((item) => item !== null));
        // @ts-ignore
        setErrorMessageFilterOptions([...em]);
    }, [filteredRows, originalRows]);

    const [openModal, setOpenModal] = React.useState(false);
    const [alertLocked, setAlertLocked] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState<GamCalculation>();
    const [editFields, setEditFields] = useState<EditRow>();

    const handleCloseModal = () => setOpenModal(false);
    const handleSaveModal = (editedRow: GamCalculation) => {
        const request = formatEditable(editedRow);
        updateRow(request).then((res: AxiosResponse<GamCalculation[]>) => {
            refresh();
            setOpenModal(false);
            updateMainSnackbar(true, "success", "It has been successfully updated");
        });
    }
    const formatEditable = (params: GamCalculation) => {
        return {
            gamCalculationLineId: {
                lineItemId: params.lineItemId,
                ytChannelKpeId: params.ytChannelKpeId,
                advertiserId: params.advertiserId.toString(),
                ytChannelId: params.ytChannelId,
                reportId: params.reportId,
                gamOrderId: params.gamOrderId.toString(),
                advertiserTitle:params.advertiserTitle.toString(),
                assetLabel:params.assetLabel.toString(),
                orderTitle:params.orderTitle.toString(),
                lineItemTitle:params.lineItemTitle.toString(),
                rate: prevRate.current ? prevRate.current.toString() : '',
            },
            talentRevShare: params.talentRevShare.toString(),
            groupRevShare: params.groupRevShare.toString(),
            bpRevShare: params.bpRevShare.toString(),
            groupOverageRevShare: params.groupOverageRevShare.toString(),
            bpOverageRevShare: params.bpOverageRevShare.toString(),
            bpPaidMediaTalentOverage: params.bpPaidMediaTalentOverage.toString(),
            rate: params.rate.toString(),
            cpm: params.cpm.toString(),
            wholesaleRate: params.wholeSaleRateCard.toString(), // checkthis
            impressions: params.impressions.toString()
        }
    }
    useEffect(() => {
        let editable = {
            gamCalculationLineId: {
                lineItemId: selectedRow?.lineItemId,
                ytChannelKpeId: selectedRow?.ytChannelKpeId,
                advertiserId: selectedRow?.advertiserId.toString(),
                ytChannelId: selectedRow?.ytChannelId,
                reportId: selectedRow?.reportId,
                gamOrderId: selectedRow?.gamOrderId.toString()
            },
            talentRevShare: selectedRow?.talentRevShare.toString(),
            groupRevShare: selectedRow?.groupRevShare.toString(),
            bpRevShare: selectedRow?.bpRevShare.toString(),
            groupOverageRevShare: selectedRow?.groupOverageRevShare.toString(),
            bpOverageRevShare: selectedRow?.bpOverageRevShare.toString(),
            rate: selectedRow?.rate.toString(),
            cpm: selectedRow?.cpm.toString(),
            wholesaleRate: selectedRow?.wholeSaleRateCard.toString(), // checkthis
            impressions: selectedRow?.impressions.toString()
        };
        setEditFields(editable);
    }, [selectedRow])
    useEffect(() => {
        setOpenModal(true);
    }, [editFields])

    const getRowOverwritten = (params: GridRowParams) => {
        const row: GamCalculation = params.row as GamCalculation;
        if (row.overwritten) {
          return 'bg-yellow';
        }
        return '';
    };

    return (
        <>
        <Grid container={true} sx={{my: 2}} spacing={2}>
            {loading ? 
            <Skeleton sx={{width: 1, height: 400}} variant={"rectangular"}/> :
            <Grid item sm={12}>
                <DataGrid
                    sx={{height: 'calc(100vh - 350px)'}}
                    localeText={{
                        toolbarColumns: "",
                        toolbarDensity: "",
                        toolbarFilters: ""
                    }}
                    rows={filteredRows}
                    columns={columns}
                    density={'compact'}
                    onRowDoubleClick={(params) => {
                        prevRate.current = params.row.rate;
                        let isLocked = lockedPayments.filter((item) => {
                            return item.paymentId === params.row.gamOrderId
                        })
                        setAlertLocked(isLocked.length > 0 );
                        setSelectedRow(params.row);
                        setOpenModal(true);
                    }}
                    className="details-data-container"
                    getRowClassName={getRowOverwritten}
                    components={{
                        Toolbar: () => (
                            <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid container className="filters-container">
                                    <Grid item xs={7}>
                                        <Autocomplete
                                            disablePortal
                                            id="kpe-filter-combo-box"
                                            options={kpeIdFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="KPE ID"/>}
                                            onChange={updateKPEFilter}
                                            value={kpeFilter}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="ot-filter-combo-box"
                                            options={orderTitleFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="Order Title"/>}
                                            onChange={updateOTFilter}
                                            value={otFilter}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="error-filter-combo-box"
                                            options={errorFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="Error"/>}
                                            onChange={updateErrorFilter}
                                            value={errorFilter}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="error-message-combo-box"
                                            options={errorMessageFilterOptions}
                                            renderInput={(params) => <TextField {...params} placeholder="Message"/>}
                                            onChange={updateEMFilter}
                                            value={emFilter}
                                        />
                                    </Grid>
                                    <Grid item xs={5}  textAlign="right" className="filter-buttons-container">
                                        <GridToolbarExport />
                                        <GridToolbarColumnsButton placeholder={'column'} startIcon={<ViewColumnOutlinedIcon />}/>
                                        <GridToolbarDensitySelector placeholder={'menu'} startIcon={<MenuOutlinedIcon />}/>
                                        <GridToolbarFilterButton placeholder={'filter'} />
                                        {!openSearch ? <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            className="search-component"
                                            sx={{ ml: 2 }}
                                            aria-controls={openSearch ? 'open-search' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openSearch ? 'true' : undefined}>
                                            <SearchOutlinedIcon color="primary" />
                                        </IconButton>: <Box className="input-search-container">
                                            <GridToolbarQuickFilter
                                            className="search-component" onClose={handleClick} sx={{pr: 4}}/>
                                            <IconButton className="closing-btn" onClick={handleClick} size="small">
                                                <SearchOffIcon color="primary" />
                                            </IconButton>
                                        </Box>}
                                        <Button variant="outlined" onClick={refresh} className={`refresh-btn ${loading && 'refresh-animation'}`}>
                                            <RefreshOutlinedIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </GridToolbarContainer>
                        )
                    }}
                />
            </Grid>}
        </Grid>
        {selectedRow && (
            <EditRowModal
            open={openModal}
            rowData={selectedRow}
            onSave={handleSaveModal}
            onCancel={handleCloseModal}
            locked={alertLocked}
            />
        )}
        </>
    );
}

export default DetailView;