import {
    Box, 
    IconButton
} from "@mui/material";
import Logo from "./Logo/logo";
import DateSelector from "./DateSelector/DateSelector";
import MenuIcon from '@mui/icons-material/Menu';
import {useContext, useState} from "react";
import NavDrawer from "../NavDrawer/NavDrawer";
import AvatarMenu from "./AvatarMenu/AvatarMenu";
import {CurrentUserInfo} from "../hooks/useUserInfo";
import { useTheme } from '@mui/material/styles';

const Header = () => {
    const [open, setOpen] = useState(false);
    const {authenticated} = useContext(CurrentUserInfo);
    // const [isUser, setIsuser] = useState(JSON.parse(localStorage.getItem("userInfo")|| "{}") );
    const token = localStorage.getItem('accessToken')
    const theme = useTheme();
    const colors = theme.palette.primary;

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    return (
        <Box sx={{backgroundColor: colors.main}}>
            {token ? <NavDrawer open={open} setOpen={setOpen}/> : <></>}
            <Box position="fixed" sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
                flexGrow: 1,
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                height: 80,
                px: 3,
                width: "100vw",
                backgroundColor: colors.main,
                zIndex: (theme) => theme.zIndex.drawer + 1,
                boxSizing: "border-box"
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: 'center'
                }}>
                    <Logo sx={{
                        fill: {xs: colors.contrastText},
                        pl: {xs: 0}, pr: {xs: 2} }} />
                    {token ? <>
                        <DateSelector/>
                    </>:<></>}
                </Box>
                {token ? <>
                        <AvatarMenu />
                    </>
                    : <></>}
            </Box>
        </Box>

    )
}

export default Header;