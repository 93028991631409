import {AxiosPromise} from "axios";
import {GamSummary} from "../model/GamSummary";
import axiosInstance from "../hooks/useUserInfo"
export const getGamChecklistByYearAndMonth = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/gam/${year}/${month}`)
}

export const uploadBillingReport = (year: number, month: number, file: File): AxiosPromise => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    return axiosInstance.post(`/api/v1/gam/upload/billing/${year}/${month}`, bodyFormData)
}

export const uploadChannelLineReport = (year: number, month: number, file: File): AxiosPromise => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    return axiosInstance.post(`/api/v1/gam/upload/channel/line/${year}/${month}`, bodyFormData)
}

export const processGamData = (year: number, month: number): AxiosPromise => {
    return axiosInstance.post(`/api/v2/gam/import/${year}/${month}`, null)
}

export const getAllGamRecords = (): AxiosPromise => {
    return axiosInstance.get(`/api/v1/gam/all`);
}

export const calculateGamData = (year: number, month: number): AxiosPromise => {
    return axiosInstance.post(`/api/v1/gam/process/${year}/${month}`, null)
}

export const getGamDataForReportId = (reportId: string): AxiosPromise => {
    return axiosInstance.get(`/api/v1/gam/calculations/${reportId}`);
}

export const getModLogs = (reportId: string): AxiosPromise => {
    return axiosInstance.get(`/api/v2/gam/calculations/history/${reportId}`);
}

export const getGamSummaryDataForReportId = (reportId: string) : AxiosPromise => {
    return axiosInstance.get(`/api/v1/gam/summary/${reportId}`)
}

export const uploadGamSummary = (reportId: string, gcs: GamSummary): AxiosPromise => {
    return axiosInstance.post(`/api/v1/gam/summary/${reportId}`, gcs)
}
