import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, TableRow, TableCell,
    TextField, Avatar, Box, Table
} from "@mui/material";
import {useContext, useState} from "react";
import {CurrentReportDate} from "../../hooks/useReportDate";
import {addAdditionalPayment, addAdditionalPaymentGroup, addAdditionalPaymentTM} from "../../services/CreatorSummaryService";
import {AxiosResponse} from "axios";
import {AdditionalPaymentType} from "../../model/AdditionalPaymentType";
import {AdditionalPayment, AdditionalPaymentGroup, AdditionalPaymentTM} from "../../model/AdditionalPayment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as React from "react";

interface AdditionalPaymentDialogProps {
    open: boolean;
    handleClose: () => void
    kpeId: string;
    talentManagerId: string;
    creatorName: string;
    updateAdditionalPayments: (ap: AdditionalPaymentTM) => void;
}

function isNumber(str: string) {
    const num = Number(str);
    return str !== '' && !isNaN(num);
}

const AdditionalPaymentDialog = (props: AdditionalPaymentDialogProps) => {
    const {year, month} = useContext(CurrentReportDate);
    const [type, setType] = useState<string>('0');
    const [amount, setAmount] = useState('0');
    const [comment, setComment] = useState('');

    const saveTransaction = () => {
        const ap: AdditionalPaymentTM = {
            year: year,
            month: month,
            talentManagerId: props.talentManagerId,
            channelKpeId: props.kpeId,
            paymentType: type,
            amount: Number(amount),
            comment: comment,
            id: props.talentManagerId
        }
        addAdditionalPaymentTM(year, month, props.talentManagerId, ap)
            .then((res: AxiosResponse<number>) => {
                ap.id = res.data.toString(10);
                props.updateAdditionalPayments(ap);
            })
            .catch((err) => console.error(err));
        handleClose();
    }
    const cancelTransaction = () => {
        handleClose();
    }
    const handleClose = () => {
        setType(AdditionalPaymentType.OTHER);
        setAmount('0')
        setComment('');
        setType('0');
        props.handleClose();
    }

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value);
    }

    return (
        <Dialog open={props.open} onClose={cancelTransaction} style={{maxWidth: '504px', margin:'0 auto'}}>
            <DialogTitle>
                <Avatar sx={{ width: 32, height: 32, display: 'inline-block', verticalAlign: 'middle' }} alt={props?.creatorName} src={props?.creatorName}/>
                <Box sx={{display: 'inline-block', marginLeft: 1, verticalAlign: 'middle'}}>
                    <p style={{margin:'0', lineHeight:'1.2em', fontSize: '20px'}}><b>{props.creatorName}</b></p>
                    <span style={{fontSize: '12px'}}>
                        {props.kpeId}
                    </span>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    These payments will add to the net earnings of the creator. No rev shares are applied.
                </DialogContentText>
                <FormControl fullWidth sx={{mt: 3}}>
                    <InputLabel id="payment-type-label">Earning Type</InputLabel>
                    <Select
                        labelId="payment-label"
                        id="payment-select"
                        value={type}
                        label="Earning Type"
                        onChange={handleChange}
                    >
                        <MenuItem value={AdditionalPaymentType.OTHER}>Other</MenuItem>
                        <MenuItem value={AdditionalPaymentType.PAID_FEATURES}>Paid Features</MenuItem>
                        <MenuItem value={AdditionalPaymentType.BRANDED}>Branded</MenuItem>
                        <MenuItem value={AdditionalPaymentType.UGC}>UGC</MenuItem>
                        <MenuItem value={AdditionalPaymentType.DSA}>DSA</MenuItem>
                        <MenuItem value={AdditionalPaymentType.SHORTS}>Shorts</MenuItem>
                        <MenuItem value={AdditionalPaymentType.PARTNER_PROVIDED}>Partner Provided</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    sx={{mt: 3}}
                    margin="dense"
                    id="name"
                    label="Amount"
                    // type="number"   
                    fullWidth
                    variant="outlined"
                    value={amount}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                       setAmount(event.target.value)
                    }}
                />
                <TextField
                    sx={{mt: 3}}
                    margin="dense"
                    id="name"
                    label="Comment"
                    fullWidth
                    variant="outlined"
                    value={comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComment(event.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelTransaction} sx={{width: '100px'}}>Cancel</Button>
                <Button disabled={!isNumber(amount)} onClick={saveTransaction}variant="contained" sx={{width: '80px'}}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AdditionalPaymentDialog;