import axios, {AxiosPromise} from "axios";
import {User} from "../model/User";
import axiosInstance from "../hooks/useUserInfo"
export const getUserInfo = (): AxiosPromise => {
    return axiosInstance.get(`/api/v1/user/me`)
}

export const getAllusers = (): AxiosPromise => {
    return axiosInstance.get(`/api/v1/user/all`);
}

export const updateUser = (user: User): AxiosPromise => {
    return axiosInstance.put(`/api/v1/user`, user);
}

export const softDeleteUser = (user: User): AxiosPromise => {
    return axiosInstance.post(`/api/v1/user`, user);
}

export const addUser = (user: User): AxiosPromise => {
    return axiosInstance.post(`/api/v1/user/add`, user);
}

export const loginGoogle = (toekn: string): AxiosPromise => {
    return axios.post(`/api/v1/login`, {},
        {
            headers: {
                "X-GOOGLE-RES": `${toekn}`
            }
        }
    );
}

export const getGoogleEndpoint = (): AxiosPromise => {
    return axios.get('/api/v1/login/google');
}