import './App.css';
import Run from "./run/Run";
import MainView from "./MainView/MainView";
import {CurrentReportDate, useReportDate} from "./hooks/useReportDate";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import BuildMe from "./BuildMe";
import Layout from "./Layout";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Login from "./Login/Login";
import {useUserInfo, CurrentUserInfo} from "./hooks/useUserInfo";
import {useMainSnackbar, CurrentMainSnackbar} from "./hooks/useMainSnackbar";
import SecureRoute from "./SecureRoute/SecureRoute";
import axios from "axios";
import Admin from "./Admin/Admin";
import {Box, ThemeProvider,Snackbar, Alert} from "@mui/material";
import CreatorSummary from "./CreatorSummary/CreatorSummary";
import TalentManagerSummary from "./TalentManagerSummary/CreatorSummary";
import GroupSummary from "./GroupSummary/CreatorSummary";
import {BentPixelsTheme} from "./theme";
import YouTubeSummary from "./YoutubeSummary/YouTubeSummary";
import BatchMonitor from './BatchMonitor';

const router = createBrowserRouter([
    {
        element: <Layout/>,
        errorElement: <BuildMe/>,
        children: [
            {
                path: "/",
                element: <SecureRoute><Home/></SecureRoute>,
            },
            {
                path: "/login",
                element: <Login/>,
            },
            {
                path: "/gam/run",
                element: <SecureRoute><Run/></SecureRoute>,
            },
            {
                path: "/gam/view",
                element: <SecureRoute><MainView/></SecureRoute>,
            },
            {
                path: "/creator/view",
                element: <SecureRoute><CreatorSummary/></SecureRoute>,
            },
            {
                path: "/talent-manager/view",
                element: <SecureRoute><TalentManagerSummary/></SecureRoute>,
            },
            {
                path: "/group/view",
                element: <SecureRoute><GroupSummary/></SecureRoute>,
            },
            {
                path: "/monitor/view",
                element: <SecureRoute><BatchMonitor/></SecureRoute>,
            },
            {
                path: "/youtube/view",
                element: <SecureRoute><YouTubeSummary/></SecureRoute>,
            },
            {
                path: "/admin",
                element: <SecureRoute><Admin/></SecureRoute>
            }
        ]
    }
]);

function App() {
    const {year, month, updateYearAndMonth, updateLastUpdateDate} = useReportDate();
    const {user, authenticated, updateUser, logout} = useUserInfo();
    const {isOpen, type, message, updateMainSnackbar, setIsOpen} = useMainSnackbar();
    const handleClose = () => {
        setIsOpen(false);
    };
    if (process.env.NODE_ENV === 'development') {
        axios.defaults.baseURL = 'http://localhost:8080';
    }
    return (
        <ThemeProvider theme={BentPixelsTheme}>
            <Box sx={{height: '100%'}}>
                <CurrentReportDate.Provider value={{year, month, updateYearAndMonth, updateLastUpdateDate }}>
                    <CurrentUserInfo.Provider value={{user, authenticated, updateUser, logout}}>
                        <CurrentMainSnackbar.Provider value={{isOpen, type, message, updateMainSnackbar}}>
                            <RouterProvider router={router}/>
                            <Snackbar
                                open={isOpen}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                autoHideDuration={4000}
                                onClose={handleClose}>
                                    <Alert severity={type} sx={{ width: '100%' }}>
                                        {message}
                                    </Alert>
                                </Snackbar>
                        </CurrentMainSnackbar.Provider>
                    </CurrentUserInfo.Provider>
                </CurrentReportDate.Provider>
            </Box>
        </ThemeProvider>
    );
}

export default App;
