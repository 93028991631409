import { Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactNode, useContext, useEffect, useState } from "react";
import { CurrentReportDate } from "../../hooks/useReportDate";

const DateSelector = () => {
    const { year, month, updateYearAndMonth } = useContext(CurrentReportDate);

    const handleDateChange = (date: any) => {
        updateYearAndMonth(date.year(), date.month() + 1);
        localStorage.setItem('selectedDate', date.toString()); // Save selected date to localStorage
    };

    const today = dayjs();
    const startOfMonth2022 = dayjs('2022-11-01');
    const endOfMonth = today.subtract(1, 'month').endOf('month');

    // Get default date from localStorage or use current date
    const defaultDateFromStorage = localStorage.getItem('selectedDate');
    const defaultDate = defaultDateFromStorage ? dayjs(defaultDateFromStorage) : today.subtract(1, 'month');

    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Check localStorage for a stored date
        const storedDate = localStorage.getItem('selectedDate');
        if (storedDate) {
            const parsedDate = dayjs(storedDate);
            updateYearAndMonth(parsedDate.year(), parsedDate.month() + 1); // +1 because month is 0-indexed
        } else {
            // If no date stored, use initial values
            updateYearAndMonth(defaultDate.year(), defaultDate.month() + 1);// +1 because month is 0-indexed
        }
    }, []);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', pr: 8 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    components={{
                        OpenPickerIcon: ArrowDropDownIcon
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    slotProps={{
                        textField: {
                            onClick: () => setOpen(true),
                            size: 'small',
                            sx: {
                                background: "#FFF",
                                borderRadius: "4px",
                                cursor: "pointer",
                                ":hover": {
                                    border: "none"
                                },
                                "& *": { cursor: "pointer" }
                            }
                        },
                    }}
                    defaultValue={defaultDate}
                    minDate={startOfMonth2022}
                    maxDate={endOfMonth}
                    views={['month', 'year']}
                    onChange={handleDateChange} />
            </LocalizationProvider>
        </Box>
    );
}

export default DateSelector;
