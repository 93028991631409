import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {YTEarning, YTEarningGroupEnum, YTEarningTypeEnum} from "../../model/YTEarning";
import {SxProps} from "@mui/system/styleFunctionSx";
import * as React from "react";

interface YTSummaryTableProps {
    isCreatorReview: boolean;
    earningGroup: YTEarningGroupEnum;
    earnings: YTEarning[];
    view?: string;
    sx?: SxProps;
}

const ensurePairs = (earnings: YTEarning[]) => {
  const earningsMap = new Map();

  // Create map to track unique earningGroups
  const earningGroupTracker = new Map();

  earnings.forEach((earning) => {
    const baseType = earning.earningType.replace(/^EP_/, "");
    const key = `${earning.earningGroup}-${baseType}`;

    if (!earningsMap.has(key)) {
      earningsMap.set(key, { base: false, extended: false });
    }

    if (earning.earningType.startsWith("EP_")) {
      earningsMap.get(key).extended = true;
    } else {
      earningsMap.get(key).base = true;
    }

    // Add isFirst property based on earningGroupTracker
    if (!earningGroupTracker.has(earning.earningGroup)) {
      earningGroupTracker.set(earning.earningGroup, true);
      earning.isFirst = true;
    } else {
      earning.isFirst = false;
    }
  });

  earningsMap.forEach((status, key) => {
    if (status.extended && !status.base) {
      const [group, type] = key.split("-");
      earnings.push({
        earningGroup: group,
        earningType: type,
        grossEarnings: 0,
        netEarnings: 0,
        revShare: 0,
        talentManagerEarnings: 0,
        bpEarnings: 0,
        groupEarnings: 0,
        isFirst: !earningGroupTracker.has(group),
      });
      earningGroupTracker.set(group, true);
    }
  });
  // filter out EP_ earnings
  return earnings.filter((earning) => !earning.earningType.startsWith("EP_"));
};
const YTSummaryTable = (props: YTSummaryTableProps) => {
  const [earningsData, setEarningsData] = React.useState<YTEarning[]>([]);

  React.useEffect(() => {
    setEarningsData(ensurePairs(props.earnings));
  }, [props.earnings]);

    const getEarningTitle = () => {
        switch (props.earningGroup) {
            case YTEarningGroupEnum.AD:
                return 'Ad Earnings';
            case YTEarningGroupEnum.SUBSCRIPTION:
                return 'Subscription Earnings';
            case YTEarningGroupEnum.PAID_FEATURE:
                return 'Paid Features Earnings';
        }
    }

  const calculateReconciliation = (earning: YTEarning) => {
    //check if for some item there is no EP_ item, then reconciliation is 0
     
    const isEPPresent = props.earnings.find(
        (e) =>
            e.earningType === "EP_" + earning.earningType &&
            e.earningGroup === earning.earningGroup
    );
    if(!isEPPresent) return 0;

      const isPresent = props.earnings.find(
        (e) =>
          e.earningType === "EP_" + earning.earningType &&
          e.earningGroup === earning.earningGroup
      );
      if (isPresent)
        return (earning.netEarnings - isPresent.netEarnings).toFixed(2);
      return (0 - earning.netEarnings).toFixed(2);

  };

    const getEarningTypeName = (earningType: YTEarningTypeEnum): string => {
        switch (earningType) {
            case YTEarningTypeEnum.PARTNER_PROVIDED:
                return 'Partner Provided';
            case YTEarningTypeEnum.UGC_MATCH:
                return 'UGC Match';
            case YTEarningTypeEnum.UGC_MANUAL:
                return 'UGC Manual';
            case YTEarningTypeEnum.UGC_OTHER:
                return 'UGC Other';
            case YTEarningTypeEnum.SGT:
                return 'Channel Membership Gifting';
            case YTEarningTypeEnum.SCT:
                return 'Super Chat';
            case YTEarningTypeEnum.SVD:
                return 'Video on Demand';
            case YTEarningTypeEnum.SST:
                return 'Super Stickers';
            case YTEarningTypeEnum.SPT:
                return 'Memberships';
            case YTEarningTypeEnum.SHORTS_ADS:
                return "Shorts Ads";
            case YTEarningTypeEnum.SHORTS_SUBS:
                return "Shorts Subs";
              case YTEarningTypeEnum.YOUTUBE_PLAYER_FOR_EDUCATION:
                return "YouTube Player for Education";
              case YTEarningTypeEnum.SUPER_THANKS:
                return 'Super Thanks';
              case YTEarningTypeEnum.GIFTED_MEMBERSHIPS:
                return 'Gifted Memberships';
              case YTEarningTypeEnum.AFFILIATE_PROGRAM:
                return 'Affiliate Program';
            default:
                return '';
        }
    }

    return (
        <>
            {props.earnings.length === 0 ?
                props.isCreatorReview ? <TableRow sx={{display: 'none'}}>
                    <TableCell/>
                    <TableCell sx={{color: 'red', borderLeft: '1px solid rgba(224, 224, 224, 1)'}} colSpan={6}>
                        No {getEarningTitle()} this month.
                    </TableCell>
                </TableRow>: <></>
                :
                <>
                    {earningsData.map((earning, index) =>
                        <TableRow key={earning.earningGroup+earning.earningType}>
                            <TableCell width={'16px'}></TableCell>
                            <TableCell sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)', fontSize: index > 0 ? '0 !important':'', fontWeight: '600'}}>
                                {getEarningTitle()}
                            </TableCell>
                            <TableCell>
                                {getEarningTypeName(earning.earningType)}
                            </TableCell>
                            {
                                props.view === "tm" ? 
                                <TableCell align='right'>
                                    {earning.talentManagerEarnings ? earning.talentManagerEarnings.toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2}):'-'}
                                </TableCell>:<></>
                            }
                            {
                                props.view === "groups" ?
                                <TableCell align='right'>
                                    {earning.groupEarnings ? earning.groupEarnings.toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2}):'-'}
                                </TableCell>:<></>
                            }
                            <TableCell align='right'>
                                {earning.grossEarnings.toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </TableCell>
                            <TableCell align='right'>
                                {earning.netEarnings.toLocaleString(undefined, {currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </TableCell>
                            <TableCell align='right'>
                                {earning.revShare}
                            </TableCell>
                            {/* <TableCell align="center">
                              {calculateReconciliation(earning)}
                            </TableCell> */}
                        </TableRow>
                    )}
                </>}
        </>
    )
}

export default YTSummaryTable;