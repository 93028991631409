import {Box, Button, Grid, Skeleton, Typography, Paper, TextField, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton, Collapse} from "@mui/material";
import {downloadCSV, getPaymentSummary, getPaymentsMonitor, submitGroupsPayments, submitPayments} from "../services/CreatorSummaryService";
import React, {useContext, useEffect, useRef, useState} from "react";
import {CurrentReportDate} from "../hooks/useReportDate";
import {PaymentSummary} from "../model/PaymentSummary";
import {AxiosResponse} from "axios";
import { getLockedYTPayment, lockYTPayment} from "../services/LockService";
import {LockedPayment} from "../model/LockedPayment";
import { PaymentsReq } from "../model/PaymentsReq";
import {CurrentMainSnackbar} from "../hooks/useMainSnackbar";
import { SyncAltOutlined } from "@mui/icons-material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const formatDate = (date: string) => {
    const formatedDate = new Date(date).toLocaleDateString("es-US", {year:"numeric",month:"2-digit",day:"2-digit"});
    return formatedDate;
}

const CreatorSummary = () => {
    const {year, month} = useContext(CurrentReportDate);
    const [paymentSummaries, setPaymentSummaries] = useState<PaymentSummary[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const {updateMainSnackbar} = useContext(CurrentMainSnackbar);

    useEffect(() => {
        setLoading(true);
        getPaymentsMonitor(year, month)
            .then((res: AxiosResponse<[]>) => {
                setPaymentSummaries(res.data)
                setCurrentPage(1);
                setLoading(false);
            })
            .catch((err) => console.error(err));
    }, [year, month]);

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Batch name', flex: 2, align: 'left'},
        { field: 'createdAt', headerName: 'Created at',flex: 2, align: 'center'},
        { field: 'status', headerName: 'Status', align: "center", flex: .4, minWidth: 224 },
        { field: 'collapsable', headerName: '', flex: .1, minWidth: 168, align: "right"},
    ];


    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof PaymentSummary>('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: PaymentSummary[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        return stabilizedThis.map((el) => el[0]);
    }
    const visibleRows = React.useMemo(
        () => {
            return stableSort(paymentSummaries, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            )
        },
        [order, orderBy, page, rowsPerPage, paymentSummaries],
    );

    return (
        <Box className={'youtube-summary-container'}>
            <Paper elevation={1} sx={{width: '90%',height:'82vh', py: 3, px: 4}}>
                <Grid container>
                    <Grid xs={3}>
                        <Typography variant={'h4'} sx={{pb: 2}} className={'h4-custom'}>
                            Batch Monitor
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{
                    width: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    {loading ? <Skeleton sx={{width: 1}} variant={"rectangular"}/> :
                        <>
                            <TableContainer component={Box} className={'custom-table-container'} sx={{ height: '70vh' }}>
                                <Table aria-label="collapsible table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            { columns.map((obj, i) => {
                                                return (<TableCell align={obj.align} key={obj.headerName}>{obj.headerName}</TableCell>)
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {visibleRows.map((row, i) => (
                                            <Row key={i} row={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{width: "100%"}}
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={paymentSummaries.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>}
                </Box>
            </Paper>
        </Box>
    )
}

const Row = (props: {row: any}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}  className={open ? 'active-row': ''}>
                <TableCell width={'30%'}>
                    <b>{props.row.name}</b>
                </TableCell>
                <TableCell width={'10%'} align="center">{formatDate(props.row.createdAt)}</TableCell>
                <TableCell width={'10%'} align="center">{props.row.status}</TableCell>
                <TableCell width={'15%'} align="right" colSpan={2}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        color={'primary'}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className={open ? 'collapsable-ctn active-row': 'collapsable-ctn'}>
                <TableCell sx={{ p: '0 !important' }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{ margin: 0 }}>
                        <Box sx={{ margin: 0 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="center">Amount (US$)</TableCell>
                                        <TableCell align="center">Created</TableCell>
                                        <TableCell align="center">Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{background:"#fff"}}>
                                    {props.row.items.map((item: any) => (
                                        <TableRow key={item.date}>
                                            <TableCell align="left">
                                                {item.email ? item.email: '-'}
                                            </TableCell>
                                            <TableCell align="center">{item.amount}</TableCell>
                                            <TableCell align="center">
                                                {item.paymentCreated ? <CheckIcon sx={{fontSize: '16px'}}/>: <CloseIcon sx={{fontSize: '16px'}}/>}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.errorMessage ? item.errorMessage: ''}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
export default CreatorSummary;